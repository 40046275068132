
import {camelCaseToSplitWords, FIELD_TYPE, ValidationRule} from "./ValidationRule";

class NotEmptyRule implements ValidationRule {
    type = FIELD_TYPE

    isValid(field: any)  {
        return field !== "";
    }

    getErrorMessage(fieldName: string) {
        const name = camelCaseToSplitWords(fieldName);
        return `${name} cannot be empty`;
    }
}

export default NotEmptyRule;