export function formatCurrencyRoundUp(amount: number): string {
    // Round up to the nearest dollar
    const roundedAmount = Math.ceil(amount);

    // Format as currency
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // Ensure we don't show decimal places
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    return formatter.format(roundedAmount);
}