import { Button, Card, CardContent, CardMedia, Typography } from '@mui/material'

export type AboutUsData = {
    displayName: string
    department: string
    branchLocation: string,
    title: string,
    email: string
    phone: string
    active: number
    photolink: string
}

export const PhotoCard = (props: AboutUsData) => {

    const salesBit = () => {
        if(props.department === "Sales Team"){
            return(
                <>
                    <Typography variant="body2" color="text.secondary">
                        {props.email}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.phone}
                    </Typography>
                    <div style={{display:"flex", paddingTop: 5}}>
                        <Button onClick={(e) => {
                                window.location.href = "mailto:"+ props.email;
                                e.preventDefault();}} 
                                size="small" variant="contained"
                                style={{marginRight: 5}}
                                >Email</Button>
                        <Button onClick={(e) => {
                                window.location.href = "tel:"+ props.phone;
                                e.preventDefault();}} 
                                size="small" variant="contained">Call</Button>
                    </div>
                </>
            )
        }
    }

    return(
        <>
            <Card sx={{ maxWidth: 350, marginTop: "15px" }}>
            <CardMedia
                component="img"
                height="300px"
                image={props.photolink === undefined ? 'https://picsum.photos/300/300' : props.photolink}
                alt={props.displayName === undefined ? "About Us Photo" : props.displayName}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {props.displayName}
                </Typography>
                <Typography gutterBottom variant="body2" color="text.primary">
                    {props.title} | {props.branchLocation}
                </Typography>
                {salesBit()}
            </CardContent>

            </Card>
        </>

    )
}