import { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import * as Excel from "exceljs";
import * as FileSaver from "file-saver";
import { BranchContext } from "../../providers/BranchProvider";
import { WebsiteAvailabilityGroup } from "../../types/ProductType";
import {CUSTOMER_NAME, DEFAULT_BRANCH, ENTITY_NAME} from "../../constants/Constants";
import { datadogLogs } from "@datadog/browser-logs";
import { addHeadersAndCallBackend } from "../../endpoints/AddHeadersAndCallBackend";
import { AuthContext } from "../../providers/AuthProvider";
import { getCompanyName } from "../LoggedOut/Products";

export const DownloadDialog = (props: {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
}) => {
  const { branches } = useContext(BranchContext);
  const auth = useContext(AuthContext);

  const [branch, setBranch] = useState(DEFAULT_BRANCH);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [progress, setProgress] = useState(0);

  const startTimer = () => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  };

  const isExpired = () => {
    if (auth.isTokenExpired) {
      return auth.isTokenExpired();
    }
    return true;
  };

  const showPricing = auth.isConfiguredUser ? auth.isConfiguredUser() : false;

  const getAvailabilityList = async () => {
    setLoading(true);
    startTimer();

    let minPrice = 0;
    let maxPrice = 1000;
    let minAvailability = 0;
    let size = "";
    let entitys = [branch];
    let genus = "";
    let species = "";
    let category = "";
    let subCategory = "";
    let leafType = "";
    let rootball = "";
    let stemType = "";
    let qtyFilterType = "Availability";
    let qtyFilterComparison = ">";
    let qtyFilterValue = 0;
    let searchString = "";
    let maxResults = 10000;

    let encodedSearchString = encodeURIComponent(
      searchString as string | number | boolean
    );

    let entitysUrl = new URLSearchParams();
    entitys.forEach((entity) => {
      entitysUrl.append("entitys", entity);
    });

    let locationName =
      auth?.user?.customer?.entityName || auth?.loggedOutLocation;

    let qpEmail =
      localStorage.getItem("token") == null || isExpired()
        ? `email=${getCompanyName(locationName as string)}`
        : "email=guest@guest.com";
    let qpMinPrice = `&minPrice=${minPrice}`;
    let qpMaxPrice = maxPrice ? `&maxPrice=${maxPrice}` : `&maxPrice=1000`;
    let qpMinAvailability = `&minAvailability=${minAvailability}`;
    let qpSize =
      size.length > 0
        ? size.includes("#")
          ? `&size=${size.replace("#", "%23")}`
          : `&size=${size}`
        : "";
    let qpEntitys = entitys.length > 0 ? "&" + entitysUrl.toString() : "";
    let qpGenus = genus?.length > 0 ? `&genus=${genus}` : "";
    let qpSpecies = genus?.length > 0 ? `&species=${species}` : "";
    let qpCategory =
      category?.length > 0 && category !== "All"
        ? `&category=${category.charAt(0)}`
        : "";
    let qpSubcategory =
      subCategory.length > 0 ? `&subcategory=${subCategory}` : "";
    let qpLeafType =
      leafType.length > 0 && leafType !== "All"
        ? `&leafType=${leafType.charAt(0)}`
        : "";
    let qpRootBall =
      rootball.length > 0 && rootball !== "All"
        ? `&rootball=${rootball.charAt(0)}`
        : "";
    let qpStemType =
      stemType.length > 0 && stemType !== "All"
        ? `&stemType=${stemType.charAt(0)}`
        : "";
    let qpQtyFilterType =
      qtyFilterType.length > 0
        ? `&qtyFilterType=${qtyFilterType}`
        : "&qtyFilterType=Available";
    let qpQtyFilterComparison =
      qtyFilterComparison.length > 0
        ? `&qtyFilterComparison=${qtyFilterComparison}`
        : "&qtyFilterComparison=>";
    let qpQtyFilterValue = `&qtyFilterValue=${qtyFilterValue}`;
    let qpSearchString =
      searchString.length > 0 ? `&searchString=${encodedSearchString}` : "";
    let customerName =
      localStorage.getItem("token") == null ||
      isExpired() ||
      auth.user?.customer?.entityName === undefined
        ? `&${CUSTOMER_NAME}=unverified`
        : `&${CUSTOMER_NAME}=${encodeURIComponent(
            localStorage.getItem(CUSTOMER_NAME) as string
          )}`;
    let entityName =
      localStorage.getItem("token") == null ||
      isExpired() ||
      auth.user?.customer?.entityName === undefined
        ? `&${ENTITY_NAME}=${branch}`
        : `&${ENTITY_NAME}=${auth.user?.userCustomerAssociation?.defaultLocalBranch}`;
    let qpMaxResults = `&maxResults=${maxResults}`;

    let url = `/api/products?${qpEmail}${qpSearchString}${qpMinPrice}${qpMaxPrice}${qpMinAvailability}${qpSize}${qpGenus}${qpSpecies}${qpCategory}${qpSubcategory}${qpLeafType}${qpRootBall}${qpStemType}${qpQtyFilterType}${qpQtyFilterComparison}${qpQtyFilterValue}${customerName}${entityName}${qpEntitys}${qpMaxResults}`;

    try {
      datadogLogs.logger.info(
        "Making call for " + entityName + " : " + encodedSearchString
      );
      let res = await addHeadersAndCallBackend(url);

      //what we're trying to do: dynamically create a list of column names for our different prices
      //internal users will get a whole list of options representing the different price tiers
      //external users just get the single yard price they are qualified for
      let priceColumnNames: any = showPricing
        ? Object.keys(res.data[0].websiteItemAvailabilities[0].yardPrice)
        : [];

      let avail: WebsiteAvailabilityGroup[] = res.data;

      const transformedAvail = avail.map((x) =>
        Object.assign(
          x.websiteItemAvailabilities[0].yardPrice,
          { companyName: x.websiteItemAvailabilities[0].companyName },
          x
        )
      );
      downloadAvailability(transformedAvail, priceColumnNames);
    } catch (err) {
      datadogLogs.logger.error(
        auth.user?.email + " failed when downloading avails for " + url
      );
      console.log(err);
      setError(true);
      setLoading(false);
    }
  };

  const downloadAvailability = async (
    transformedAvailability: WebsiteAvailabilityGroup[],
    priceColumnNames: any
  ) => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Availability List");

    const columns = [
      { header: "Id", key: "id", width: 6 },
      { header: "Botanical Name", key: "botanicalName", width: 52 },
      { header: "Common Name", key: "commonName", width: 52 },
      { header: "Size", key: "size", width: 8 },
      { header: "Quantity", key: "quantity", width: 8 },
      { header: "Future Availability", key: "quantityOnPO", width: 15 },
      { header: "Company Name", key: "companyName", width: 15 },
      { header: "Item Code", key: "itemCode", width: 31 },
      { header: "Category", key: "category", width: 10 },
      { header: "Leaf Type", key: "leafType", width: 10 },
      { header: "Rootball", key: "rootball", width: 10 },
      { header: "Image Link", key: "imageLink", width: 10 },
    ];

    let ogLength = columns.length;

    priceColumnNames.map((x: string) =>
      columns.push({ header: x.toString(), key: x.toString(), width: 12 })
    );

    worksheet.columns = columns;
    transformedAvailability.map((avail) =>
      worksheet.addRow({
        ...avail,
        imageLink: avail.imageLink
          ? { text: "Image", hyperlink: avail.imageLink }
          : "",
      })
    );

    worksheet.getColumn("imageLink").font = {
      underline: true,
      color: { argb: "FF0000FF" },
    };
    worksheet.getRow(1).font = {
      underline: false,
      color: { argb: "000000" },
      bold: true,
    };

    for (let i = ogLength; i < transformedAvailability.length; i++) {
      worksheet.getColumn(i).numFmt = "$0.00";
    }

    await workbook.xlsx
      .writeBuffer()
      .then((data) => {
        let blobType: string =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
        const blob = new Blob([data], { type: blobType });
        FileSaver.saveAs(blob, `${branch} Availability List.xlsx`);
        setLoading(false);
        props.setOpen(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  const handleChange = (e: any) => {
    setBranch(e.target.value);
  };

  const renderDialogDropDown = () => {
    if (!props.open) {
      return <></>;
    }

    return (
      <>
        <DialogTitle>Select a Branch to Dowload the availability</DialogTitle>
        <DialogContent>
          <FormControl style={{ marginTop: 5 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Branch</InputLabel>
            <Select value={branch} label="Branch" onChange={handleChange}>
              {branches?.map((x, index) => {
                return (
                  <MenuItem key={index} value={x?.entityName}>
                    {x?.entityName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            onClick={getAvailabilityList}
            style={{ marginTop: 10 }}
            fullWidth
            variant="contained"
          >
            Download
          </Button>
        </DialogContent>
      </>
    );
  };

  const renderErrorDialog = () => {
    return (
      <>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          There has been an error loading the availability list, please try
          again later.
        </DialogContent>
      </>
    );
  };

  const renderDialogLoading = () => {
    return (
      <>
        <DialogTitle>
          Downloading Availabilty. This may take a few minutes...
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        </DialogContent>
      </>
    );
  };

  const handleClose = () => {
    props.setOpen(false);
    setError(false);
  };

  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth="lg">
      {error
        ? renderErrorDialog()
        : loading
        ? renderDialogLoading()
        : renderDialogDropDown()}
    </Dialog>
  );
};
