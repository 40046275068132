import React, {Dispatch, SetStateAction, useContext, useEffect} from "react";
import ImageGallery from "react-image-gallery";
import {PaddedCard} from "./PaddedCard";
import {StylesContext} from "../../providers/StylesProvider";
import {WebsiteAvailabilityGroup} from "../../types/ProductType";
export type Photo = {
    id: number;
    uploadTime?: string;
    branch: string;
    core: boolean;
    itemSpecific: boolean;
    shortItemCode: string;
    itemId: string;
    original: string;
    note: string;
    dimensions: string;
    url: string;
    rank: number;
}

export const DEFAULT_PHOTO: Photo = {
    id: -1,
    branch: "",
    core: false,
    itemSpecific: false,
    shortItemCode: "",
    itemId: "",
    original: "https://res.cloudinary.com/treesource-systems/image/upload/w_450,h_600,c_pad/v1661178206/BandB%20Trees/default_photo.png",
    note: "",
    dimensions: "",
    url: "https://res.cloudinary.com/treesource-systems/image/upload/v1661178206/BandB%20Trees/default_photo.png",
    rank: 0
}

export type PhotoGalleryProps = {
    itemcode: string;
    setSelectedPhoto: Dispatch<SetStateAction<Photo>>
    selectedProduct: WebsiteAvailabilityGroup
    photos: Photo[];
    setPhotos: Dispatch<SetStateAction<Photo[]>>
    pageReady: boolean;
}

export const PhotoGallery = (props: PhotoGalleryProps) => {
    // the purpose of this component is to decorate https://github.com/xiaolin/react-image-gallery
    // with our Photo objects and make it easy for me to get the data I want
    const { isDesktop } = useContext(StylesContext);
    const singlePhoto = props.photos.length === 1;

    useEffect(() => {
        if(props.photos && props.photos.length === 0 && props.selectedProduct.botanicalName !== "") {
            fetchPhotos(encodeURIComponent(props.itemcode))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.photos, props.selectedProduct.botanicalName]);

    const photoChanged = ( index: number) => {
        props.setSelectedPhoto(props.photos[index]);
    }

    const scaleForPortrait = (photoUrl: string, title: string) => {
        const encodedTitle = encodeURIComponent(title.replaceAll(",", ""));
        const scaleParams = `b_rgb:000000,bo_5px_solid_rgb:595959,c_lpad,h_800,w_600/c_scale,g_south,h_60,l_black_bar,o_60,w_600,y_5/g_south,l_text:Arial_30_bold:${encodedTitle},co_white,w_550,y_20`;
        // https://res.cloudinary.com/treesource-systems/image/upload/b_rgb:000000,bo_5px_solid_rgb:595959,c_lpad,h_800,w_600/c_scale,g_south,h_60,l_black_bar,o_60,w_600,y_5/g_south,l_text:Arial_30_bold:Pinus%20nigra%20-%20Pine%20Austrian,co_white,y_20/v1717800367/10E3G_c1_2024_06_07T1646.jpg
        return photoUrl.replace("/upload/", `/upload/${scaleParams}/`);
    }

    const scaleForThumbnail = (photoUrl: string) => {
        const scaleParams = "w_90,h_120,c_pad";

        return photoUrl.replace("/upload/", `/upload/${scaleParams}/`);
    }

    const stripItemSpecific = (name: string) => {
        //we want to find where the first number or # is in the string so that we can
        //grab everything before it.
        const regex = /^[^\d#]*/;

        // @ts-ignore
        return name.match(regex)[0];
    }

    const createPhotoTitle = (isCore: boolean,  product: WebsiteAvailabilityGroup) => {
        const botanicalName = isCore ? stripItemSpecific(product.botanicalName) : product.botanicalName;
        const commonName = isCore ? stripItemSpecific(product.commonName) : product.commonName;

        return botanicalName + " - " + commonName;
    }

    const fetchPhotos = async (itemcode: string) => {
        fetch(`/api/products/${itemcode}/photos`)
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((data) => {
                const transformedData = data.map((photoFromBackend: any) => {
                    const label = photoFromBackend.core ? "" : photoFromBackend.branch;

                    const title = createPhotoTitle(photoFromBackend.core, props.selectedProduct);

                    return {...photoFromBackend, original: scaleForPortrait(photoFromBackend.url, title), thumbnail: scaleForThumbnail(photoFromBackend.url), thumbnailLabel: label}
                });

                if(transformedData.length === 0) {
                    transformedData.push(DEFAULT_PHOTO);
                }

                props.setPhotos(transformedData);
                props.setSelectedPhoto(transformedData[0]);

            })
            .catch((error) => {
                console.error(
                    "There has been a problem with your fetch operation:",
                    error
                );
            });
    }

    const renderGallery = () => {
        //If there's only one photo the gallery looks wierd with reserved space for thumbnails.
        //Moving them to the bottom and then hiding them makes the gallery look like a single photo.
        return <PaddedCard >
            <ImageGallery
                showPlayButton={false}
                thumbnailPosition={singlePhoto ? "bottom" : "left"}
                showThumbnails={!singlePhoto}
                disableThumbnailScroll={true}
                useBrowserFullscreen={isDesktop}
                showFullscreenButton={!isDesktop}
                items={props.photos}
                onSlide={photoChanged}
            />
        </PaddedCard>;
    }

    return <>{props.pageReady ? renderGallery() : ""}</>
}