import {TextField} from "@mui/material";
import { useState, useRef, useEffect } from "react";

export type AluInfo = {count: number, expectedCount: number, comment: string, message: string};
type StringToCountMap = { [key: string]: AluInfo };

type ALUCounterProps = {
  branch: string;
  yard: string;
  block: string;
  aluCounts: StringToCountMap;
  setALUCounts: React.Dispatch<React.SetStateAction<StringToCountMap>>;
};

export const ALUCounter = (props: ALUCounterProps) => {
  const aluCounts: StringToCountMap = props.aluCounts;
  const setALUCounts = props.setALUCounts;

  // local data
  const [aluValue, setALUValue] = useState("");

  //ui controls
  const aluTextBoxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    aluTextBoxRef.current?.focus();
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setALUValue(event.target.value);
  };

  const updateALUCount = (
    alu: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const textValue = event.target.value;
    const newValue: AluInfo = {count: Number(textValue), comment: aluCounts[alu]?.comment, expectedCount: aluCounts[alu]?.expectedCount, message: aluCounts[alu]?.message};

    //empty means go ahead and clear the value. Otherwise, only update if it's a number
    if (textValue === "" || newValue.count) {
      setALUCounts((prevMap: { [key: string]: AluInfo }) => ({
        ...prevMap,
        [alu]: newValue || 0,
      }));
    } else if (textValue === "remove") {
      setALUCounts((prevMap: { [key: string]: AluInfo }) => {
        const newMap = { ...prevMap };
        delete newMap[alu];
        return newMap;
      });
    }
  };

  const fetchExpectedCount = async (alu: string) => {
    //let's submit our form to /api/cycle-count with a POST request using the fetch API. We will also need to include our token in the request header.
    fetch(`/api/cycle-count/${props.branch}/${props.yard}/${props.block}/${alu}`, {
      method: "GET",
      headers: {
        authorization: localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json",
      } as HeadersInit,
    })
      .then((response) => {
        if (response.ok) {
          response.json()
              .then((body) => updateLocationInfo(alu, body))
              .catch((error) => {
            handleError(alu,"An error occurred when parsing the response. How?");
          });
        } else {
          response.json().then((body) => {
            const errorMessage: string =
                body.message ?? "An error occurred, please try again later.";

            handleError(alu, errorMessage);
          }).catch((error) => {
            handleError(alu,"An error occurred. Is a valid location set?");
          });
        }
      })
      .catch((error) => {
        handleError(alu,"A network error occurred, please try again later.");
      });
  };

  const updateLocationInfo = (alu: string, body: any) => {
      const newValue: AluInfo = {count: aluCounts[alu]?.count || 1, comment: aluCounts[alu]?.comment || "", expectedCount: body.balance, message: body.name};
      setALUCounts((prevMap: { [key: string]: AluInfo }) => ({
        ...prevMap,
        [alu]: newValue,
      }));
  }

  const handleError = (alu: string, errorMessage: string) => {
    const newValue: AluInfo = {count: aluCounts[alu]?.count || 1, comment: aluCounts[alu]?.comment || "", expectedCount: 0, message: errorMessage};
    setALUCounts((prevMap: { [key: string]: AluInfo }) => ({
      ...prevMap,
      [alu]: newValue,
    }));
  }

  const updateComment = (
      alu: string,
      event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const textValue = event.target.value;
    const newValue: AluInfo = {count: aluCounts[alu]?.count, comment: textValue, expectedCount: aluCounts[alu]?.expectedCount, message: aluCounts[alu]?.message};

    //empty means go ahead and clear the value. Otherwise, only update if it's a number
    if (textValue === "" || newValue.count) {
      setALUCounts((prevMap: { [key: string]: AluInfo }) => ({
        ...prevMap,
        [alu]: newValue || 0,
      }));
    } else if (textValue === "remove") {
      setALUCounts((prevMap: { [key: string]: AluInfo }) => {
        const newMap = { ...prevMap };
        delete newMap[alu];
        return newMap;
      });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && aluValue !== "") {
      const wasNew = !aluCounts[aluValue];

      setALUCounts((prevMap: { [key: string]: AluInfo }) => ({
        ...prevMap,
        [aluValue]: { count: (prevMap[aluValue]?.count || 0) + 1, comment: prevMap[aluValue]?.comment || "", expectedCount: prevMap[aluValue]?.expectedCount || 0, message: prevMap[aluValue]?.message || "Loading..."},
      }));

      if (wasNew) {
          fetchExpectedCount(aluValue);
      }

      setALUValue("");

      event.preventDefault(); // prevent form submission
    }
  };

  return (
    <>
      <div style={{ marginTop: 15, marginRight: 10 }}>
        <TextField
          inputRef={aluTextBoxRef}
          id="aluTextBox"
          fullWidth
          label="Scan Item ALU Here"
          type="text"
          value={aluValue}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />
      </div>
      <ul>
        {Object.keys(aluCounts).map((key) => (
          <li key={key}>
            <div >
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <div>
                  <label style={{marginRight: 10}}>{key}:</label>
                  <input
                      style={{width: "32px"}}
                      type="text"
                      value={aluCounts[key].count}
                      onChange={(event) => updateALUCount(key, event)}
                  />
                  <button
                      style={{marginLeft: "10px"}}
                      type="button"
                      onClick={() =>
                          updateALUCount(key, {target: {value: "remove"}} as any)
                      }
                  >
                    X
                  </button>
                </div>
                <div style={{marginRight: 20}}>
                  Expected: {aluCounts[key].expectedCount}
                </div>
              </div>
              <div style={{marginTop: "10px", marginRight: "20px"}}>
                <input
                    style={{width: "100%"}}
                    type="text"
                    placeholder="Comment"
                    value={aluCounts[key].comment}
                    onChange={(event) => updateComment(key, event)}
                />
              </div>
              <div style={{marginTop: "10px", marginBottom: "10px"}}>
                {aluCounts[key].message}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};
