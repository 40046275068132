import { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import {
  CardMedia,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  CardActions,
  useMediaQuery,
} from "@mui/material";
import { useHistory } from "react-router";
import Axios from "axios";
import { AuthContext } from "../../providers/AuthProvider";
import {
  EntityWebsiteSettings,
  WebsiteSettings,
} from "../../types/WebsiteSettings";
import { StylesContext } from "../../providers/StylesProvider";
import EntityCard from "../guiComponents/EntityCard";
import LoyaltyRewardsTracker from "../guiComponents/loyaltyProgram/LoyaltyRewardsTracker";

const image =
  "https://res.cloudinary.com/treesource-systems/image/upload/v1713238943/treesource-background_h7yxm8.jpg";

const PREFIX = "Home";

const classes = {
  media: `${PREFIX}-media`,
  level1: `${PREFIX}-headerTextContainer`,
  cardTopLeft: `${PREFIX}-cardTopLeft`,
  cardTopRight: `${PREFIX}-cardTopRight`,
  level2: `${PREFIX}-level2`,
  level2Child: `${PREFIX}-level2Child`,
  level3Child: `${PREFIX}-level3Child`,
  level3: `${PREFIX}-level3`,
  youTubeSection: `${PREFIX}-youTubeSection`,
};

const Root = styled("div")({
  [`& .${classes.media}`]: {
    height: 600,
    width: 1000,
  },
  [`& .${classes.level1}`]: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "#1C370E",
    backgroundPosition: "center center",
  },
  [`& .${classes.cardTopLeft}`]: {
    padding: "15px",
    margin: "15px",
    opacity: "85%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  [`& .${classes.cardTopRight}`]: {
    padding: "15px",
    margin: "15px",
    opacity: "85%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  [`& .${classes.level2}`]: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    margin: "35px",
    flexWrap: "wrap",
  },
  [`& .${classes.level2Child}`]: {
    padding: "15px",
    margin: "15px",
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: "100px",
    minHeight: "250px",
  },
  [`& .${classes.level3Child}`]: {
    padding: "5px",
    margin: "5px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: "200px",
    width: "20%",
  },
  [`& .${classes.level3}`]: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
    flexWrap: "wrap",
    marginBottom: "150px",
  },
  [`& .${classes.youTubeSection}`]: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    backgroundColor: "#1D4151",
  },
});

const Home = () => {
  const history = useHistory();
  const { user, loggedOutLocation } = useContext(AuthContext);
  const { navBarHeight } = useContext(StylesContext);
  const { isDesktop } = useContext(StylesContext);

  useEffect(() => {
    getProperty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProperty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedOutLocation]);

  const [websiteProperties, setWebsiteProperties] = useState<WebsiteSettings>();
  const [entitySettings, setEntitySettings] = useState<EntityWebsiteSettings>();
  const over480 = useMediaQuery("(min-width:480px");

  const getProperty = async () => {
    try {
      let res = await Axios.get("/api/applicationsettings");
      setWebsiteProperties(res.data);
      let data: WebsiteSettings = res.data;

      handleSettingApplicationSettings(data);
    } catch (error) {}
  };

  const handleSettingApplicationSettings = (data: WebsiteSettings) => {
    let treeSourceSettings = data.entityWebsiteSettings
      .filter((x) => x.defaultEntity)
      .at(0);

    let entitySettings;
    if (user == null) {
      entitySettings = data.entityWebsiteSettings
        .filter((x) => x.entityName === loggedOutLocation)
        .at(0);
    } else {
      entitySettings = data.entityWebsiteSettings
        .filter((x) => x.entityName === user?.customer?.entityName)
        .at(0);
    }

    setEntitySettings(
      entitySettings?.promoProduct1Image ? entitySettings : treeSourceSettings
    );
  };

  return (
    <Root>
      {/*flexDirection: wideScreen ? "row" : "column"*/}
      <div style={{ paddingTop: navBarHeight, paddingBottom: isDesktop ? "20vh" : "5vh" }} className={classes.level1}>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: isDesktop ? "20vh" : "auto" }}>
          {/*marginTop: isDesktop ? "20vh" : "auto"*/}
          <Card className={classes.cardTopLeft} style={{ width: over480 ? 480 : "100%"}} >
            <CardMedia
                component="img"
                src={
                    websiteProperties?.globalWebsiteSettings.homePageMainBannerLogo ||
                    "https://mcusercontent.com/b61bde706ca219f6690032ed4/images/73ac7372-e286-ae4e-59b0-233c8a6ebcd6.png"
                }
            />
          </Card>
          <Card className={classes.cardTopRight} style={{ width: over480 ? 480 : "100%"}} color="white">
            <CardHeader
                style={{ textAlign: "center" }}
                title={
                    websiteProperties?.globalWebsiteSettings
                        .homePageMainBannerTitle || "Welcome to Tree Source"
                }
            />
            <Typography
                style={{
                  paddingTop: 25,
                  paddingBottom: "2em",
                  textAlign: "center",
                }}
                variant="body1"
            >
              {websiteProperties?.globalWebsiteSettings
                      .homePageMainBannerParagraph ||
                  "TreeSource Systems is the interconnectivity of expert growing, specialized purchasing, and intricate logistics. We combine all of these to provide convenience and availability to landscape professionals and wholesale nurseries throughout the Intermountain West."}
            </Typography>
            <Button
                variant="contained"
                onClick={() =>
                    history.push(
                        `${websiteProperties?.globalWebsiteSettings.homePageMainBannerLink}`
                    )
                }
            >
              View Availability
            </Button>
          </Card>
          <LoyaltyRewardsTracker />
        </div>

      </div>

      <Typography
        variant={isDesktop ? "h3" : "h4"}
        style={{ textAlign: "center", paddingTop: "30px" }}
      >
        Locations
      </Typography>
      <div className={classes.level2}>
        {websiteProperties?.entityWebsiteSettings
          ?.filter((x) => x.entityName !== "Tree Source")
          .map((x) => {
            return (
              <EntityCard
                entityName={x.entityName}
                streetAddress={x.address}
                phoneNumber={x.phoneNumber}
                addressUrl={x.directionsLink}
                includeBelgardImage={true}
              />
            );
          })}
      </div>
      <div>
        <Typography
          variant={isDesktop ? "h3" : "h4"}
          style={{ textAlign: "center" }}
        >
          Featured Products
        </Typography>
        <div className={classes.level3}>
          <Card className={classes.level3Child}>
            <CardMedia
              component="img"
              src={`${entitySettings?.promoProduct1Image}`}
            />
            <CardContent>
              <Typography gutterBottom variant="subtitle2" component="div">
                {entitySettings?.promoProduct1Title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {entitySettings?.promoProduct1Subtitle}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                variant="contained"
                onClick={() =>
                  history.push(`${entitySettings?.promoProduct1Link}`)
                }
              >
                View Now
              </Button>
            </CardActions>
          </Card>
          <Card className={classes.level3Child}>
            <CardMedia
              component="img"
              src={entitySettings?.promoProduct2Image}
            />
            <CardContent>
              <Typography gutterBottom variant="subtitle2" component="div">
                {entitySettings?.promoProduct2Title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {entitySettings?.promoProduct2Subtitle}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                variant="contained"
                onClick={() =>
                  history.push(`${entitySettings?.promoProduct2Link}`)
                }
              >
                View Now
              </Button>
            </CardActions>
          </Card>
          <Card className={classes.level3Child}>
            <CardMedia
              component="img"
              src={`${entitySettings?.promoProduct3Image}`}
            />
            <CardContent>
              <Typography gutterBottom variant="subtitle2" component="div">
                {entitySettings?.promoProduct3Title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {entitySettings?.promoProduct3Subtitle}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                variant="contained"
                onClick={() =>
                  history.push(`${entitySettings?.promoProduct3Link}`)
                }
              >
                View Now
              </Button>
            </CardActions>
          </Card>
          <Card className={classes.level3Child}>
            <CardMedia
              component="img"
              src={entitySettings?.promoProduct4Image}
            />
            <CardContent>
              <Typography gutterBottom variant="subtitle2" component="div">
                {entitySettings?.promoProduct4Title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {entitySettings?.promoProduct4Subtitle}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                variant="contained"
                onClick={() =>
                  history.push(`${entitySettings?.promoProduct4Link}`)
                }
              >
                View Now
              </Button>
            </CardActions>
          </Card>
        </div>
      </div>
    </Root>
  );
};

export default Home;
