import {StylesContext} from "../../../providers/StylesProvider";
import React, {useContext, useEffect, useState} from "react";
import {CustomerContext} from "../../../providers/CustomerProvider";
import {BankAccountWithNickname, CardWithNickname, PaymentMethod, TYPE_CARD} from "../../../types/CardDataType";
import {SelectChangeEvent} from "@mui/material/Select";
import {Button, CardHeader, FormControl, MenuItem, Select} from "@mui/material";
import {NEW_BANK_ACCOUNT, NEW_CARD} from "./PaymentCardConstants";
import {useHistory} from "react-router";

export type PaymentMethodDropDownProps = {
    paymentMethod: PaymentMethod;
    setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>
}

const PaymentMethodDropDown: React.FC<PaymentMethodDropDownProps> = (props: PaymentMethodDropDownProps) => {
    //context
    const { isDesktop } = useContext(StylesContext);

    //react state
    const [availablePaymentMethods, setAvailablePaymentMethods] = useState<PaymentMethod[]>([]);
    const history = useHistory();

    //local
    const { setPaymentMethod} = props;
    const { fetchSavedPaymentMethods } = useContext(CustomerContext); //hmm, do we like this? Its advantage is that I'll use the same in the manage cards page

    useEffect(() => {
        if(fetchSavedPaymentMethods && setPaymentMethod) {
            fetchSavedPaymentMethods()
                .then((paymentMethods) => {
                    setAvailablePaymentMethods([...paymentMethods]);

                    return paymentMethods;
                })
                .then((paymentMethods: PaymentMethod[]) => {
                    const defaultCard = paymentMethods.find((x) => x.isDefault);

                    if(defaultCard) {
                        setPaymentMethod(defaultCard);
                    } else {
                        setPaymentMethod(NEW_CARD);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching saved cards: ", error);
                });
        }
    }, [fetchSavedPaymentMethods, setPaymentMethod]);

    // const handlePaymentOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     props.setPaymentOption(event.target.value);
    // };

    const handlePaymentMethodChange = (event: SelectChangeEvent<string>) => {
        const selectedPaymentId = event.target.value;
        const selectedPaymentMethod = availablePaymentMethods.find(card => card.id === selectedPaymentId);
        if (selectedPaymentMethod) {
            props.setPaymentMethod(selectedPaymentMethod);
        } else if (selectedPaymentId === NEW_CARD.id) {
            props.setPaymentMethod(NEW_CARD);
        } else {
            props.setPaymentMethod(NEW_BANK_ACCOUNT);
        }
    };

    const renderCards = () => {
        if(availablePaymentMethods.length === 0) {
            return <MenuItem key={"Loading"} value={"Loading"} >
                Loading...
            </MenuItem>
        }

        return availablePaymentMethods?.map((x) => {
            if (x.nickname === NEW_CARD.id) { //x is being returned from an older endpoint. I'd rather not change it, but here "New Card" is stored in the id.
                return renderNewCard(x);
            } else if(x.nickname === NEW_BANK_ACCOUNT.id) {
                return renderNewBankAccount(x);
            } else if (x.type === TYPE_CARD) {
                return renderSavedCard(x as CardWithNickname);
            } else { // (x.type === TYPE_BANK_ACCOUNT)
                return renderSavedBankAccount(x as BankAccountWithNickname);
            }
        });
    }

    const renderNewCard = (currentPaymentMethod: PaymentMethod) => {
        return <MenuItem key={NEW_CARD.id} value={NEW_CARD.id} >
            {NEW_CARD.id}
        </MenuItem>
    }

    const renderSavedCard = (currentPaymentMethod: CardWithNickname) => {
        return <MenuItem
            key={currentPaymentMethod.id}
            value={currentPaymentMethod.id}
        >
            {currentPaymentMethod.cardType +
                " - " +
                currentPaymentMethod.number.substring(currentPaymentMethod.number.length - 4) +
                (currentPaymentMethod.nickname ? " | " + currentPaymentMethod.nickname : "")
            }
        </MenuItem>
    }

    const renderNewBankAccount = (currentPaymentMethod: PaymentMethod) => {
        return <MenuItem key={NEW_BANK_ACCOUNT.id} value={NEW_BANK_ACCOUNT.id} >
            {NEW_BANK_ACCOUNT.id}
        </MenuItem>
    }

    const renderSavedBankAccount = (currentPaymentMethod: BankAccountWithNickname) => {
        return <MenuItem
            key={currentPaymentMethod.id}
            value={currentPaymentMethod.id}
        >
            {"BANK - " +
                currentPaymentMethod.accountNumber.substring(currentPaymentMethod.accountNumber.length - 4) +
                (currentPaymentMethod.nickname ? " | " + currentPaymentMethod.nickname : "")
            }
        </MenuItem>
    }

    const renderDesktop = () => {
        return <>
            <FormControl  style={{width: "42%"}} >
                <CardHeader title="Payment Method" />

                <Select value={props.paymentMethod.id} onChange={handlePaymentMethodChange}>
                    {renderCards()}
                </Select>

                <Button
                    onClick={() => history.push('/payments/manage')}
                    style={{color: '#0000EE', textTransform: 'none', textDecoration: 'underline'}}
                >
                    Manage payment methods
                </Button>
            </FormControl>
        </>
    }

    const renderMobile = () => {
        return <>
            <CardHeader title="Payment Method" />

            <div style={{display: "flex", justifyContent: "center", marginBottom: 20}}>
                <FormControl  style={{width: "100%",  marginLeft: 20, marginRight: 20 }} >
                    <Select value={props.paymentMethod.id} onChange={handlePaymentMethodChange}>
                        {renderCards()}
                    </Select>

                    <Button
                        onClick={() => history.push('/payments/manage')}
                        style={{color: '#0000EE', textTransform: 'none', textDecoration: 'underline'}}
                    >
                        Manage Payment Methods
                    </Button>
                </FormControl>
            </div>
        </>
    }

    return <>
        {isDesktop ? renderDesktop() : renderMobile()}
    </>;
}

export default PaymentMethodDropDown;