import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./providers/AuthProvider";
import "@fontsource/roboto";
import { CustomerProvider } from "./providers/CustomerProvider";
import { OrderProvider } from "./providers/OrderProvider";
import { StyledEngineProvider } from "@mui/material";
import { createRoot } from "react-dom/client";
import { BranchProvider } from "./providers/BranchProvider";
import { StylesProvider } from "./providers/StylesProvider";
import {GlobalProvider} from "./providers/GlobalContext";

// initMiddleware()
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <GlobalProvider>
      <AuthProvider>
        <BranchProvider>
          <StylesProvider>
            <CustomerProvider>
              <OrderProvider>
                <StyledEngineProvider injectFirst>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </StyledEngineProvider>
              </OrderProvider>
            </CustomerProvider>
          </StylesProvider>
        </BranchProvider>
      </AuthProvider>
    </GlobalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
