import { Autocomplete, TextField } from "@mui/material";
import { useState, useEffect } from "react";

type Location = {
  branch: string;
  yard: string;
  block: string;
};

export type { Location };

type LocationSelectorProps = {
  location: Location;
  setLocation: React.Dispatch<React.SetStateAction<Location>>;
};

export const LocationSelector = (props: LocationSelectorProps) => {
  // data from backend
  const [locations, setLocations] = useState<{
    [key: string]: { [key: string]: string[] };
  }>();

  // local data
  const NO_SELECTION = "";
  const selectedLocation = props.location;
  const setSelectedLocation = props.setLocation;

  const [branchOptions, setBranchOptions] = useState<string[]>([NO_SELECTION]);
  const [yardOptions, setYardOptions] = useState<string[]>([NO_SELECTION]);
  const [blockOptions, setBlockOptions] = useState<string[]>([NO_SELECTION]);

  const fetchLocations = async () => {
    fetch("/api/cycle-count/locations", {
      headers: {
        Authorization: localStorage.getItem("token") ?? undefined,
      } as HeadersInit,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        setLocations(data);
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  };

  const updateLocationOptions = () => {
    let myBranchOptions = Object.keys(
      locations ?? { NO_SELECTION: { "": [""] } }
    );

    if (!myBranchOptions.includes(NO_SELECTION)) {
      myBranchOptions.push(NO_SELECTION);
    }

    myBranchOptions.sort()

    setBranchOptions(myBranchOptions);

    let myYardOptions = Object.keys(
      locations?.[selectedLocation.branch] ?? { "": [""] }
    );

    myYardOptions.sort();

    if (!myYardOptions.includes(NO_SELECTION)) {
      myYardOptions.push(NO_SELECTION);
    }

    setYardOptions(myYardOptions);

    let myBlockOptions = locations?.[selectedLocation.branch]?.[
      selectedLocation.yard
    ] ?? [NO_SELECTION];

    myBlockOptions.sort();

    if (!myBlockOptions.includes(NO_SELECTION)) {
      myBlockOptions.push(NO_SELECTION);
    }

    setBlockOptions(myBlockOptions);

    if (!myBranchOptions.includes(selectedLocation.branch)) {
      setSelectedLocation({ ...selectedLocation, branch: NO_SELECTION });
    } else if (!myYardOptions.includes(selectedLocation.yard)) {
      setSelectedLocation({
        ...selectedLocation,
        yard: NO_SELECTION,
        block: NO_SELECTION,
      });
    } else if (!myBlockOptions.includes(selectedLocation.block)) {
      setSelectedLocation({ ...selectedLocation, block: NO_SELECTION });
    }
  };

  useEffect(() => {
    fetchLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateLocationOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    locations,
    selectedLocation.branch,
    selectedLocation.yard,
    selectedLocation.block,
  ]);

  return (
    <>
      <Autocomplete
        id="branchAutoComplete"
        style={{ marginRight: 10 }}
        options={branchOptions}
        value={selectedLocation.branch}
        onChange={(event, value) => {
          setSelectedLocation({ ...selectedLocation, branch: value ?? "" });
        }}
        renderInput={(params) => (
          <TextField {...params} label="Branch" type="text" />
        )}
      />
      <div style={{ display: "flex", marginTop: 10 }}>
        <Autocomplete
          id="yardAutoComplete"
          style={{ marginRight: 10, flex: "1" }}
          options={yardOptions}
          value={selectedLocation.yard}
          onChange={(event, value) => {
            setSelectedLocation({ ...selectedLocation, yard: value ?? "" });
          }}
          renderInput={(params) => (
            <TextField {...params} label="Yard" type="text" />
          )}
        />

        <Autocomplete
          id="blockAutoComplete"
          style={{ marginRight: 10, flex: "1" }}
          options={blockOptions}
          value={selectedLocation.block}
          onChange={(event, value) => {
            setSelectedLocation({ ...selectedLocation, block: value ?? "" });
          }}
          renderInput={(params) => (
            <TextField {...params} label="Block" type="text" />
          )}
        />
      </div>
    </>
  );
};
