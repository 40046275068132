import React, {useContext} from "react";

import {StylesContext} from "../../../providers/StylesProvider";
import {Box, Button, Checkbox, FormControlLabel, TextField} from "@mui/material";
import {PaymentMethod, TYPE_CARD} from "../../../types/CardDataType";

export type SavePaymentMethodFieldsProps = {
    //Required and used on the [take] payment page
    setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
    paymentMethod: PaymentMethod;
    savePaymentMethod: boolean;
    setSavePaymentMethod: React.Dispatch<React.SetStateAction<boolean>>;

    //Optional and used on the manage payment page
    cancel?: () => void;
    save?: () => void;
}

const SavePaymentMethodFields: React.FC<SavePaymentMethodFieldsProps> = (props: SavePaymentMethodFieldsProps) => {
    const { isDesktop } = useContext(StylesContext)

    //If the cancel and save functions are passed in, the card is being used on the manage payment methods page
    const saveByItself = props.cancel && props.save;
    const partOfPaymentPage = !saveByItself;

    const methodDesc = props.paymentMethod.type === TYPE_CARD ? "Save Card" : "Save Bank Account";

    const renderDesktop = () => {
        return <div style={{marginLeft: 10, display: "flex", alignItems: "center"}}>
            {partOfPaymentPage && <FormControlLabel
                control={<Checkbox checked={props.savePaymentMethod}
                                   onChange={(event) => props.setSavePaymentMethod(event.target.checked)}/>}
                label={methodDesc}
            />}
            {props.savePaymentMethod && <FormControlLabel
                control={<Checkbox checked={props.paymentMethod.isDefault} onChange={(event) => {
                    props.setPaymentMethod({...props.paymentMethod, isDefault: event.target.checked});
                }}/>}
                label="Default"/>}

            {props.savePaymentMethod &&
                <TextField label="Nickname"
                           variant="outlined"
                           margin="dense"
                           style={{flexGrow: 1}}
                           value={props.paymentMethod.nickname}
                           onChange={(event) => {
                               props.setPaymentMethod({...props.paymentMethod, nickname: event.target.value});
                           }}
                />}
            {saveByItself && <Box style={{display: "flex", justifyContent: "space-between", marginTop: 8}}>
                <Button style={{marginLeft: 10}} variant="contained" color="primary"
                        onClick={props.save}>Save</Button>
                <Button style={{marginLeft: 10}} variant="contained" color="secondary"
                        onClick={props.cancel}>Cancel</Button>
            </Box>}
        </div>
    }

    const renderMobile = () => {
        return <>
            <div style={{display: "flex", alignItems: "center"}}>
                {props.savePaymentMethod &&
                    <TextField label="Nickname"
                               variant="outlined"
                               margin="dense"
                               style={{width: "59%", marginTop: 10}}
                               value={props.paymentMethod.nickname}
                               onChange={(event) => {
                                   props.setPaymentMethod({...props.paymentMethod, nickname: event.target.value});
                               }}
                    />}
                {props.savePaymentMethod && <FormControlLabel
                    control={<Checkbox checked={props.paymentMethod.isDefault}
                                       style={{marginLeft: 10}}
                                       onChange={(event) => {
                                           props.setPaymentMethod({
                                               ...props.paymentMethod,
                                               isDefault: event.target.checked
                                           });
                                       }
                                       }/>}
                    label="Default"
                />}
            </div>
            <div style={{marginLeft: 10, marginTop: 5}}>
                {partOfPaymentPage && <FormControlLabel
                    control={<Checkbox checked={props.savePaymentMethod}
                                       onChange={(event) => props.setSavePaymentMethod(event.target.checked)}/>}
                    label={methodDesc}
                />}
            </div>
            {saveByItself && <Box style={{display: "flex", justifyContent: "space-between", marginTop: 8}}>
                <Button style={{width: "48%"}} variant="contained" color="primary"
                        onClick={props.save}>Save</Button>
                <Button style={{width: "48%", marginLeft: 10}} variant="contained" color="secondary"
                        onClick={props.cancel}>Cancel</Button>
            </Box>}
        </>
    }

    return <>
        {isDesktop ? renderDesktop() : renderMobile()}
    </>
}

export default SavePaymentMethodFields;