import {Button, Card, CardContent, Checkbox, Tooltip, Typography} from "@mui/material";
import React from "react";
import {AccountEvent} from "./PaySpecificInvoicesCard";
import {GridSelectionModel} from "@mui/x-data-grid";
import {formatter} from "../../../constants/Constants";

type SpecificAccountBalanceEventListItemProps = {
    accountBalanceEvent: AccountEvent;
    selectedRowModel: GridSelectionModel;
    setSelectedRowModel: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
    handleEventSelectionChange: (newSelection: GridSelectionModel) => void;
    handlePdfClick: (pressedRow: AccountEvent) => void;
}

const SpecificAccountBalanceEventListItem = (props: SpecificAccountBalanceEventListItemProps ) => {
    //local variables
    const event = props.accountBalanceEvent;
    const isDisabled = !event.url;

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            props.setSelectedRowModel((prevSelectedRowModel) => {
                const newSelectedRowModel = [...prevSelectedRowModel, props.accountBalanceEvent.refNumber];
                props.handleEventSelectionChange(newSelectedRowModel);
                return newSelectedRowModel;
            });
        } else {
            props.setSelectedRowModel((prevSelectedRowModel) => {
                const newSelectedRowModel = prevSelectedRowModel.filter(id => id !== props.accountBalanceEvent.refNumber);
                props.handleEventSelectionChange(newSelectedRowModel);
                return newSelectedRowModel;
            });
        }
    }

    return <>
        <Card style={{ marginLeft: 2, marginRight: 2, marginBottom: '10px' }}>
            <CardContent style={{ display: 'flex', flexDirection: 'column'}}>
                <div style={{
                    display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"
                }}>
                    <div style={{display: "flex"}}>
                        <Typography variant="h6" style={{fontWeight: 'bold'}}>{event.type}</Typography>
                        <Tooltip title="We're sorry, this event came from another system. Please reach out to our AR team to get a PDF." disableHoverListener={!isDisabled}>
                            <span>
                                <Button
                                    onClick={() => {
                                        props.handlePdfClick(event)
                                    }}
                                    style={{textDecoration: 'underline', textTransform: 'none'  }}
                                    disabled={isDisabled}
                                >
                                    <Typography variant="body1">#{event.refNumber}</Typography>
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
                    <Checkbox onChange={handleCheckboxChange} />
                </div>
                <div style={{display: "flex"}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>Balance</Typography><Typography
                    variant="subtitle1">: {formatter.format(event.balanceRemaining as number)}</Typography>
                </div>
                <div style={{display: "flex"}}>
                    <Typography variant="body2" style={{fontWeight: 'bold'}}>Due Date</Typography>
                    <Typography variant="body2">: {event.dueDate}</Typography>
                </div>
                <div style={{display: "flex"}} >
                    <Typography variant="body2" style={{fontWeight: 'bold'}}>PO Number</Typography>
                    <Typography variant="body2">: {event.poNumber}</Typography>
                </div>
                <div style={{display: "flex"}} >
                    <Typography variant="body2" style={{fontWeight: 'bold'}}>Memo </Typography>
                    <Typography variant="body2">: {event.memo}</Typography>
                </div>
            </CardContent>
        </Card>
    </>

}

export default SpecificAccountBalanceEventListItem;