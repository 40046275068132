import {OBJECT_TYPE, ValidationRule} from "./ValidationRule";
import { NewUserData } from "../Register";

class EmailsMatchRule implements ValidationRule {
    type = OBJECT_TYPE

    isValid(data: NewUserData): boolean {
        return data.email === data.confirmEmail;
    }

    getErrorMessage(fieldName: string): string {
        return `Emails do not match`;
    }
}

export default EmailsMatchRule;