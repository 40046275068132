import * as React from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import {CustomerContext} from "../../providers/CustomerProvider";
import {useCallback, useContext, useEffect, useState} from "react";
import {JobLine} from "../authComponents/registration/validators/jobLineApplication/JobLine";
import {AuthContext} from "../../providers/AuthProvider";



const columns: GridColDef[] = [
    { field: 'jobName', headerName: 'Name', flex: 7 },
    { field: 'terms', headerName: 'Terms', flex: 2 },
    { field: 'totalBalance', headerName: 'Total Balance', flex: 2 },
    { field: 'creditLimit', headerName: 'Credit Limit', flex: 2 },
];

const JobLineData = () => {
    //context
    const { getParentCustomerName } = useContext(CustomerContext);
    const auth = useContext(AuthContext);

    //local state
    const [jobLines, setJobLines] = useState<JobLine[]>([]);
    const customerName = auth.user?.customer?.fullName ?? "";

    const fetchJobLines = useCallback((customerName: string) => {
        const parentCustomerName = getParentCustomerName ? getParentCustomerName(customerName) : customerName;
        const encodedCustomerName = encodeURIComponent(parentCustomerName);

        fetch(`/api/customer/${encodedCustomerName}/job-lines`, {
            headers: {
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((data) => {
                setJobLines(data);
            })
            .catch((error) => {
                console.error(
                    "There has been a problem with your fetch operation:",
                    error
                );
            });
    }, [getParentCustomerName]);

    useEffect(() => {
        fetchJobLines(customerName);
    }, [customerName, fetchJobLines]);

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGridPro rows={jobLines} columns={columns} />
        </div>
    );
};

export default JobLineData;