import {
  Typography,
  Button,
  TextField,
  Autocomplete,
  Input,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import XFormData from "../../Utilities/XFormData";
import Axios from "axios";
import { CUSTOMER_NAME } from "../../constants/Constants";
import { useHistory } from "react-router";
import { OrderContext } from "../../providers/OrderProvider";

const PREFIX = "AddItemDialog";

const classes = {
  sideBarMain: `${PREFIX}-sideBarMain`,
};

const StyledPaper = styled("div")({
  [`&.${classes.sideBarMain}`]: {
    padding: "20px",
    margin: "5px",
  },
});

export type AddItemProps = {
  openOrder: any[];
  itemCode: string;
  maxQuantity: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
  callback: any;
  vendor: string;
  selectedLogistics: string;
};

const addHeadersAndPostBackend = (url: string, data: any) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "Cache-Control": "no-store",
    },
  };

  return Axios.post(url, data, config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      if (error.response.data.message) {
        return error.response.data.message;
      } else {
        return "We encountered an error. Please reach out is the problem persists.";
      }
    });
};

export const AddItemDialog = (props: AddItemProps) => {
  let [maxQuantity] = useState<number>(props.maxQuantity);
  let [errorMessage, setErrorMessage] = useState<string>("");
  let [quantityError, setQuantityError] = useState<boolean>(false);
  let [orderError, setOrderError] = useState<boolean>(false);
  let [enteredQuantity, setQuantity] = useState<any>(0);
  let [newJobName, setNewJobName] = useState<string>("");

  const { getUnfinishedOrders, selectedOrderForAdd, setSelectedOrderForAdd } =
    useContext(OrderContext);

  const history = useHistory();

  const optionalJobNameField = () => {
    let useNewOrder =
      selectedOrderForAdd !== null &&
      selectedOrderForAdd !== undefined &&
      selectedOrderForAdd.orderID < 0;

    return useNewOrder ? (
      <TextField
        style={{ marginLeft: 10, marginTop: 10, width: 270 }}
        id="new-shipment-name"
        onChange={(e) => setNewJobName(e.target.value)}
        label="Job Name"
      />
    ) : null;
  };

  function checkForm() {
    let quantityOk = true;
    let orderOk = true;

    if (selectedOrderForAdd === undefined || selectedOrderForAdd === null) {
      orderOk = false;
    }

    if (enteredQuantity <= 0) {
      quantityOk = false;
    }

    if (enteredQuantity > maxQuantity) {
      quantityOk = false;
    }

    setQuantityError(!quantityOk);
    setOrderError(!orderOk);

    return "";
  }

  function describeFormProblems(enteredQuantity: number) {
    //we really only have space for one error message at a time.
    if (enteredQuantity <= 0) {
      return "Please enter a quantity > 0. ";
    }

    if (enteredQuantity > maxQuantity) {
      return "We only have " + maxQuantity + " of these. Would that work? ";
    }

    if (selectedOrderForAdd === undefined || selectedOrderForAdd === null) {
      return "Please select an order. ";
    }

    return "";
  }

  function ordersAreEqual(option: any, value: any) {
    if (option.orderID === value.orderID) {
      return true;
    } else {
      return false;
    }
  }

  async function submitAddRequest(e: any) {
    e.preventDefault();

    checkForm();
    let firstFormProblem = describeFormProblems(enteredQuantity);

    if (firstFormProblem.length > 0) {
      setErrorMessage(firstFormProblem);
    } else {
      if (selectedOrderForAdd) {
        let orderName =
          selectedOrderForAdd.orderID < 0
            ? newJobName
            : selectedOrderForAdd.orderName;

        if (orderName === null || orderName === undefined) {
          orderName = "";
        }

        const customerName = encodeURIComponent(
          localStorage.getItem(CUSTOMER_NAME) as string | number | boolean
        );
        const entityName = props.vendor as string | number | boolean;

        const data = new XFormData().format({
          itemCode: props.itemCode,
          quantity: enteredQuantity,
          /* used for the shipment's name, if a new one needs to be created */
          shipmentName: orderName,
          customer: customerName,
          entityName: entityName,
          shipmentType: props.selectedLogistics,
        });
        let res = await addHeadersAndPostBackend(
          "/api/orderlineitems/order/" + selectedOrderForAdd.orderID,
          data
        );

        if (getUnfinishedOrders) {
          getUnfinishedOrders();
        }

        history.push(
          `/products?orderid=${res.data.orderID}&shipmentid=${res.data.shipmentID}`
        );

        props.setOpen(false);
        props.callback(res);
      }
    }
  }

  return (
    <StyledPaper className={classes.sideBarMain}>
      <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
        variant="h6"
      >
        Add Item
      </DialogTitle>
      <DialogContent>
        <form
          style={{ display: "flex", flexDirection: "column" }}
          onSubmit={submitAddRequest}
        >
          <hr></hr>
          <Autocomplete
            id="open-order-combo-box"
            options={props.openOrder}
            style={{ marginLeft: 10, width: 270 }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={orderError}
                label="Please select an order"
              />
            )}
            onChange={(event, value) => {
              if (setSelectedOrderForAdd) {
                setSelectedOrderForAdd(value);
              }
            }}
            value={selectedOrderForAdd}
            isOptionEqualToValue={ordersAreEqual}
          />

          {optionalJobNameField()}

          <Input
            type="number"
            error={quantityError}
            autoFocus
            style={{ marginLeft: 10, marginTop: 10, width: 270 }}
            onChange={(e) => setQuantity(e.target.value)}
            placeholder="How Many?"
            id="quantity"
          />

          <Typography style={{ color: "red", textAlign: "center" }}>
            {errorMessage}
          </Typography>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="submit"
              style={{ marginLeft: 10, marginTop: 20, width: 270 }}
              variant="contained"
              color="primary"
            >
              Add to order!
            </Button>
          </div>
        </form>
      </DialogContent>
    </StyledPaper>
  );
};

export default AddItemDialog;
