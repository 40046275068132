import {camelCaseToSplitWords, FIELD_TYPE, ValidationRule} from "../ValidationRule";
import CreditReferenceValidator from "./CreditReferenceValidator";

class IsValidCreditReference implements ValidationRule {
    type = FIELD_TYPE;

    validator = new CreditReferenceValidator();

    isValid(field: any): boolean {
        const validationErrors = this.validator.validateAll(field);

        return Object.keys(validationErrors).length === 0;
    }

    getErrorMessage(fieldName: string): string {
        const name = camelCaseToSplitWords(fieldName);
        return `${name} must be a valid. Please supply all fields.`;
    }
}

export default IsValidCreditReference;