import {Button, Typography} from "@mui/material";
import {StylesContext} from "../../../providers/StylesProvider";
import React, {useContext} from "react";
import CreditApplicationCard from "./CreditApplicationCard";
import {NewUserData} from "./Register";
import {CreditApplicationData, DEFAULT_CREDIT_APPLICATION} from "./CreditApplicationData";

export type OptionalCreditAppProps = {
  progressStep: () => void;
  regressStep: () => void;
  userData: NewUserData;
  directDataUpdate: (fieldName: string, value: any) => void;
};

const OptionalCreditApp = (props: OptionalCreditAppProps) => {
    //context
    const {isDesktop} = useContext(StylesContext);

    //local variables
    const [fillOutApplication, setFillOutApplication] = React.useState(props.userData.optionalCreditApp !== undefined);
    const [creditAppValid, setCreditAppValid] = React.useState(false);

    const setCreditApplicationData = (newData: CreditApplicationData) => {
        props.directDataUpdate("optionalCreditApp", newData);
    }

    const cancelCreditApp = () => {
        setFillOutApplication(false);

        props.userData.optionalCreditApp = undefined; //clear app
        props.progressStep();
    }

    const cancelAndBackCreditApp = () => {
        setFillOutApplication(false);

        props.userData.optionalCreditApp = undefined; //clear app
        props.regressStep();
    }

    const beginCreditApp = () => {
        if(!props.userData.optionalCreditApp) {
            const newApp = {...DEFAULT_CREDIT_APPLICATION,
                companyName: props.userData.businessLegalName.length > 0 ? props.userData.businessLegalName : props.userData.companyName,
                userFirstName: props.userData.firstName,
                userLastName: props.userData.lastName,
                userStreetAddress: props.userData.billingAddress,
                userTitle: props.userData.role,
            };

            setCreditApplicationData(newApp);
        }

        setFillOutApplication(true);
    }

    const optionToFillOutApplication = () => {
        return <>
            <Typography variant="h6" style={{margin: 10}}>Optional: Would you like to apply for special terms now?</Typography>
            <div style={{marginTop: 20}}>
                <Button variant="contained" color="primary" onClick={() => props.regressStep()}>Back</Button>
                <Button style={{marginLeft: 10}} variant="contained" color="primary" onClick={beginCreditApp}>Yes</Button>
                <Button style={{marginLeft: 10}} variant="contained" color="primary" onClick={() => props.progressStep()}>Skip</Button>
            </div>
        </>
    }

    const displayCreditApp = () => {
        return <>
            <div style={{width: isDesktop ? "800px" : "100%"}}>
                <CreditApplicationCard creditApplicationData={props.userData.optionalCreditApp ?? DEFAULT_CREDIT_APPLICATION}
                                       setCreditApplicationData={setCreditApplicationData}
                                       cancelFunction={cancelCreditApp}
                                       setIsValid={setCreditAppValid}
                />
                <div style={{marginTop: 20, marginBottom: 20, display: "flex", justifyContent: "center" }}>
                    <Button variant="contained" color="primary" onClick={() => cancelAndBackCreditApp()}>Cancel And Back</Button>

                    <Button style={{marginLeft: 10}} variant="contained" color="primary" disabled={!creditAppValid} onClick={() => props.progressStep()}>Next</Button>
                </div>
            </div>
        </>
    }

    if(fillOutApplication) {
        return displayCreditApp();
    } else {
        return optionToFillOutApplication();
    }

};

export default OptionalCreditApp;