import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Typography,
  styled,
} from "@mui/material";
import belgardLogo from "../../media/graphics/Belgard_BAD_logo.png";

const EntityCard = ({
  entityName,
  streetAddress,
  phoneNumber,
  addressUrl,
  includeBelgardImage,
}: {
  entityName: string;
  streetAddress: string;
  phoneNumber: string;
  addressUrl: string;
  includeBelgardImage: boolean;
}) => {
  const PREFIX = "EntityCard";

  const classes = {
    level2Child: `${PREFIX}-level2Child`,
  };

  const Root = styled("div")({
    [`& .${classes.level2Child}`]: {
      padding: "15px",
      margin: "15px",
      display: "flex",
      justifyContent: "flex-start",
      alignContent: "center",
      alignItems: "center",
      flexDirection: "column",
      minWidth: "100px",
      minHeight: includeBelgardImage ? "280px" : "100px",
    },
  });

  const isBelgardDealer = (name: string) => {
    return name === "Kaysville Branch" || name === "Pleasant Grove Branch";
  };

  const belgardImage = () => {
    return (
      <img
        style={{ margin: 10 }}
        height="100"
        width="100"
        src={belgardLogo}
        alt="Authorized Belgard Dealer Logo"
      />
    );
  };

  return (
    <Root>
      <Card key={entityName} className={classes.level2Child}>
        <CardHeader title={entityName} />
        <Typography>{streetAddress}</Typography>
        <Typography>{phoneNumber}</Typography>
        <CardActions>
          <Button onClick={() => window.open(addressUrl, "_blank")}>
            Get Directions
          </Button>
        </CardActions>
        {includeBelgardImage && isBelgardDealer(entityName)
          ? belgardImage()
          : ""}
      </Card>
    </Root>
  );
};

export default EntityCard;
