import {CardHeader, FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import React, {useContext} from "react";
import {PAY_ACCOUNT_BALANCE, PAY_OTHER_AMOUNT, PAY_SPECIFIC_INVOICES} from "./PaymentCardConstants";
import {formatter} from "../../../constants/Constants";
import {StylesContext} from "../../../providers/StylesProvider";


export type PaymentOptionRadioButtonsProps = {
    paymentOption: string;
    setPaymentOption: React.Dispatch<React.SetStateAction<string>>
    accountTotalDue: number;
}

const PaymentOptionRadioButtons: React.FC<PaymentOptionRadioButtonsProps> = (props: PaymentOptionRadioButtonsProps) => {
    //context
    const { isDesktop } = useContext(StylesContext);

    //local
    const formattedBalance = formatter.format(props.accountTotalDue);

    const handlePaymentOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setPaymentOption(event.target.value);
    };

    const renderDesktop = () => {
        return <>
            <FormControl style={{width: "58%"}} component="fieldset">
                <CardHeader title="Make a payment" />
                <RadioGroup value={props.paymentOption} onChange={handlePaymentOptionChange}>
                    <FormControlLabel  value={PAY_ACCOUNT_BALANCE} control={<Radio />} label={`Pay Account Balance: ${formattedBalance}`} />
                    <FormControlLabel value={PAY_SPECIFIC_INVOICES} control={<Radio />} label="Pay Specific Invoices Or Apply Credit Memo" />
                    <FormControlLabel value={PAY_OTHER_AMOUNT} control={<Radio />} label="Pay Other Amount" />
                </RadioGroup>
            </FormControl>
        </>
    }

    const renderMobile = () => {
        return <>
            <CardHeader title="Make a payment" />
            <FormControl style={{width: "100%", marginLeft: 20, marginBottom: 20 }} component="fieldset">

                <RadioGroup value={props.paymentOption} onChange={handlePaymentOptionChange}>
                    <FormControlLabel  value={PAY_ACCOUNT_BALANCE} control={<Radio />} label={`Pay Account Balance: ${formattedBalance}`} />
                    <FormControlLabel value={PAY_SPECIFIC_INVOICES} control={<Radio />} label="Pay Specific Invoices Or Apply Credit Memo" />
                    <FormControlLabel value={PAY_OTHER_AMOUNT} control={<Radio />} label="Pay Other Amount" />
                </RadioGroup>
            </FormControl>
        </>
    }


    return <>
        { isDesktop ? renderDesktop() : renderMobile() }
    </>
}

export default PaymentOptionRadioButtons;