import { datadogLogs } from "@datadog/browser-logs";
import { TextField, Button, Snackbar, Alert } from "@mui/material";
import { styled } from "@mui/material/styles";
import Axios from "axios";
import { useState } from "react";
import XFormData from "../../Utilities/XFormData";

const PREFIX = "ForgottenPassword";

const classes = {
  formControl: `${PREFIX}-formControl`,
  input: `${PREFIX}-input`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  [`& .${classes.input}`]: {
    margin: 10,
  },
}));

const ForgottenPassword = () => {
  const [emailAddress, setEmail] = useState("");
  const [emailError, setEmailError] = useState<String>("");
  const [toastOpen, setToastOpen] = useState(false);

  const generateLink = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-store",
      },
    };

    try {
      datadogLogs.logger.info(
        `Reset Password request done by ${emailAddress}`,
        {
          email: emailAddress,
        }
      );

      const data = new XFormData().format({
        email: emailAddress,
      });

      await Axios.post(`/api/user/resetpassword`, data, config);
      setToastOpen(true);
    } catch (error: any) {
      datadogLogs.logger.error(`Reset Password Failed for ${emailAddress}`, {
        errorData: error?.response?.data,
      });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validForm()) {
      generateLink();
    }
  };

  const validForm = () => {
    if (emailError.length === 0) {
      return true;
    }
    return false;
  };

  const validateInput = (inputName: String, textToValidate: String) => {
    switch (inputName) {
      case "Email":
        setEmailError(
          !textToValidate.includes("@")
            ? "Please input a valid email address"
            : ""
        );
        break;

      default:
        break;
    }
  };

  const handleToastClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  const renderToast = () => {
    return (
      <Snackbar open={toastOpen}>
        <Alert
          onClose={handleToastClose}
          severity={"success"}
          style={{ width: "100%" }}
        >
          {`A password reset link has been sent to ${emailAddress}`}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <Root>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 200,
        }}
      >
        <h1>Reset Password Request</h1>
        <p>
          Enter your email below and we'll send you a password reset link if
          you're registered on TreeSource.com.
        </p>
        <form
          onSubmit={handleSubmit}
          className={classes.formControl}
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: "300px",
          }}
        >
          <TextField
            className={classes.input}
            required
            variant="outlined"
            name="email"
            label="Email"
            error={emailError ? true : false}
            helperText={emailError ? emailError : ""}
            onChange={(e) => {
              setEmail(e.target.value);
              validateInput(e.target.name, e.target.value);
            }}
            value={emailAddress}
          />
          <Button
            className={classes.input}
            type="submit"
            variant="contained"
            color="primary"
          >
            {" "}
            Request link to reset password
          </Button>
        </form>
      </div>
      {renderToast()}
    </Root>
  );
};

export default ForgottenPassword;
