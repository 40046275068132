import {Container, Typography} from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { AboutUsData, PhotoCard } from '../guiComponents/PhotoCard'
import TreeSourceLoad from '../Loaders/TreeSourceLoad'

const AboutUs = () => {

  const [aboutUsData, setAboutUsData]  = useState<AboutUsData[]>()
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    getAboutUsData()

  },[])


const getAboutUsData = async () => {

  const config = {
    headers: {
      'Accept' : 'application/json',
      'Content-Type': 'application/json',
      "Cache-Control": "no-store"
    }
  }
  try {
    setLoading(true)
    let res = await axios.get("/api/aboutus/all", config)
    let aboutUsData: AboutUsData[] = res.data
    let aboutUsDataWithId = aboutUsData.map((x: AboutUsData, index: number)=> {
      return {...x, id: x.email + index.toString()}
    })
    setAboutUsData(aboutUsDataWithId)
    setLoading(false)
  } catch (error) {
    
    setLoading(false)
  } 
 
}

const unique = (value: string, index:number, self: string[]) => {
  return self.indexOf(value) === index
}

let allDepartments = aboutUsData?.map(x=>x.department)
let uniqueDepartments = allDepartments?.filter(unique).sort().reverse()

if(loading){
  return(
    <TreeSourceLoad message="Loading..."/>
  )
}

return(
  <Container>
  <div style={{paddingTop:125, paddingBottom: 125}}>
    <div >
      {uniqueDepartments?.map((dept, index) => {
      return(
      <div key={index}>
        <Typography style={{textAlign: "center"}} gutterBottom mt={4} variant="h2">{dept}</Typography>
        <div style={{ display:"flex", flexDirection:"row", flexWrap: "wrap", justifyContent: 'space-evenly'}}>
        {aboutUsData?.filter((x)=> x.department === dept).map((x, index: number) => <PhotoCard key={x.email+index.toString()} {...x}/>)}
        </div>
      </div>
      )
      })}
    </div>
  </div>
  </Container>
)
}

export default AboutUs