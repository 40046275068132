import { Typography} from "@mui/material"
import { styled } from "@mui/styles"
import {ReactNode} from "react";

export const BoldTypography = styled(Typography)(
    {
      fontWeight:"600",
      paddingRight: 3
    }
  )
export const KeyValue = (key: string, value: string, followingTag: ReactNode = null) => {

    if(value=== undefined || value===""){
        return null
    }

    return(
        <div style={{display:"flex", flexDirection: "row"}}>
            <BoldTypography>{key}</BoldTypography>
            <Typography>{value}</Typography>
            {followingTag}
        </div>
    )
}