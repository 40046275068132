import React, { useContext, useEffect } from "react"
import { useState } from "react"
import Axios from "axios"
import { Customer } from "../types/UserType"
import { AuthContext } from "./AuthProvider"
import { CUSTOMER_NAME } from "../constants/Constants"
import { BranchType } from "../types/BranchType"

export type BranchPropertiesType = {
  branches: BranchType[]
}

export type BranchFunctionsType = {
  loadBranches: (customerName?: string, entityName?: string) => Promise<void>
}

export const BranchContext = React.createContext<Partial<BranchPropertiesType & BranchFunctionsType>>({})

export const BranchProvider = (props: any) => {
  const { setLoggedOutLocation, loggedOutLocation, user, isTokenExpired } = useContext(AuthContext)
  const [branches, setBranches] = useState<BranchType[]>([])

  const isExpired = () => {
    if (isTokenExpired) {
      return isTokenExpired()
    }
    return true
  }

  useEffect(() => {
    let customerName = localStorage.getItem("token") == null || isExpired() || user?.customer?.entityName === undefined ? "" : localStorage.getItem(CUSTOMER_NAME)
    if(user){
      loadBranches(customerName as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])



  const loadBranches = async (customerName?: string) => {
    
    let c = customerName || ""

    const config = {
      headers: {
        'authorization': localStorage.getItem("token"),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }

    try {
      let res = await Axios.get(`/api/branches?customerName=${encodeURIComponent(c)} `, config)

      let rawBranches: BranchType[] = res.data
      let branchesNew = rawBranches.filter(x=> x?.entityName !== "Central")
      setBranches(branchesNew as BranchType[])
      let branchObject: Customer[] = res.data
      let branches = branchObject.map(x => x?.entityName)
      if (setLoggedOutLocation) {
        if (!branches.includes(loggedOutLocation as string)) {
          setLoggedOutLocation(res.data[0].entityName)
        }
      }
    } catch (error) {
      //do nothing
    }
  }

  const branch: BranchPropertiesType & BranchFunctionsType = {
    branches,
    loadBranches
  }

  return (
    <BranchContext.Provider value={branch}>
      {props.children}
    </BranchContext.Provider>
  )
}
