import {CreditReference} from "./CreditApplicationData";
import {CardHeader, Checkbox, FormControlLabel, TextField} from "@mui/material";
import React, {ChangeEvent, useContext} from "react";
import {AddressType} from "../../../types/CardDataType";
import AddressInputFields from "../../guiComponents/payments/AddressInputFields";
import {PaddedCard} from "../../LoggedOut/PaddedCard";
import {Clear} from "@mui/icons-material";
import {StylesContext} from "../../../providers/StylesProvider";
import CreditReferenceValidator from "./validators/creditApplication/CreditReferenceValidator";

export type CreditReferenceCardProps = {
    reference: CreditReference;
    index: number;
    updateReference: (reference: CreditReference, index: number) => void;
    removeReference: (index: number) => void;
    addReference: () => void;
}

export const CreditReferenceCard = (props: CreditReferenceCardProps) => {
    //context
    const {isDesktop} = useContext(StylesContext);

    const [validator] = React.useState(new CreditReferenceValidator());

    const getErrorMessage = (fieldName: string): string => {
        return validator.validateFieldIfTouched(props.reference, fieldName);
    }

    const isInvalid = (fieldName: string): boolean => {
        return getErrorMessage(fieldName) !== "";
    }

    // Function to handle input change
    const updateData = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        props.updateReference({...props.reference, [name]: value}, props.index);
    };

    const updateAddress = (address: AddressType) => {
        props.updateReference({...props.reference, address: address}, props.index);
    }

    const renderDesktop = () => {
        return <PaddedCard>
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <CardHeader title={"Trade Reference #" + (props.index + 1)}/>
                <div style={{display:"flex", justifyContent: "center", alignItems: "center"}}>
                    <FormControlLabel
                        style={{ marginTop: 5}}
                        control={<Checkbox checked={props.reference.isPastDue}
                                           onChange={(event) => {
                                               props.updateReference({...props.reference, isPastDue: event.target.checked}, props.index);
                                           }}/>}
                        label="I am past due with this reference."
                    />
                    <div style={{display: "flex", alignItems: "center", marginRight: 15, marginTop: 5 }} onClick={() => props.removeReference(props.index)}>
                        <Clear/>
                    </div>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent:"space-between", marginLeft: 15, marginRight: 15}}>
                <TextField
                    style={{width: "31%"}}
                    required
                    variant="standard"
                    name="companyName"
                    label="Company Name"
                    error={isInvalid("companyName")}
                    helperText={getErrorMessage("companyName")}
                    onChange={updateData}
                    value={props.reference.companyName}
                />
                <TextField
                    style={{width: "31%"}}
                    required
                    variant="standard"
                    name="contactName"
                    label="Contact Name"
                    error={isInvalid("contactName")}
                    helperText={getErrorMessage("contactName")}
                    onChange={updateData}
                    value={props.reference.contactName}
                />
                <TextField
                    style={{width: "31%"}}
                    required
                    variant="standard"
                    name="phoneNumber"
                    label="Phone Number"
                    error={isInvalid("phoneNumber")}
                    helperText={getErrorMessage("phoneNumber")}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value;
                        const isNumber = !isNaN(Number(value));

                        if (isNumber) {
                            updateData(event);
                        }
                    }}
                    value={props.reference.phoneNumber}
                />
            </div>
            <div style={{ marginLeft: 15, marginRight: 15, marginBottom: 20}}>
                <AddressInputFields address={props.reference.address}
                                    setAddress={updateAddress}
                                    label={"Address"}
                                    outlined={false}
                                    required={true}
                />
            </div>
        </PaddedCard>
    }

    const renderMobile = () => {
        return <PaddedCard style={{margin: 0, marginTop: 15}}>
            <CardHeader title={"Trade Reference #" + (props.index + 1)}/>
            <FormControlLabel
                style={{ marginLeft: 5}}
                control={<Checkbox checked={props.reference.isPastDue}
                                   onChange={(event) => {
                                       props.updateReference({...props.reference, isPastDue: event.target.checked}, props.index);
                                   }}/>}
                label="I am past due with this reference."
            />
            <div style={{display: "flex", flexDirection: "column", justifyContent:"space-between", marginLeft: 15, marginRight: 15}}>
                <TextField
                    fullWidth
                    required
                    margin={"dense"}
                    variant="standard"
                    name="companyName"
                    label="Company Name"
                    error={isInvalid("companyName")}
                    helperText={getErrorMessage("companyName")}
                    onChange={updateData}
                    value={props.reference.companyName}
                />
                <TextField
                    fullWidth
                    required
                    margin={"dense"}
                    variant="standard"
                    name="contactName"
                    label="Contact Name"
                    error={isInvalid("contactName")}
                    helperText={getErrorMessage("contactName")}
                    onChange={updateData}
                    value={props.reference.contactName}
                />
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent:"space-between", marginLeft: 15, marginRight: 15}}>
                <TextField
                    fullWidth
                    required
                    margin={"dense"}
                    variant="standard"
                    name="phoneNumber"
                    label="Phone Number"
                    error={isInvalid("phoneNumber")}
                    helperText={getErrorMessage("phoneNumber")}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value;
                        const isNumber = !isNaN(Number(value));

                        if (isNumber) {
                            updateData(event);
                        }
                    }}
                    value={props.reference.phoneNumber}
                />
            </div>
            <div style={{ marginLeft: 15, marginRight: 15, marginBottom: 20}}>
                <AddressInputFields address={props.reference.address}
                                    setAddress={updateAddress}
                                    label={"Address"}
                                    outlined={false}
                                    required={true}
                />
            </div>
        </PaddedCard>
    }

    if(isDesktop) {
        return renderDesktop();
    } else {
        return renderMobile();
    }

}