import {Button, Typography} from "@mui/material";
import {StylesContext} from "../../../providers/StylesProvider";
import {useContext, useState} from "react";
import {NewUserData} from "./Register";

export type NewOrExistingCustomerProps = {
  handleNewUserIdentification: (newCustomer: boolean, homeowner: boolean) => void;
  userData: NewUserData;
};

const NewOrExistingCustomer = (props: NewOrExistingCustomerProps) => {
    const {isDesktop} = useContext(StylesContext);
    const { handleNewUserIdentification } = props;

    //grabbing the default off the userData means that we can force the form to reset from the parent
    const [newCustomer, setNewCustomer] = useState<boolean>(props.userData.newCustomer);

    const askForNewOrExisting = () => {
        return <>
            <Typography variant="h5">Have you (or has your company) worked with us before?</Typography>

            {/*I'm going to make an assumption that shouldn't hurt me: if a second person is registering for an account under the same customer, that customer is a business*/}
            <div style={{marginTop: 30, display: "flex", }}>
                <Button style={{marginLeft: isDesktop ? 50 : 0}} variant="contained" color="primary" onClick={() => setNewCustomer(true)}>New Customer</Button>
                <Button style={{marginLeft: isDesktop ? 50 : 10}} variant="contained" color="primary" onClick={() => handleNewUserIdentification(false, false)}>Existing Customer</Button>
            </div>
        </>
    }

    const askForHomeowner = () => {
        return <>
            <Typography variant="h5">Are you a homeowner or business?</Typography>
            <div style={{marginTop: 30, display: "flex",}}>
                <Button style={{marginLeft: isDesktop ? 10 : 0}} variant="contained" color="primary"
                        onClick={() => handleNewUserIdentification(true, true)}>Homeowner</Button>
                <Button style={{marginLeft: isDesktop ? 30 : 10}} variant="contained" color="primary"
                        onClick={() => handleNewUserIdentification(true, false)}>Business</Button>
            </div>
        </>
    }

    return <>
        {!newCustomer && askForNewOrExisting()}

        {newCustomer && askForHomeowner()}
    </>

};

export default NewOrExistingCustomer;