import {OBJECT_TYPE, ValidationRule} from "../ValidationRule";
import {CreditApplicationData} from "../../CreditApplicationData";

class IfMeetsOrExceedsRule implements ValidationRule {
    type = OBJECT_TYPE;

    fieldUnderTest: string;
    minValue: number;
    validationRule: ValidationRule;
    nextFieldName: string;

    constructor(fieldUnderTest: string, minValue: number, ValidationRule: ValidationRule, fieldName: string) {
        this.fieldUnderTest = fieldUnderTest;
        this.minValue = minValue;
        this.validationRule = ValidationRule;
        this.nextFieldName = fieldName;
    }

    isValid(application: CreditApplicationData)  {
        if(application[this.fieldUnderTest] >= this.minValue) {
            // pass the responsibility for validation to the next rule
            return this.validationRule.isValid(application[this.nextFieldName]);
        } else {
            return true;
        }
    }

    getErrorMessage(fieldName: string) {
        //take advantage of the fact this is only called when isValid returns false
        //to assume we are a business and need to delegate
        return this.validationRule.getErrorMessage(fieldName);
    }
}

export default IfMeetsOrExceedsRule;