import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {Alert, Button, CardContent, CardHeader, IconButton, Snackbar} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {ChangeEvent, useEffect, useState} from "react";
import { PaddedCard } from '../LoggedOut/PaddedCard';
import {Photo} from "../LoggedOut/PhotoGallery";

type RowData = {
    id: number;
    preview: File | null;
    note: string;
    rank: number;
    changed: boolean;
    deleted: boolean;
}

type ItemBasePhotoCardProps = {
    hide: boolean;
    setHide: React.Dispatch<React.SetStateAction<boolean>>;
    ALU: string;
    photos: Photo[];
    callRefresh: () => void;
}

const ItemBasePhotoCard = (props: ItemBasePhotoCardProps) => {
    const [rows, setRows] = useState<RowData[]>([]);
    const [isSaving, setIsSaving] = useState(false)
    const [errorToastOpen, setErrorToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const { setHide, callRefresh } = props; //destructed props because we're using them in useEffect

    useEffect(() => {
        if(props.hide) {
            return;
        }

        const corePhotos = props.photos.filter(p => p.core);
        Promise.all(corePhotos.map(photo => urlToFile(photo.url, photo.shortItemCode, "image/jpeg")))
            .then(files => {
                const newRows = files.map((file, index) => ({
                    id: corePhotos[index].id,
                    preview: file,
                    note: corePhotos[index].note,
                    rank: corePhotos[index].rank > 0 ? corePhotos[index].rank : index + 1,
                    changed: corePhotos[index].rank <= 0, //if we had to assign a rank, we'll need to update the backend on the next save
                    deleted: false
                }));
                setRows(newRows);
            });
    }, [props.photos, props.hide]);

    useEffect(() => {
        //if we're done saving and there are no error messages, hide the card and refresh the data
        if(!isSaving && !toastMessage) {
            setHide(true);

            if(callRefresh) {
                callRefresh();
            }
        }
    }, [toastMessage, isSaving, setHide, callRefresh]);

    async function urlToFile(url: string, filename: string, mimeType: string): Promise<File> {
        const response = await fetch(url);
        const data = await response.blob();
        return new File([data], filename, {type: mimeType});
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>, id: number) => {
        // @ts-ignore
        const file = event.target.files[0];

        setRows(rows.map(row => row.id === id ? { ...row, preview: file, changed: true } : row));
    };

    const handleAddRow = () => {
        setRows([...rows, { id: -rows.length, preview: null, note: '', rank: rows.length + 1, changed: true, deleted: false}]);
    };

    const handleSave = async () => {
        setIsSaving(true)
        setToastMessage('') //clear any previous error messages

        await Promise.all(rows.filter(row => row.changed).map(row => {
            if (row.preview && !row.deleted) {
                return sendPutRequest(props.ALU, row.id, row.note, row.rank, row.preview);
            } else if (row.id > 0 && row.deleted) {
                sendDeleteRequest(row.id);
            }

            return Promise.resolve();
        }));

        setIsSaving(false);
    }

    const sendPutRequest = async (alu: string, id: number, note: string, rank: number, imageBlob: Blob | null) => {
        const formData = new FormData();
        if (imageBlob) {
            formData.append('file', imageBlob, 'photo.jpg');
            formData.append('id', "" + id);
            formData.append('note', note);
            formData.append('core', 'true');
            formData.append('itemSpecific', 'false');
            formData.append('updateItemBase', 'true');
            formData.append('rank', "" + rank);

            const vendor = encodeURIComponent("Central")
            const response = await fetch(`/api/products/${vendor}/${alu}/photos`, {
                method: 'PUT',
                body: formData,
                headers: {
                    Authorization: localStorage.getItem("token") ?? undefined,
                } as HeadersInit
            });

            if(response.status === 400) {
                const data = await response.json();

                setErrorToastOpen(true);
                setToastMessage(data.message);
            } else if (!response.ok) {
                setErrorToastOpen(true);
                setToastMessage('Failed to send POST request');
            }
        }
    };

    const sendDeleteRequest = async (id: number) => {
        return fetch(`/api/products/photos/${id}`, {
            method: 'DELETE',
            headers: {
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit
        });
    }

    const handleClose = () => {
        setErrorToastOpen(false);
    }

    const renderErrorToast = () => {
        return (
            <Snackbar autoHideDuration={5000} open={errorToastOpen} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="error"
                    style={{ width: "50%" }}
                >
                    {toastMessage}
                </Alert>
            </Snackbar>
        );
    };

    const columns = [
        {
            field: 'preview',
            headerName: 'Preview',
            width: 200,
            renderCell: (params: any) => params.value && <img src={URL.createObjectURL(params.value)} alt="preview" width="200" height="267" />,
            editable: false,
        },
        {
            field: 'upload',
            headerName: 'Manage',
            width: 110,
            renderCell: (params: any) => (
                <div style={{display:"flex", flexDirection: "column"}}>
                    <label style={{
                        display: 'inline-block',
                        padding: '6px 12px',
                        cursor: 'pointer',
                        backgroundColor: '#3f51b5',
                        color: 'white',
                        borderRadius: '4px'
                    }}>
                        Select File
                        <input type="file" style={{display: 'none'}}
                               onChange={(event) => handleFileChange(event, params.id)}/>
                    </label>
                </div>
            ),
        },
        {
            field: 'note',
            headerName: 'Optional Note',
            flex: 1,
            editable: true,
        },
        {
            field: 'rank',
            headerName: 'Rank',
            width: 50,
            editable: true,
            valueFormatter: (params: any) => params.value ?? "",
            valueParser: (value: any) => parseInt(value),
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 70,
            renderCell: (params: any) => (
                <IconButton onClick={() => setRows(rows.map(row => row.id === params.id ? {...row, deleted: true, changed: true} : row))}>
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];

    if(props.hide) {
        return <></>;
    } else {
        return <div style={{ cursor: isSaving ? 'wait' : 'default' }}>
            <PaddedCard>
                <CardHeader title="Edit Item Base Photos" />
                <CardContent>
                    <div style={{display: "flex", justifyContent: "space-between", marginBottom: 15}}>
                        <Button variant="contained" onClick={handleAddRow}>Add Row</Button>
                        <div>To set the main item base photo, please supply exactly one photo with a rank of 1.</div>
                        <div>
                            <Button variant="contained" color="success" onClick={handleSave}>Save</Button>
                            <Button style={{marginLeft: 10}} variant="contained" color="secondary" onClick={() => {
                                props.setHide(true)
                            }}>Cancel</Button>
                        </div>

                    </div>

                    <DataGridPro
                        style={{height: "380px"}}
                        rows={rows.filter(row => !row.deleted)}
                        rowHeight={280}
                        columns={columns}
                        hideFooter
                        onCellEditCommit={(params: any) => {
                            //file changes go through handleFileChange method. \o/
                            if (params.field === 'note') {
                                setRows(rows.map(row => row.id === params.id ? { ...row, note: params.value, changed: true } : row));
                            } else if (params.field === 'rank') {
                                setRows(rows.map(row => row.id === params.id ? { ...row, rank: params.value, changed: true } : row));
                            }
                        }}
                    />
                </CardContent>

            </PaddedCard>
            {renderErrorToast()}
        </div>
    }

};

export default ItemBasePhotoCard;