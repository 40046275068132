import { OBJECT_TYPE, ValidationRule} from "./ValidationRule";
import {NewUserData} from "../Register";

class IfBusinessRule implements ValidationRule {
    type = OBJECT_TYPE;

    validationRule: ValidationRule;
    fieldName: string;

    constructor(ValidationRule: ValidationRule, fieldName: string) {
        this.validationRule = ValidationRule;
        this.fieldName = fieldName;
    }

    isValid(newUserData: NewUserData)  {
        const isBusiness = !newUserData.isHomeowner;

        if(isBusiness) {
            // pass the responsibility for validation to the next rule
            return this.validationRule.isValid(newUserData[this.fieldName]);
        } else {
            return true;
        }
    }

    getErrorMessage(fieldName: string) {
        //take advantage of the fact this is only called when isValid returns false
        //to assume we are a business and need to delegate
        return this.validationRule.getErrorMessage(fieldName);
    }
}

export default IfBusinessRule;