import {NewUserData} from "./Register";
import ScrollPane from "../../guiComponents/ScrollPane";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import React, {useContext} from "react";
import {StylesContext} from "../../../providers/StylesProvider";

export type RegistrationTermsAndConditionsProps = {
    userData: NewUserData;
    directDataUpdate: (fieldName: string, value: any) => void;
}

const RegistrationTermsAndConditions = (props: RegistrationTermsAndConditionsProps) => {
    const { userData, directDataUpdate } = props;
    const { isDesktop } = useContext(StylesContext);


    return <div style={{display: "flex", flexDirection: "column", width: isDesktop ? "790px" : "100%"}}>
        <ScrollPane>
            <Typography variant={"h6"}>TERMS AND CONDITIONS</Typography>
            <Typography className="body-paragraph-header" variant={"subtitle1"}>Prices</Typography>
            <Typography variant={"body2"}>
                Quoted prices are for the trade only and are subject to change without notice. Prices do not include
                federal, state, or local taxes, which are a
                Buyer’s sole responsibility. All prices are quoted in U.S. dollars, F.O.B. Rupert Idaho.
            </Typography>
            <Typography className="body-paragraph-header" variant={"subtitle1"}>Initial Orders</Typography>
            <Typography variant={"body2"}>
                Unless credit has been approved, all initial orders are to be paid in full before shipment can take
                place.
            </Typography>
            <Typography className="body-paragraph-header" variant={"subtitle1"}>Subsequent Orders</Typography>
            <Typography variant={"body2"}>
                All subsequent orders are cash unless arrangements for credit have been established. To establish
                credit, a completed and signed credit
                application must be forwarded to Treesource LLC. A submitted credit application does not constitute
                establishment of credit.
            </Typography>
            <Typography className="body-paragraph-header" variant={"subtitle1"}>Payment Terms</Typography>
            <Typography variant={"body2"}>
                All invoices are due per Treesource ’s Credit Terms and Conditions listed herein. Payment on initial
                orders must be executed as listed under
                “Initial Orders” above. Accounts not paid in full within terms involved with each transaction will be
                considered past due unless
                Past Due accounts will
                accrue finance charges at the rate of (1.5%) per month (18%) per annum from the Due Date. Past Due
                accounts may have credit privileges
                temporarily/permanently restricted to a cash-only basis until satisfactory evidence warrants otherwise.
                Accounts past due for more than 90 days
                may be turned over to a collection agency at the discretion of the seller with all collection costs and
                attorney fees for collection of any amount
                due to be included in total amount due.
            </Typography>
            <Typography className="body-paragraph-header" variant={"subtitle1"}>Fees</Typography>
            <Typography variant={"body2"}>
                Accounts will be charged $35 per NSF check. An NSF check may result in a cash-basis credit status. All
                credit card payments will incur a fee of 2.5% on the
                overall amount if paid after 20 days from the date of invoice. If paid by credit card within 20 days of
                invoice, no fee will be incurred.
            </Typography>
            <Typography className="body-paragraph-header" variant={"subtitle1"}>Guarantee</Typography>
            <Typography variant={"body2"}>
                Seller gives no other warranty, expressed or implied. Treesource reserves the right to cancel all or a
                portion of stock on order should there be
                injury from insect and disease, error in counts, weather conditions or other causes beyond our control.
            </Typography>
            <Typography className="body-paragraph-header" variant={"subtitle1"}>Shipping/Claims</Typography>
            <Typography variant={"body2"}>
                Upon receipt of goods by client, any product shortages should be reported immediately (within 24 hours)
                to your Treesource account
                representative or shipping department.
            </Typography>
            <Typography className="body-paragraph" variant={"body2"}>
                All damages resulting from the neglect or mishandling by common carrier shall be addressed with said
                common carrier. In this case, the invoice
                shall be paid in full within agreed terms of sale. Resulting shortages due to damage by Treesource
                delivery may be remedied according to
                methods listed under “shortages” above. Clients picking up their own plant materials are required to do
                their own verification according to the
                most recent, signed acknowledgment with respect to the order. Any damages or shortages subsequent to
                client picking up materials will be
                considered client’s own loss.
                All shipments are F.O.B TCS nursery. All shipments require written confirmation before Treesource will
                release the shipment.
            </Typography>
            <Typography className="body-paragraph-header" variant={"subtitle1"}>Returns</Typography>
            <Typography variant={"body2"}>
                Once you have signed the packing slip the material is yours. We do not accept returns under any
                circumstances.
            </Typography>
            <Typography className="body-paragraph-header" variant={"subtitle1"}>Waiver/Duty to Inspect</Typography>
            <Typography variant={"body2"}>
                Client agrees to examine immediately upon receipt, each of Treesource ’s statements and to advise
                Treesource of any disputed transactions or
                statements within 10 days of receipt, together with a written statement specifying the reasons for such
                dispute. Failure to notify Treesource of
                any dispute with respect to defective goods or billing shall constitute a waiver of all such disputes.
            </Typography>
        </ScrollPane>
        <FormControlLabel
            style={{marginTop: 10, marginLeft: 0}}
            control={<Checkbox checked={userData.hasAcceptedTerms}
                               onChange={(event) => directDataUpdate("hasAcceptedTerms", event.target.checked)}/>}
            label="I agree to the terms and conditions"
        />
    </div>
}

export default RegistrationTermsAndConditions;