import {NO_PRICE_DISPLAY} from "../constants/Constants";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export function getDisplayPrice(price: number | null): string {
    if (price)
        return formatter.format(price);
    else
        return NO_PRICE_DISPLAY;
}

export function getDisplayForMaxPrice(price1: number | null, price2: number | null): string {
    if (price1 && price2) {
        if (price1 > price2)
            return formatter.format(price1);
        else
            return formatter.format(price2);
    } else {
        return NO_PRICE_DISPLAY;
    }
}