import { Container } from "@mui/material"
import { useContext } from "react"
import { StylesContext } from "../../providers/StylesProvider"

type Props = {
    errorHeader: string,
    errorMessage: string
}

export const Error = (props: Props) => {

    const {navBarHeight} = useContext(StylesContext)

    return (
        <Container>
            <div style={{ paddingTop: navBarHeight}}>
                <h1>{props.errorHeader}</h1>
                <br />
                <p>{props.errorMessage}</p>
            </div>
        </Container>
    )
}