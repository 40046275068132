import {camelCaseToSplitWords, FIELD_TYPE, ValidationRule} from "./ValidationRule";

class MustBeTrueRule implements ValidationRule {
    type = FIELD_TYPE;

    isValid(field: any)  {
        return field === true;
    }

    getErrorMessage(fieldName: string) {
        const name = camelCaseToSplitWords(fieldName);
        return `${name} must be true`;
    }
}

export default MustBeTrueRule;