import BaseValidator from "../BaseValidator";
import NotEmptyRule from "../NotEmptyRule";

import {JobLineApplicationData} from "../../JobLineApplicationData";
import IsValidAddress from "../IsValidAddress";
import MustBeTrueRule from "../MustBeTrueRule";

class JobLineValidator extends BaseValidator<JobLineApplicationData> {
    constructor() {
        super();
        this.dataValidations = {
            jobName: [new NotEmptyRule()],
            jobPhysicalAddress: [new IsValidAddress()],
            jobEstimatedMaterialCost: [new NotEmptyRule()],
            jobBidNumber: [new NotEmptyRule()],

            propertyOwnerName: [new NotEmptyRule()],
            propertyOwnerPhoneNumber: [new NotEmptyRule()],
            propertyOwnerPhysicalAddress: [new IsValidAddress()],

            generalContractorName: [new NotEmptyRule()],
            generalContractorPhoneNumber: [new NotEmptyRule()],
            generalContractorPhysicalAddress: [new IsValidAddress()],

            digitallySigned: [new MustBeTrueRule()],
        };
    }
}

export default JobLineValidator;