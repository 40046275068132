import React, { useState } from 'react';

// Create the context
export const GlobalContext = React.createContext<Partial<GlobalPropertiesType>>({});

export type GlobalPropertiesType = {
    lastProductSearch: string ;
    setLastProductSearch: React.Dispatch<React.SetStateAction<string>>;
};

// Create the provider component
export const GlobalProvider = (props: any) => {
    const [lastProductSearch, setLastProductSearch] = useState("");

    const container: GlobalPropertiesType = {
        lastProductSearch,
        setLastProductSearch
    }

    return (
        <GlobalContext.Provider value={container}>
            {props.children}
        </GlobalContext.Provider>
    );
};