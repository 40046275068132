import Container from "@mui/material/Container/Container";
import React from "react";
import {useMediaQuery} from "@mui/material";

export const LoyaltyProgram = () => {
    const isFullScreen = useMediaQuery("(min-width:716px");
    return <Container style={{paddingTop: isFullScreen ? 85 : 65}}>
        <div className={"flex-column"}>
            <img alt="flyer explaining the customer loyalty program" style={{width: "100%"}}
                 src="https://res.cloudinary.com/treesource-systems/image/upload/v1722618408/website_assets/2024-LoyaltyProgram-Page1.png"/>
            <img alt="flyer with terms and conditions for loyalty program" style={{width: "100%"}}
                 src="https://res.cloudinary.com/treesource-systems/image/upload/v1722618399/website_assets/2024-LoyaltyProgram-Page2.png"/>
            <img alt="flyer with terms and conditions for loyalty program" style={{width: "100%"}}
                 src="https://res.cloudinary.com/treesource-systems/image/upload/v1722618411/website_assets/2024-LoyaltyProgram-Page3.png"/>
        </div>

    </Container>;
}