import { Typography } from "@mui/material"

const LinkSent = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 200 }}>
            <Typography variant ="h5" style={{padding: "20px"}}>Please confirm your email before registration is complete</Typography>
            <Typography variant="subtitle1"> Thank you for registering! In order to complete
                your registration, you'll need to verify your email address by
                clicking on the link that we just sent to your email.
            </Typography>
        </div>
    )
}

export default LinkSent