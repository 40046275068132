import {PaddedCard} from "../LoggedOut/PaddedCard";
import {CardContent, CardHeader, Typography} from "@mui/material";
import React from "react";

export type JobLineInfoBoxProps = {

}

const JobLineInfoBox = (props: JobLineInfoBoxProps) => {
    return <PaddedCard>
            <CardHeader title={"What is a job line?"}/>
            <CardContent>
                <Typography variant="body1">
                    A job line is a credit line that allows you to purchase materials on credit.
                    It is secured by a lien on the property where the materials are being used rather than against your
                    business or personal assets.
                </Typography>
            </CardContent>
        </PaddedCard>
}

export default JobLineInfoBox;