import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { AuthContext } from "../../providers/AuthProvider"
import TreeSourceLoad from "../Loaders/TreeSourceLoad"


const VerifyEmailAddress = () =>{

    useEffect(()=>{
    verifyUserEmail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const {setUserGuid, setUser} = useContext(AuthContext)
  const [error, setError] = useState(false)
  const history = useHistory()

  const verifyUserEmail = async () =>{
    let urlString = window.location.href
		let guidString = urlString.split("?")[1]
		let urlParams = new URLSearchParams(guidString);
		let guid = urlParams.get('GUID')
        if(setUserGuid){setUserGuid(guid)}

        const config = {
            headers: {
              'Accept' : 'application/json',
              'Content-Type': 'application/json',
              "Cache-Control": "no-store"
            }
          }


        try {
            let res = await axios.get(`/api/user/getUserFromGuid?GUID=${guid}`, config)
            if(setUser){setUser(res.data)}
            history.push('/setpassword')
            setError(false)
        } catch (error) {
            setError(true)
        }

  }
  if(error){
    return(
      <h6>Error verifying account. Please try again later. </h6>
    )
  }

  return(
      <>
        <TreeSourceLoad message="Verifying User"/>
      </>
  )

}

export default VerifyEmailAddress