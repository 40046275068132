import { Tooltip, Typography} from "@mui/material";
import React from "react";

export type BeachCounterProps = {
    points: number;
}

const BeachCounter: React.FC<BeachCounterProps> = (props: BeachCounterProps) => {
    const usNumberFormatter = new Intl.NumberFormat('en-US');
    const pointsToGo = 250000 - props.points;
    const eligible = props.points >= 250000;

    const statusMessage = eligible ? "Let your rep know if you'd like to go!" : `Just ${usNumberFormatter.format(pointsToGo)} points to go!`;

    return <Tooltip
        title={"This is an awesome trip that starts at 250,000 points. Come hang out on the beach with us!"}
        arrow
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -60],
                        },
                    },
                ],
            },
        }}
    >
        <div style={{marginTop: 10, textAlign: "center"}}>
            <Typography variant={"h6"}>VIP Beach Trip for Two Valued at $6,500! </Typography>
            <Typography variant={"body1"}>Eligibility starts at 250,000 points.</Typography>
            <Typography variant={"body1"}>{statusMessage}</Typography>
        </div>
    </Tooltip>
}

export default BeachCounter;