import { useContext, useState, useEffect} from "react"
import { AuthContext } from "../../providers/AuthProvider"
import Axios from "axios"
import { useHistory } from "react-router"
import { CUSTOMER_NAME} from "../../constants/Constants"
import { datadogLogs } from "@datadog/browser-logs"


export interface FetchProps  {
  children?: any
}

const FetchUser = (props: FetchProps) => {

  const history = useHistory()
  const [checkToken, setCheckToken] = useState(false)
  const auth = useContext(AuthContext)

  useEffect(()=>{
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const getUser = async () => {
    if (auth.authenticated || !localStorage.getItem('token')) {      
      setCheckToken(true)
      return;
    }

    const config = {
      headers: {
        'authorization':localStorage.getItem("token"),
        'Accept' : 'application/json',
        'Content-Type': 'application/json',
        "Cache-Control": "no-store"
      }
    }

    let customerName = encodeURIComponent(localStorage.getItem(CUSTOMER_NAME) as string | number | boolean)    
    
    try {
      const res = await Axios.get(`/api/user/getUser?${CUSTOMER_NAME}=${customerName}&timestamp=${new Date().getTime()}`, config)
      if(auth.setUser && auth.setAuthenticated){
        auth.setUser(res.data)
        auth.setAuthenticated(true)

        datadogLogs.setLoggerGlobalContext({userEmail: res.data?.email})
      }

      let myToken: string = localStorage.getItem("token") || ''
      auth.getUsersCustomers?.(res.data.email, myToken)

    } catch (err) {
      if(auth.handleLogout){auth.handleLogout(history)}
        } finally {
      setCheckToken(true)
    }

    }

    if(checkToken) {

     return props.children
       } 
       return null
      
}

export default FetchUser