import {camelCaseToSplitWords, FIELD_TYPE, ValidationRule} from "../ValidationRule";

class ForEach implements ValidationRule {
    type = FIELD_TYPE;

    nextRule: ValidationRule;

    constructor(nextRule: ValidationRule) {
        this.nextRule = nextRule;
    }

    isValid(field: any): boolean {
        for(const element of field) {
            if(!this.nextRule.isValid(element)) {
                return false;
            }
        }

        return true;
    }

    getErrorMessage(fieldName: string): string {
        const name = camelCaseToSplitWords(fieldName);
        return `${name} must be a valid. Please supply all fields.`;
    }
}

export default ForEach;