import {NewUserData} from "./Register";
import React, {useContext} from "react";
import {Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import SimpleAutoComplete from "../../guiComponents/SimpleAutoComplete";
import {StylesContext} from "../../../providers/StylesProvider";
import MultiSelect from "../../guiComponents/MultiSelect";

const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]

const customerTypes = [
    "Commercial",
    "Residential New",
    "Residential Custom",
    "Design Build",
    "Municipal",
    "Maintenance",
    "Government",
    "Golf Course",
    "Other"
]

const serviceTypes = [
    "Shrub and Tree planting",
    "Maintenance",
    "Hardscapes",
    "Irrigation",
    "Landscape Design ",
    "Environmental Restoration ",
    "Snow Removal",
    "Other"
]

export type BusinessCustomerSupplementalInfoTwoProps = {
    userData: NewUserData,
    updateUserData: (event: React.ChangeEvent<HTMLInputElement>) => void,
    directDataUpdate: (fieldName: string, value: any) => void,
    isInvalid: (fieldName: string) => boolean
    getErrorMessage: (fieldName: string) => string
    showInstructions?: boolean
}

const BusinessCustomerSupplementalInfoTwo = (props: BusinessCustomerSupplementalInfoTwoProps) => {
    //context
    const {userData} = props;
    const {isDesktop} = useContext(StylesContext);

    //local vars
    const showInstructions = props.showInstructions ?? true;

    const changeMultiSelection = (fieldName: string, event: any) => {
        let newSelection =
            event.target.value === "string"
                ? event.target.value.split(",")
                : event.target.value;

        props.directDataUpdate(fieldName, newSelection);
    };


    return <>
        <div style={{
            display: "flex",
            marginBottom: 10,
            flexDirection: "row",
            width: isDesktop ? "800px" : "100%",
            flexFlow: "wrap"
        }}>
            {showInstructions && <Typography variant={"h6"} style={{margin: 10}}>
                Understanding your business will help the team at Tree Source better serve you. Please take a few moments to answer the questions below.
            </Typography>}
            <div style={{display: "flex", flexDirection: "column", minWidth: isDesktop ? "400px" : "100%"}}>
                <div style={{display: "flex", flexDirection: "row", width: isDesktop ? "400px" : "100%"}}>
                    <TextField
                        style={{margin: 10, width: "49%"}}
                        variant="standard"
                        autoFocus
                        type="number"
                        name="numberOfCrews"
                        label="Number of Crews"
                        error={props.isInvalid("numberOfCrews")}
                        helperText={props.getErrorMessage("numberOfCrews")}
                        onChange={props.updateUserData}
                        value={userData.numberOfCrews}
                    />
                    <TextField
                        style={{margin: 10, width: "49%"}}
                        variant="standard"
                        type="number"
                        name="averageNumberOfProjectsAnnually"
                        label="Annual # of Projects"
                        error={props.isInvalid("averageNumberOfProjectsAnnually")}
                        helperText={props.getErrorMessage("averageNumberOfProjectsAnnually")}
                        onChange={props.updateUserData}
                        value={userData.averageNumberOfProjectsAnnually}
                    />
                </div>
                {/*TODO: number field type thing? - would only allow numbers*/}
                <TextField
                    style={{margin: 10}}
                    variant="standard"
                    name="geographiesServed"
                    label="Geographies Served"
                    error={props.isInvalid("geographiesServed")}
                    helperText={props.getErrorMessage("geographiesServed")}
                    onChange={props.updateUserData}
                    value={userData.geographiesServed}
                />
                <MultiSelect
                    id={"customerTypes"}
                    label={"Customers Served"}
                    options={customerTypes}
                    values={userData.customerTypes}
                    onChange={(value) => changeMultiSelection("customerTypes", value)}
                    formControlStyle={{margin: 10, maxWidth: isDesktop ? 380 : "100%"}}
                    // style={{margin: 10}}
                />
                <MultiSelect
                    id={"services"}
                    label={"Services Offered"}
                    options={serviceTypes}
                    values={userData.services}
                    onChange={(value) => changeMultiSelection("services", value)}
                    formControlStyle={{margin: 10, maxWidth: isDesktop ? 380 :  "100%"}}/>

            </div>
            <div style={{display: "flex", flexDirection: "column", minWidth: isDesktop ? "400px" : "100%"}}>
                <div style={{display: "flex", flexDirection: "row", width: isDesktop ? "400px" : "100%"}}>
                    <SimpleAutoComplete
                        id={"averageSeasonStart"}
                        label={"Season Start"}
                        options={monthsOfYear}
                        outlined={false}
                        value={userData.averageSeasonStart}
                        setValue={(value) => props.directDataUpdate("averageSeasonStart", value)}
                        style={{margin: 10, width: "49%"}}/>
                    <SimpleAutoComplete
                        id={"averageSeasonEnd"}
                        label={"Season End"}
                        options={monthsOfYear}
                        outlined={false}
                        value={userData.averageSeasonEnd}
                        setValue={(value) => props.directDataUpdate("averageSeasonEnd", value)}
                        style={{margin: 10, width: "49%"}}/>
                </div>
                <TextField
                    style={{margin: 10}}
                    variant="standard"
                    name="averageJobSize"
                    label="Average Job Size"
                    error={props.isInvalid("averageJobSize")}
                    helperText={props.getErrorMessage("averageJobSize")}
                    onChange={props.updateUserData}
                    value={userData.averageJobSize}
                    InputProps={{
                        startAdornment: <span>$</span>,
                    }}
                />
                <FormControlLabel
                    style={{margin: 10}}
                    control={<Checkbox checked={userData.holdPlantMaterialAtYard}
                                       onChange={(event) => props.directDataUpdate("holdPlantMaterialAtYard", event.target.checked)}/>}
                    label="Do you hold material at your yard?"
                />
                <FormControlLabel
                    style={{margin: 10}}
                    control={<Checkbox checked={userData.acceptShipments}
                                       onChange={(event) => props.directDataUpdate("acceptShipments", event.target.checked)}/>}
                    label="Do you accept direct shipments?"
                />
            </div>
        </div>
    </>
}

export default BusinessCustomerSupplementalInfoTwo;