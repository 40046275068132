import React, {useContext} from "react";
import {Button, Dialog, DialogContent, DialogTitle, MenuItem, Select, TextField} from "@mui/material";
import {USER_ROLES} from "../../constants/Constants";
import {Customer, UserType} from "../../types/UserType";
import {AuthContext} from "../../providers/AuthProvider";

//There's a fairly good argument that this is a 'nested subclass' and it will never be used anywhere except AccountInfoCard.tsx
//IDK how we want to deal with this. I'm putting it in a new file for now.
const PREFIX = "EditAccountInfo";

const classes = {
    editDialog: `${PREFIX}-editDialog`,
    editButtons: `${PREFIX}-editButtons`,
};

export type EditAccountInfoProps = {
    open: boolean;
    handleClose: () => void;
    editValue: string;
    setEditValue: React.Dispatch<React.SetStateAction<string>>;
    editType: string;
    editName: string;
};

const EditAccountInfo: React.FC<EditAccountInfoProps> = (props: EditAccountInfoProps) => {
    const Auth = useContext(AuthContext);
    const { open, handleClose, editValue, setEditValue, editType, editName} = props;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const { user } = Auth;
        const customer = user?.customer;

        let editUser: UserType;

        if (
            Auth.setUser &&
            user &&
            customer &&
            Auth.userCustomers &&
            Auth.setUserCustomers &&
            Auth.updateUser &&
            Auth.updateCustomer
        ) {
            switch (editName) {
                case "firstName":
                    editUser = { ...user, firstName: editValue };
                    Auth.setUser(editUser);
                    Auth.updateUser(editUser);
                    break;
                case "lastName":
                    editUser = { ...user, lastName: editValue };
                    Auth.setUser(editUser);
                    Auth.updateUser(editUser);
                    break;
                case "phoneNumber":
                    editUser = { ...user, phoneNumber: editValue };
                    Auth.setUser(editUser);
                    Auth.updateUser(editUser);
                    break;
                case "title":
                    editUser = { ...user, title: editValue };
                    Auth.setUser(editUser);
                    Auth.updateUser(editUser);
                    break;
                case "customerName":
                    let editUserCustomers = Auth.userCustomers.map((uc) =>
                        uc?.fullName === customer.fullName
                            ? { ...uc, companyName: editValue, displayName: editValue }
                            : uc
                    );
                    Auth.setUserCustomers(editUserCustomers as Customer[]);
                    let customerEdit = { ...customer, companyName: editValue };
                    Auth.setUser({ ...user, customer: customerEdit });
                    Auth.updateCustomer(
                        customer.fullName as string,
                        editValue,
                        user.entityName
                    );
                    break;
                default:
                    break;
            }
        }

        handleClose();
    };

    const dropDown = () => {
        return (
            <DialogContent className={classes.editDialog}>
                <Select
                    onChange={(event: any) => setEditValue(event?.target.value)}
                    value={editValue}
                >
                    {USER_ROLES.map((x) => {
                        return <MenuItem value={x}>{x}</MenuItem>;
                    })}
                </Select>
                <div className={classes.editButtons}>
                    <Button onClick={() => handleClose()}>Cancel</Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </div>
            </DialogContent>
        );
    };

    const text = () => {
        return (
            <DialogContent className={classes.editDialog}>
                <form onSubmit={handleSubmit}>
                    <TextField
                        onChange={(event) => setEditValue(event?.target.value)}
                        value={editValue}
                    />
                    <div className={classes.editButtons}>
                        <Button onClick={() => handleClose()}>Cancel</Button>
                        <Button type="submit">Submit</Button>
                    </div>
                </form>
            </DialogContent>
        );
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit</DialogTitle>
            {editType === "dropDown" ? dropDown() : text()}
        </Dialog>
    );
};

export default EditAccountInfo;