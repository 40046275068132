
import React, {useContext, useState} from "react";
import {
    Alert, AlertColor,
    Button,
    Container,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import {BranchSelector} from "./BranchSelector";
import CameraWidget from "../guiComponents/CameraWidget";
import {ALULookup} from "./ALULookup";

import {StylesContext} from "../../providers/StylesProvider";
const INITIAL_CAMERA_STATE = "Take new picture";

export type PhotoBoothProps = {
    forItemBases: boolean;
    title: string;
}
const PlantPhotoBooth = (props: PhotoBoothProps) => {
    // Data for submit
    const [branch, setBranch] = React.useState<string>("")
    const [aluValue, setALUValue] = React.useState("");
    const [aluValid, setALUValid] = React.useState(false);
    const [dimensions, setDimensions] = React.useState("");
    const [note, setNote] = React.useState("");
    const [photoBlob, setPhotoBlob] = React.useState<Blob | null>(null);

    // UI Controls
    const { isDesktop } = useContext(StylesContext);
    const [open, setOpen] = React.useState(false);
    const [toastSeverity, setToastSeverity] = useState<AlertColor | undefined>();
    const [toastMessage, setToastMessage] = React.useState("");

    const [cameraWidgetState, setCameraWidgetState] = React.useState(INITIAL_CAMERA_STATE);


    const sendPostRequest = async (vendor: string, alu: string, imageBlob: Blob | null) => {
        const formData = new FormData();
        if (imageBlob) {
            formData.append('file', imageBlob, 'photo.jpg');
            formData.append('note', note);
            formData.append('dimensions', dimensions);
            formData.append('updateItemBase', props.forItemBases ? 'true' : 'false')
        }

        const response = await fetch(`/api/products/${vendor}/${alu}/photos`, {
            method: 'PUT',
            body: formData,
            headers: {
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit
        });

        if (!response.ok) {
            throw new Error('Failed to send POST request');
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        try {
            await sendPostRequest(encodeURIComponent(branch), encodeURIComponent(aluValue), photoBlob);

            setToastMessage("Photo submitted! Thanks!");
            setToastSeverity("success");
            setOpen(true);

            setBranch("");
            setALUValue("");
            setDimensions("");
            setNote("");
            setPhotoBlob(null);
            setCameraWidgetState(INITIAL_CAMERA_STATE);

            // Scroll to the top of the page
            window.scrollTo(0, 0);
        } catch (error) {
            console.error(error);

            setToastMessage("Failed to submit photo. Please try again later.");
            setToastSeverity("error");
            setOpen(true);
        }
    };

    const getVendor = () => {
        if(branch) {
            return branch;
        } else {
            return "Central";
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const renderToast = () => {
        return (
            <Snackbar autoHideDuration={4000} open={open} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={toastSeverity}
                    style={{ width: "50%" }}
                >
                    {toastMessage}
                </Alert>
            </Snackbar>
        );
    };

    const formInvalid = () => {
        return !branch || !aluValid || !dimensions || !photoBlob;
    }

    return (
        <>
            <Container style={{paddingTop: isDesktop ? 75 : 55}}>
                <Typography variant="h6">{props.title}</Typography>
                <hr></hr>
                <BranchSelector
                    branch={branch}
                    setBranch={setBranch}
                />

                <ALULookup aluValue={aluValue} branch={getVendor()} setALUValue={setALUValue} setAluValid={setALUValid}/>

                <div style={{marginTop: 10, marginBottom: 10}}>
                    <TextField
                        id="dimensions"
                        fullWidth
                        label="Width x Height"
                        type="text"
                        value={dimensions}
                        onChange={(e) => setDimensions(e.target.value)}
                    />
                </div>

                <div style={{marginTop: 10, marginBottom: 10}}>
                    <TextField
                        id="noteForCustomer"
                        fullWidth
                        label="Optional Note For Customer"
                        type="text"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                </div>

                <CameraWidget setPhotoBlob={setPhotoBlob} widgetState={cameraWidgetState} setWidgetState={setCameraWidgetState}/>

                <div
                    id="buttonGroup"
                    style={{display: "flex",}}
                >
                    <Button
                        style={{
                            flex: "1",
                            marginBottom: 20
                        }}
                        id="submitCountButton"
                        type="submit"
                        variant="contained"
                        color="success"
                        onClick={handleSubmit}
                        disabled={formInvalid()}
                    >
                        Submit Photo & Info
                    </Button>
                </div>
            </Container>
            {renderToast()}
        </>
    );
}

export default PlantPhotoBooth;