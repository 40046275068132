import { Autocomplete, TextField } from "@mui/material";
import { useState, useEffect } from "react";

type LocationSelectorProps = {
  branch: string;
  setBranch: React.Dispatch<React.SetStateAction<string>>;
};

export const BranchSelector = (props: LocationSelectorProps) => {
  // data from backend
  const [branchOptions, setBranchOptions] = useState<string[]>([]);

  // local data
  const NO_SELECTION = "";
  const selectedBranch = props.branch;
  const setSelectedBranch = props.setBranch;

  const fetchBranches = async () => {
    fetch("/api/branches/all", {
      headers: {
        Authorization: localStorage.getItem("token") ?? undefined,
      } as HeadersInit,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        const branchNames = data.map((result: any) => result.entityName);

        if (!branchNames.includes(NO_SELECTION)) {
          branchNames.push(NO_SELECTION);
        }

        setBranchOptions(branchNames);
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  };


  useEffect(() => {
    fetchBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Autocomplete
        id="branchAutoComplete"
        style={{  }}
        options={branchOptions}
        value={selectedBranch}
        onChange={(event, value) => {
          setSelectedBranch(value ?? "");
        }}
        renderInput={(params) => (
          <TextField {...params} label="Branch" type="text" />
        )}
      />

    </>
  );
};
