import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SpecificOrder from '../LoggedIn/SpecificOrder';
import { Divider, SwipeableDrawer } from '@mui/material';


interface DrawerProps {
  openDrawer?: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  orderid: string,
  shipmentid: string,
  drawerWidth: number,
  phone: boolean,
  tablet: boolean, 
  successToastOpen: boolean
}


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function PersistentDrawerRight(props: DrawerProps) {
  const theme = useTheme();
  const {openDrawer, setOpenDrawer, orderid, shipmentid, drawerWidth, phone, tablet, successToastOpen} = props



  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleDrawerOpen = (open: boolean) => {
    setOpenDrawer(open)
  }

  const desktopDrawer = () => {
      return (
          <Drawer
                  sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                      width: drawerWidth,
                    },
                  }}
                  variant={tablet ? "persistent" : "temporary" }
                  anchor="right"
                  open={openDrawer}
                  onClose={handleDrawerClose}
                >
                  <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                      {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                  </DrawerHeader>
                  <Divider />
                  <SpecificOrder orderid={orderid} shipmentid={shipmentid} successToastOpen={successToastOpen}/>
          </Drawer>
      )
    }

  const container = window !== undefined ? () => window.document.body : undefined;

  const phoneDrawer = () => {
    return(
      <SwipeableDrawer
        container={container}
        open={openDrawer as boolean}
        onClose={handleDrawerClose}
        onOpen={()=>handleDrawerOpen(true)}
        swipeAreaWidth={56}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true
        }}
        anchor="bottom"
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <SpecificOrder orderid={orderid} shipmentid={shipmentid} successToastOpen={successToastOpen}/>
      </SwipeableDrawer>
    )
  }

  return (
      phone ? desktopDrawer() : phoneDrawer()
  )

 
}
