import React, {useCallback, useContext, useEffect, useState} from 'react';
import {TextField, Typography} from '@mui/material';
import SimpleAutoComplete from "../SimpleAutoComplete";
import { CardWithNickname, PaymentMethod } from "../../../types/CardDataType";
import {StylesContext} from "../../../providers/StylesProvider";

const listNextTenYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [""];

    for (let i = 0; i < 10; i++) {
        years.push("" + (currentYear + i));
    }

    return years;
}

const monthsOfYear = ["", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
const years = listNextTenYears();

type CardInputFieldsProps = {
    paymentMethod: CardWithNickname;
    setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
    asPaymentMethod: (card: CardWithNickname) => PaymentMethod;
};

const CardInputFields: React.FC<CardInputFieldsProps> = (props: CardInputFieldsProps) => {
    //destructuring / context
    const { asPaymentMethod } = props;
    const { isDesktop } = useContext(StylesContext);

    //local state
    const [cvcError, setCvcError] = useState('');

    const looksLikeAmex = (cardNumber: string) => {
        return cardNumber.slice(0, 2) === '37' || cardNumber.slice(0, 2) === '34';
    }

    const validateCvc = useCallback(() => {
        if(props.paymentMethod.number.length < 5 || props.paymentMethod.cvc.length < 3) {
            if(cvcError.length > 0) {
                setCvcError('');
            }

            return;
        }

        if (looksLikeAmex(props.paymentMethod.number) && props.paymentMethod.cvc.length !== 4) {
            setCvcError('CVC should be 4 digits for American Express');
        } else if (!looksLikeAmex(props.paymentMethod.number) && props.paymentMethod.cvc.length !== 3) {
            setCvcError('CVC should be 3 digits for most cards');
        } else {
            setCvcError('');
        }
    }, [props.paymentMethod.number, props.paymentMethod.cvc.length, cvcError.length]);

    const isExpiryDateInvalid = (expMonth: string, expYear: string): boolean => {
        const currentDate = new Date();
        // Set the expiration date to the last day of the month
        const expiryDate = new Date(parseInt(expYear), parseInt(expMonth), 0);

        // If the current date is greater than the expiration date, the card is expired
        return currentDate > expiryDate;
    };

    useEffect(() => {
        validateCvc();
    }, [props.paymentMethod.number, props.paymentMethod.cvc, validateCvc]);


    const renderDesktop = () => {
        return <>
            <TextField label="Name on card" variant="outlined" margin="dense" fullWidth value={props.paymentMethod.name} onChange={(event) => {
                props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, name: event.target.value}));
            }} />
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <TextField
                    id="cardNumber"
                    label="Card number"
                    variant="outlined"
                    margin="dense"
                    style={{width: "50%"}}
                    value={props.paymentMethod.number}
                    onChange={(event) => {
                        const value = event.target.value;
                        const isNumber = !isNaN(Number(value));

                        if (isNumber) {
                            props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, number: value}));
                        }
                    }}
                />
                <SimpleAutoComplete
                    id={"expMonth"}
                    label={"Exp. Month"}
                    options={monthsOfYear}
                    value={props.paymentMethod.expMonth}
                    setValue={(value) => props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, expMonth: value}))}
                    error={isExpiryDateInvalid(props.paymentMethod.expMonth, props.paymentMethod.expYear)}
                    style={{width: "14%", marginTop: 8}} />

                <div style={{width: "14%"}}>
                    <SimpleAutoComplete
                        id={"expYear"}
                        label={"Exp. Year"}
                        options={years}
                        value={props.paymentMethod.expYear}
                        setValue={(value) => props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, expYear: value}))}
                        error={isExpiryDateInvalid(props.paymentMethod.expMonth, props.paymentMethod.expYear)}
                        style={{width: "100%", marginTop: 8}} />
                    {isExpiryDateInvalid(props.paymentMethod.expMonth, props.paymentMethod.expYear) && <Typography variant="caption" color="error">Card is expired</Typography>}
                </div>

                <TextField
                    label="CVC"
                    variant="outlined"
                    margin="dense"
                    style={{width: "15%"}}
                    value={props.paymentMethod.cvc}
                    onChange={(event) => {
                        const value = event.target.value;
                        const isNumber = !isNaN(Number(value));

                        if (isNumber) {
                            props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, cvc: event.target.value}));
                        }
                    }}
                    error={!!cvcError}
                    helperText={cvcError}
                />
            </div>
        </>
    }

    const renderMobile = () => {
        return <>
            <TextField label="Name on card" variant="outlined" margin="dense" fullWidth value={props.paymentMethod.name}
                       onChange={(event) => {
                           props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, name: event.target.value}));
                       }}/>
            <TextField
                id="cardNumber"
                label="Card number"
                variant="outlined"
                margin="dense"
                fullWidth
                value={props.paymentMethod.number}
                onChange={(event) => {
                    const value = event.target.value;
                    const isNumber = !isNaN(Number(value));

                    if (isNumber) {
                        props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, number: value}));
                    }
                }}
            />
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{width: "38%"}}>
                    <SimpleAutoComplete
                        id={"expMonth"}
                        label={"Exp. Month"}
                        options={monthsOfYear}
                        value={props.paymentMethod.expMonth}
                        setValue={(value) => props.setPaymentMethod(asPaymentMethod({
                            ...props.paymentMethod,
                            expMonth: value
                        }))}
                        style={{width: "100%", marginTop: 8}}
                        error={isExpiryDateInvalid(props.paymentMethod.expMonth, props.paymentMethod.expYear)}
                    />
                </div>
                <div style={{width: "38%"}}>
                    <SimpleAutoComplete
                        id={"expYear"}
                        label={"Exp. Year"}
                        options={years}
                        value={props.paymentMethod.expYear}
                        setValue={(value) => props.setPaymentMethod(asPaymentMethod({
                            ...props.paymentMethod,
                            expYear: value
                        }))}
                        style={{width: "100%", marginTop: 8}}
                        error={isExpiryDateInvalid(props.paymentMethod.expMonth, props.paymentMethod.expYear)}
                    />
                </div>
                <div style={{width: "20%"}}>
                    <TextField
                        label="CVC"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        value={props.paymentMethod.cvc}
                        onChange={(event) => {
                            const value = event.target.value;
                            const isNumber = !isNaN(Number(value));

                            if (isNumber) {
                                props.setPaymentMethod(asPaymentMethod({
                                    ...props.paymentMethod,
                                    cvc: event.target.value
                                }));
                            }
                        }}
                        error={!!cvcError}
                    />
                </div>
            </div>
            <div>
                {isExpiryDateInvalid(props.paymentMethod.expMonth, props.paymentMethod.expYear) &&
                    <Typography variant="caption" color="error">Card is expired</Typography>}
            </div>
            <div>
                {!!cvcError && <Typography variant="caption" color="error">{cvcError}</Typography>}
            </div>
        </>
    }

    return <>
        {isDesktop ? renderDesktop() : renderMobile()}
    </>
};

export default CardInputFields;