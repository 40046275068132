import {AddressType} from "../../../types/CardDataType";

export type CreditReference = {
    companyName: string;
    contactName: string;
    address: AddressType;
    phoneNumber: string;
    isPastDue: boolean;
}

export const DEFAULT_CREDIT_REFERENCE: CreditReference = {
    companyName: "",
    contactName: "",
    address: {
        streetAddress: "",
        city: "",
        region: "",
        postalCode: "",
        country: "US"
    } as AddressType,
    phoneNumber: "",
    isPastDue: false
}

export type BankReference = {
    [key: string]: any;
    bankName: string;
    officerName: string;
    phoneNumber: string;
    checkingAccountNumber: string;
    savingsAccountNumber: string;
    address: AddressType;
}

export const DEFAULT_BANK_REFERENCE: BankReference = {
    bankName: "",
    officerName: "",
    phoneNumber: "",
    checkingAccountNumber: "",
    savingsAccountNumber: "",
    address: {
        streetAddress: "",
        city: "",
        region: "",
        postalCode: "",
        country: "US"
    } as AddressType
}

export type CreditApplicationData = {
    [key: string]: any;
    companyName: string;
    userStreetAddress: AddressType; //make required earlier or ??
    userFirstName: string;
    userLastName: string;
    userRole: string;

    references: CreditReference[];
    bankReference: BankReference;
    creditRequested: number;
    stateSalesTaxNumber: string;
    optionalExemptionCertificate: string; //actually a file - going to punt on it until later though
    isTaxExempt: boolean;
    hasAcceptedStandardTerms: boolean;
    hasAcceptedPersonalGuaranty: boolean;
    hasAcceptedSecurityAgreement: boolean;
}

export const DEFAULT_CREDIT_APPLICATION: CreditApplicationData = {
    companyName: "",
    userStreetAddress: {
        streetAddress: "",
        city: "",
        region: "",
        postalCode: "",
        country: "US"
    } as AddressType,
    userFirstName: "",
    userLastName: "",
    userRole: "",

    references: [DEFAULT_CREDIT_REFERENCE, DEFAULT_CREDIT_REFERENCE, DEFAULT_CREDIT_REFERENCE, DEFAULT_CREDIT_REFERENCE],
    bankReference: DEFAULT_BANK_REFERENCE,
    creditRequested: 0,
    stateSalesTaxNumber: "",
    optionalExemptionCertificate: "N/A",
    hasAcceptedStandardTerms: false,
    hasAcceptedPersonalGuaranty: false,
    hasAcceptedSecurityAgreement: false,
    isTaxExempt: false
}
