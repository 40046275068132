import {camelCaseToSplitWords, FIELD_TYPE, ValidationRule} from "../ValidationRule";

class MustHaveFourReferences implements ValidationRule {
    type = FIELD_TYPE;

    isValid(field: any)  {
        return field.length >= 4;
    }

    getErrorMessage(fieldName: string) {
        const name = camelCaseToSplitWords(fieldName);
        return `You must supply at least four ${name}`;
    }
}

export default MustHaveFourReferences;