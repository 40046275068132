import {Button, Typography} from "@mui/material";
import {StylesContext} from "../../../providers/StylesProvider";
import React, {useContext} from "react";
import {DEFAULT_JOB_LINE_APPLICATION, JobLineApplicationData} from "./JobLineApplicationData";
import {NewUserData} from "./Register";
import JobLineApplicationCard from "./JobLineApplicationCard";
import JobLineInfoBox from "../../guiComponents/JobLineInfoBox";

export type OptionalJobLineProps = {
  progressStep: () => void;
  regressStep: () => void;
  userData: NewUserData;
  directDataUpdate: (fieldName: string, value: any) => void;
};

const OptionalJobLineApp = (props: OptionalJobLineProps) => {
    //context
    const {isDesktop} = useContext(StylesContext);

    //local variables
    const [fillOutApplication, setFillOutApplication] = React.useState(props.userData.optionalJobLineApp !== undefined);
    const [jobLineAppValid, setJobLineAppValid] = React.useState(false);

    const setJobLineApplicationData = (newData: JobLineApplicationData) => {
        props.directDataUpdate("optionalJobLineApp", newData);
    }

    const cancelJobLineApp = () => {
        setFillOutApplication(false);

        props.userData.optionalJobLineApp = undefined; //clear app
        props.progressStep();
    }

    const cancelAndBackJobLineApp = () => {
        setFillOutApplication(false);

        props.userData.optionalJobLineApp = undefined; //clear app
        props.regressStep();
    }

    const beginJobLineApp = () => {
        if(!props.userData.optionalJobLineApp) {
            const newApp = {...DEFAULT_JOB_LINE_APPLICATION,
                signedBy: `${props.userData.firstName} ${props.userData.lastName}`,
            };

            setJobLineApplicationData(newApp);
        }

        setFillOutApplication(true);
    }

    const optionToFillOutApplication = () => {
        return <>
            <div style={{width: isDesktop ? "800px" : "100%"}}>
                <JobLineInfoBox/>
            </div>

            <Typography variant="h6" style={{margin: 10}}>Optional: Would you like to apply for a job line now?</Typography>
            <div style={{marginTop: 20}}>
                <Button variant="contained" color="primary" onClick={() => props.regressStep()}>Back</Button>
                <Button style={{marginLeft: 10}} variant="contained" color="primary"
                        onClick={beginJobLineApp}>Yes</Button>
                <Button style={{marginLeft: 10}} variant="contained" color="primary"
                        onClick={() => props.progressStep()}>Skip</Button>
            </div>
        </>
    }

    const displayJobLine = () => {
        return <>
            <div style={{width: isDesktop ? "800px" : "100%"}}>
                <JobLineApplicationCard
                    jobLineApplication={props.userData.optionalJobLineApp ?? DEFAULT_JOB_LINE_APPLICATION}
                    setJobLineApplicationData={setJobLineApplicationData}
                    cancelFunction={cancelJobLineApp}
                    setIsValid={setJobLineAppValid}
                />
                <div style={{marginTop: 20, marginBottom: 20, display: "flex", justifyContent: "center"}}>
                    <Button variant="contained" color="primary" onClick={() => cancelAndBackJobLineApp()}>Cancel And Back</Button>

                    <Button style={{marginLeft: 10}} variant="contained" color="primary" disabled={!jobLineAppValid} onClick={() => props.progressStep()}>Next</Button>
                </div>
            </div>
        </>
    }

    if(fillOutApplication) {
        return displayJobLine();
    } else {
        return optionToFillOutApplication();
    }

};

export default OptionalJobLineApp;