import {
  Alert,
  Snackbar,
  Tooltip,
  Typography,
  useMediaQuery,
  Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridToolbar,
} from "@mui/x-data-grid";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { OrderLineItemType } from "../../types/OrderLineItemType";
import { ShipmentType } from "../../types/ShipmentType";
import { AuthContext } from "../../providers/AuthProvider";
import { formatter, SHOW_ADD_TO_SHIP } from "../../constants/Constants";
import axios from "axios";
import XFormData from "../../Utilities/XFormData";
import { UpdateItemDialog } from "./UpdateItemDialog";
import { CustomFooterTotalComponent } from "./CustomFooterTotalComponent";

const PREFIX = "OrderLineItems";

const classes = {
  totalBar: `${PREFIX}-totalBar`,
  totalBarBottom: `${PREFIX}-totalBarBottom`,
  totalBarPhone: `${PREFIX}-totalBarPhone`,
  headerText: `${PREFIX}-headerText`,
  headerTextForFooter: `${PREFIX}-headerTextForFooter`,
  headerContentForFooter: `${PREFIX}-headerContentForFooter`,
  headerContent: `${PREFIX}-headerContent`,
  headerLine: `${PREFIX}-headerLine`,
  dataGridPhone: `${PREFIX}-dataGridPhone`,
  dataGridDesktop: `${PREFIX}-root`,
};

const Root = styled("div")({
  [`& .${classes.totalBar}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: 10,
    marginRight: 10,
  },
  [`& .${classes.totalBarBottom}`]: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    paddingTop: 25,
    paddingBottom: 25,
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    background: "#8BC63E",
  },
  [`& .${classes.totalBarPhone}`]: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    margin: 5,
  },
  [`& .${classes.headerText}`]: {
    fontWeight: "bold",
    fontSize: "12pt",
    marginTop: 3,
    marginBottom: 3,
  },
  [`& .${classes.headerTextForFooter}`]: {
    fontWeight: "bold",
    fontSize: "12pt",
  },
  [`& .${classes.headerContentForFooter}`]: {
    fontSize: "12pt",
    marginRight: "10px",
    marginLeft: "5px",
  },
  [`& .${classes.headerContent}`]: {
    fontSize: "12pt",
    marginRight: "10px",
    marginLeft: "5px",
    marginTop: 3,
    marginBottom: 3,
  },
  [`& .${classes.headerLine}`]: {
    display: "inline-flex",
    marginTop: 3,
  },
  [`& .${classes.dataGridPhone}`]: {
    height: "80vh",
    border: 0,
  },
  [`& .${classes.dataGridDesktop}`]: {
    height: "80vh !important",
    marginBottom: 150,
  },
});

type OliProps = {
  orderLineItems: OrderLineItemType[];
  oli: RefObject<HTMLDivElement>;
  oliLoading: boolean;
  oliError: boolean;
  selectedOrderData: ShipmentType | GridRowModel | undefined;
  view: string;
  setRemovingUpdating: Dispatch<SetStateAction<boolean>>;
};

const OrderLineItems = (props: OliProps) => {
  const {
    orderLineItems,
    oli,
    oliLoading,
    selectedOrderData,
    view,
    setRemovingUpdating,
  } = props;

  const auth = useContext(AuthContext);

  const matches = useMediaQuery("(min-width:716px");

  const [newOli, setNewOli] = useState<any>(
    orderLineItems.map((x: any) => {
      let commonNamePlusSize = x.commonName + " " + x.sizeID;
      let amount = x.effectivePrice * x.sourceItemQuantity;
      return { ...x, commonName: commonNamePlusSize, amount: amount };
    })
  );

  const [row, setRow] = useState<GridRowModel>({});
  const [editItem, setEditItem] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [toastSuccess, setToastSuccess] = useState(false);
  const [statusMessage, setStatusMessage] = useState(false);

  const convertShipmentType = (key: string) => {
    switch (key) {
      case "WILL_CALL":
        return "Will Call/Yard Pickup";
      case "YARD_SHIP":
        return "Shipping from local yard";
      case "DIRECT_SHIP":
        return "Farm Direct Ship";
      case "DROP_SHIP":
        return "Third Party Drop Ship";
      default:
        return "";
    }
  };

  function showAddFeature() {
    return (
      localStorage.getItem("token") !== undefined &&
      localStorage.getItem("token") != null &&
      localStorage.getItem(SHOW_ADD_TO_SHIP) === "true"
    );
  }

  const handleUpdateItem = async (e: any, row: GridRowModel) => {
    e.preventDefault();
    setRemovingUpdating(true);
    const config = {
      headers: {
        authorization: localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-store",
      },
    };
    const data = new XFormData().format({
      itemCode: row.requestedItemCode,
      quantity: row.sourceItemQuantity,
      shipmentName: "",
      customer: auth?.user?.customer?.fullName,
      entityName: row.vendor,
      lineItemId: row.id,
      comment: row.comment ? row.comment : " ",
      sortOrder: row.sortOrder,
    });
    // Make the HTTP request to save in the backend
    try {
      await axios.put(
        `/api/orderlineitems/order/${row.orderID}/shipment/${row.deliveryId}`,
        data,
        config
      );
      let orderLineItems = newOli.map((x: any) =>
        x.id === row.id
          ? {
              ...x,
              sourceItemQuantity: row.sourceItemQuantity,
              comment: row.comment,
            }
          : x
      );

      setNewOli(orderLineItems);
      setToastSuccess(true);
      setRemovingUpdating(false);
      setEditItem(false);
    } catch (error: any) {
      setRemovingUpdating(false);
      setToastError(true);
      setErrorMessage(
        error.response.data.message ||
          "An error has occurred, please contact a sales rep for assistance."
      );
    }
  };

  const handleRemoveLineItem = async (row: GridRowModel) => {
    if (window.confirm("Are you sure you want to delete this item? ")) {
      setEditItem(false);
      setRemovingUpdating(true);

      const config = {
        data: {
          customer: auth?.user?.customer?.fullName,
          sortOrder: row.sortOrder,
          order: row.orderID,
          shipment: row.deliveryId,
        },
        headers: {
          authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
          "Cache-Control": "no-store",
        },
      };

      let customer = auth?.user?.customer?.fullName;
      let entityName = auth?.user?.customer?.entityName;
      let sortOrder = row.sortOrder;
      // Make the HTTP request to save in the backend
      try {
        let response = await axios.delete(
          `/api/orderlineitems?entityName=${entityName}&customer=${customer}&orderId=${row.orderID}&shipmentId=${row.deliveryId}&sortOrder=${sortOrder}`,
          config
        );

        let orderLineItems = newOli.filter((x: any) => x.id !== row.id);
        let orderLineItemsReSorted = orderLineItems.map((x: any, i: number) => {
          let sortOrder = (x.sortOrder = i + 1);
          return { ...x, sortOrder: sortOrder };
        });
        setNewOli(orderLineItemsReSorted);
        setRemovingUpdating(false);

        return response;
      } catch (error: any) {
        setRemovingUpdating(false);
      }
    } else {
      setEditItem(false);
    }
  };

  const handleRowClick = (params: GridRowModel) => {
    setEditItem(true);
    setRow(params.row);
  };

  const handleCloseToast = () => {
    setToastError(false);
    setToastSuccess(false);
  };

  const handleCloseStatusMessageToast = () => {
    setStatusMessage(false);
  };

  const doNothing = () => {
    return null;
  };

  const handleOpenStatusMessageToast = () => {
    setStatusMessage(true);
  };

  const effectivePrice = (params: GridRenderCellParams) => {
    if (params.row.priceOverride === 0) {
      return params.row.suggestedPrice as number;
    }
    return params.row.priceOverride as number;
  };

  const oliColumns: GridColDef[] = [
    {
      field: "commonName",
      headerName: "Common Name",
      width: 250,
    },
    {
      field: "sourceItemQuantity",
      headerName: "Qty",
      width: 75,
      type: "number",
    },
    {
      field: "suggestedPrice",
      headerName: "Price",
      width: 125,
      renderCell: (params) => {
        return <p>{formatter.format(effectivePrice(params))}</p>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 125,
      renderCell: (params: any) => {
        let price: number = effectivePrice(params);
        let quantity: number = params.row.sourceItemQuantity;

        return <p>{formatter.format(quantity * price)}</p>;
      },
    },
    {
      field: "rootTypeDesc",
      headerName: "Root Type",
      width: 125,
      renderCell: (params) => {
        if (
          params.row.rootTypeDesc
            ? params.row.rootTypeDesc.includes("B&B")
            : false
        ) {
          return (
            <Tooltip title="Ball and Burlap">
              <p>{params.row.rootTypeDesc}</p>
            </Tooltip>
          );
        }
        return (
          <Tooltip title="Container Pot">
            <p>{params.row.rootTypeDesc}</p>
          </Tooltip>
        );
      },
    },
    {
      field: "comment",
      headerName: "Comment",
      width: 200,
    },
  ];
  const displayGrid = () => {
    return (
      <>
        <DataGrid
          className={!matches ? classes.dataGridPhone : classes.dataGridDesktop}
          rows={newOli}
          columns={oliColumns}
          density={matches ? "standard" : "compact"}
          components={{
            Toolbar: GridToolbar,
            Footer: CustomFooterTotalComponent,
          }}
          componentsProps={{
            footer: selectedOrderData,
          }}
          onRowClick={
            showAddFeature()
              ? selectedOrderData?.status === "OPEN"
                ? handleRowClick
                : handleOpenStatusMessageToast
              : doNothing
          }
        />
      </>
    );
  };

  const totalBar = () => {
    return (
      <div className={matches ? classes.totalBarBottom : classes.totalBarPhone}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: `${matches ? "4em" : "0"}`,
          }}
        >
          <div className={classes.headerLine}>
            <Typography className={classes.headerTextForFooter}>
              {" "}
              Balance Due:
            </Typography>
            <Typography className={classes.headerContentForFooter}>
              {selectedOrderData
                ? formatter.format(selectedOrderData.shipmentBalance)
                : ""}
            </Typography>
          </div>
          <div className={classes.headerLine}>
            <Typography className={classes.headerTextForFooter}>
              {" "}
              Account Balance:{" "}
            </Typography>
            <Typography className={classes.headerContentForFooter}>
              {auth.user
                ? formatter.format(
                    auth.user?.customer?.totalBalance
                      ? auth.user.customer.totalBalance
                      : 0
                  )
                : ""}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  if (oliLoading) {
    return (
      <p style={{ textAlign: "center", marginTop: 200 }}>
        {" "}
        Click on an order to view specific line items
      </p>
    );
  }

  return (
    <Root>
      <Container>
        <div
          ref={oli}
          id="oli"
          className={matches ? classes.totalBar : classes.totalBarPhone}
        >
          <div className={classes.headerLine}>
            <Typography className={classes.headerText}> Order #: </Typography>
            <Typography className={classes.headerContent}>
              {selectedOrderData
                ? selectedOrderData.orderID + "-" + selectedOrderData.shipmentID
                : ""}
            </Typography>
          </div>
          <div className={classes.headerLine}>
            <Typography className={classes.headerText}> Job Name: </Typography>
            <Typography className={classes.headerContent}>
              {selectedOrderData ? selectedOrderData.jobName : "N/A"}
            </Typography>
          </div>
          {selectedOrderData?.landDate ? (
            <div className={classes.headerLine}>
              <Typography className={classes.headerText}>LandDate:</Typography>
              <Typography className={classes.headerContent}>
                {selectedOrderData
                  ? selectedOrderData.landDate
                    ? selectedOrderData.landDate.toLocaleDateString()
                    : ""
                  : ""}
              </Typography>
            </div>
          ) : (
            ""
          )}
          <div className={classes.headerLine}>
            <Typography className={classes.headerText}> Status: </Typography>
            <Typography className={classes.headerContent}>
              {selectedOrderData ? selectedOrderData.status : ""}
            </Typography>
          </div>

          {selectedOrderData?.dueDate ? (
            <div className={classes.headerLine}>
              <Typography className={classes.headerText}>
                {" "}
                Due Date:{" "}
              </Typography>
              <Typography className={classes.headerContent}>
                {selectedOrderData ? selectedOrderData.dueDate : ""}
              </Typography>
            </div>
          ) : (
            ""
          )}
          {selectedOrderData?.type ? (
            <div className={classes.headerLine}>
              <Typography className={classes.headerText}>
                Delivery Type:{" "}
              </Typography>
              <Typography className={classes.headerContent}>
                {selectedOrderData
                  ? convertShipmentType(selectedOrderData.type)
                  : ""}
              </Typography>
            </div>
          ) : (
            ""
          )}
        </div>
        {matches ? displayGrid() : ""}
        <UpdateItemDialog
          open={editItem}
          setEditItem={setEditItem}
          handleUpdateItem={handleUpdateItem}
          row={row}
          setRow={setRow}
          error={toastError}
          handleRemoveLineItem={handleRemoveLineItem}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
        <Snackbar
          open={toastError || toastSuccess}
          autoHideDuration={2000}
          onClose={handleCloseToast}
        >
          <Alert
            severity={toastError ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {toastError ? errorMessage : "Successfully updated item quantity"}
          </Alert>
        </Snackbar>
        <Snackbar
          open={statusMessage}
          autoHideDuration={8000}
          onClose={handleCloseStatusMessageToast}
        >
          <Alert severity={"info"} sx={{ width: "100%" }}>
            Only Orders with an "OPEN" Status can be modified.
          </Alert>
        </Snackbar>
        {view === "orderview" ? totalBar() : ""}
      </Container>
      {!matches ? displayGrid() : ""}
    </Root>
  );
};

export default OrderLineItems;
