import React from "react";
import { useState } from "react";
import { getUnfinishedOrders } from "../endpoints/GetUnfinishedOrders";
import { NEW_ORDER } from "../constants/Constants";
import { OrderForAdd } from "../components/LoggedOut/Products";

export type OrderPropertiesType = {
  orders: OrderForAdd[];
  allAddToOptions: any[];
  selectedOrderForAdd: OrderForAdd;
  getUnfinishedOrders: () => Promise<any>;
  setSelectedOrderForAdd: React.Dispatch<React.SetStateAction<OrderForAdd>>;
};

export const OrderContext = React.createContext<Partial<OrderPropertiesType>>(
  {}
);

export const OrderProvider = (props: any) => {
  const [orders, setOrders] = useState<any[]>([]);
  const [allAddToOptions, setAllAddToOptions] = useState<any[]>([]);
  const [selectedOrderForAdd, setSelectedOrderorAdd] =
    useState<OrderForAdd>(NEW_ORDER);

  let getOpenOrders = async () => {
    let orderNames = await getUnfinishedOrders();
    setOrders(orderNames);
    const addToOrderOptions = [NEW_ORDER];
    orderNames.forEach((val: any) =>
      addToOrderOptions.push(Object.assign({}, val))
    );
    setAllAddToOptions(addToOrderOptions);

    return orderNames;
  };

  const customer: OrderPropertiesType = {
    setSelectedOrderForAdd: setSelectedOrderorAdd,
    getUnfinishedOrders: getOpenOrders,
    selectedOrderForAdd: selectedOrderForAdd,
    orders: orders,
    allAddToOptions,
  };

  return (
    <OrderContext.Provider value={customer}>
      {props.children}
    </OrderContext.Provider>
  );
};
