
import {
    AddressType,
    BankAccountWithNickname,
    CardWithNickname,
    TYPE_BANK_ACCOUNT,
    TYPE_CARD
} from "../../../types/CardDataType";

// Cards
export const NEW_CARD: CardWithNickname = {
    type: TYPE_CARD,
    id: "New Card",
    cardType: "None",
    expYear: "",
    expMonth: "",
    name: "",
    cvc: "",
    number: "",
    nickname : "",
    isDefault: true,
    errors: "",
    warnings: "",
    address: {
        streetAddress: "",
        city: "",
        region: "",
        postalCode: "",
        country: "US"
    } as AddressType
};

export const NEW_BANK_ACCOUNT: BankAccountWithNickname = {
    type: TYPE_BANK_ACCOUNT,
    id: "New Bank Account",
    name: "",
    accountNumber: "",
    routingNumber: "",
    accountType: "",
    phone: "",
    inputType: "KEYED",
    nickname : "",
    isDefault: true,
    errors: "",
    warnings: "",
};

// Payment Options
export const PAY_ACCOUNT_BALANCE = "Pay account balance";
export const PAY_SPECIFIC_INVOICES = "Pay Specific Invoices";
export const PAY_OTHER_AMOUNT = "Pay Other Amount";
