import { DataGridPro, GridEnrichedColDef } from "@mui/x-data-grid-pro";
import { WebsiteAvailabilityGroup } from "../../types/ProductType";
import { useContext } from "react";
import ItemAvailability from "./ItemAvailability";
import { AuthContext } from "../../providers/AuthProvider";
import {StylesContext} from "../../providers/StylesProvider";

const ItemAvailabilityGroup = ({
  itemAvailGroup,
  catalogformatting,
  setErrorToastMessage,
  setErrorToastOpen,
  setSuccessToastOpen,
}: {
  itemAvailGroup: WebsiteAvailabilityGroup;
  catalogformatting: string;
  setErrorToastMessage: (value: String | string) => void;
  setErrorToastOpen: (value: boolean) => void;
  setSuccessToastOpen: (value: boolean) => void;
}) => {
  const isExpired = () => {
    if (isTokenExpired) {
      return isTokenExpired();
    }
    return true;
  };

  const { user, loggedOutLocation, isTokenExpired, isConfiguredUser } =
    useContext(AuthContext);

  const showPricing = isConfiguredUser ? isConfiguredUser() : false;

  const styles = useContext(StylesContext);
  const isDesktop  = styles.isDesktop ?? false;

  const defaultLocalBranch =
    localStorage.getItem("token") == null ||
    isExpired() ||
    user?.userCustomerAssociation?.defaultLocalBranch === undefined
      ? loggedOutLocation
      : user?.userCustomerAssociation?.defaultLocalBranch;

  const detailColumns: GridEnrichedColDef[] = [
    {
      field: "quantity",
      headerName: "Available",
      width: 80,
      hide: catalogformatting === "enable" ? true : false,
      align: "center",
    },

    {
      field: "companyName",
      headerName: "Location",
      width: isDesktop ? 170 : 90,
      renderCell: (params) => {
        return <p>{params.row.companyName}</p>;
      },
    },
    {
      field: "priceAdjustment",
      headerName: isDesktop ? defaultLocalBranch + " Pick Up Price" : "Price",
      align: "left",
      hide: !showPricing,
      width: isDesktop ? 850 : 200,
      sortable: false,
      hideable: false,
      renderCell: (params) => {
        return (
            <ItemAvailability
                screen="availabilityList"
                websiteAvailability={params.row}
                minimumPrice={itemAvailGroup.priceFloor}
                setErrorToastMessage={setErrorToastMessage}
                setErrorToastOpen={setErrorToastOpen}
                setSuccessToastOpen={setSuccessToastOpen}
            />
        );
      },
    },
  ];

  return (
    <>
      <DataGridPro
        rows={itemAvailGroup.websiteItemAvailabilities}
        columns={detailColumns}
        autoHeight
      />
    </>
  );
};

export default ItemAvailabilityGroup;
