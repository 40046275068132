import axios from "axios"

export const addHeadersAndCallBackend = (url: string) => {

    let config = {
      headers: {
        'authorization': localStorage.getItem("token"),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Cache-Control": "no-store"
      }
    }
    return axios.get(url, config)
  
    
  }