import {Card, CardHeader, useMediaQuery} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import BeachCounter from "./BeachCounter";
import RebateCounter from "./RebateCounter";
import {AuthContext} from "../../../providers/AuthProvider";
import GiftCardCounter from "./GiftCardCounter";
import {CustomerContext} from "../../../providers/CustomerProvider";

export type LoyaltyRewardsTrackerProps = {

}

const LoyaltyRewardsTracker: React.FC<LoyaltyRewardsTrackerProps> = (props: LoyaltyRewardsTrackerProps) => {
    // context
    const over480 = useMediaQuery("(min-width:480px");

    const authContext = useContext(AuthContext);
    const selectedCustomerName = authContext?.user?.customer?.fullName;
    const inLoyaltyProgram : boolean | undefined = authContext?.user?.customer?.inLoyaltyProgram;

    const { fetchLoyaltyPoints } = useContext(CustomerContext)

    // local state
    const [points, setPoints] = useState(10000);

    useEffect(() => {
        if(selectedCustomerName && fetchLoyaltyPoints) {
            fetchLoyaltyPoints(selectedCustomerName, inLoyaltyProgram)
                .then((data) => {
                    setPoints(data);
                })
        }
    }, [selectedCustomerName, inLoyaltyProgram, fetchLoyaltyPoints]);

    const render = () => {
        return <Card style={{
            padding: "15px",
            margin: "15px",
            width: over480 ? 480 : "100%",
            opacity: "85%",
        }} color="white">
            <CardHeader style={{ textAlign: "center" }} title="Choose Your Reward!" />
            <GiftCardCounter points={points} />
            <RebateCounter points={points} />
            <BeachCounter  points={points} />
        </Card>
    }

    return <>{ inLoyaltyProgram ? render() : <></> }</>

}

export default LoyaltyRewardsTracker;