import {JobLineApplicationData} from "./JobLineApplicationData";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button, Checkbox, FormControlLabel,
    TextField,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import AccountReceivableSpecialistInfo, {
    RACHEL_SHOCKEY
} from "../../guiComponents/payments/AccountsReceivableSpecialist";
import AddressInputFields from "../../guiComponents/payments/AddressInputFields";
import {ExpandMore} from "@mui/icons-material";
import JobLineValidator from "./validators/jobLineApplication/JobLineValidator";

export type JobLineApplicationCardProps = {
    jobLineApplication: JobLineApplicationData;
    setJobLineApplicationData: (newData: JobLineApplicationData) => void;
    cancelFunction?: () => void;
    showHeader?: boolean;
    setIsValid: (isValid: boolean) => void;
}

const JobLineApplicationCard = (props: JobLineApplicationCardProps) => {
    //context
    const {jobLineApplication, setJobLineApplicationData, setIsValid} = props;

    //local vars
    const [validator] = useState(new JobLineValidator());
    const showHeader = props.showHeader ?? true;

    const getErrorMessage = (fieldName: string): string => {
        return validator.validateFieldIfTouched(jobLineApplication, fieldName);
    }

    const isInvalid = (fieldName: string): boolean => {
        return getErrorMessage(fieldName) !== "";
    }

    const updateData = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        directDataUpdate(name, value);
    };

    const directDataUpdate = (name: string, value: any) => {
        const newJobLineData = {...jobLineApplication, [name]: value};
        setJobLineApplicationData(newJobLineData);

        const errors = validator.validateAll(newJobLineData);
        setIsValid(Object.keys(errors).length === 0);
    }

    const conditionallyRenderHeader = () => {
        if(!showHeader) {
            return <></>;
        }

        return <>
            <div style={{
                marginTop: 10,
                marginBottom: 15,
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
            }}>
                <Typography variant={"h6"}>JOB LINE APPLICATION</Typography>
                {props.cancelFunction && <div style={{marginTop: 5}}>
                    <Button variant="contained" color="secondary" onClick={() => {
                        props.cancelFunction!()
                    }}>Cancel</Button>
                </div>}
            </div>
            <AccountReceivableSpecialistInfo arSpecialist={RACHEL_SHOCKEY} title={"Questions? Ask me!"} padded={false}/>
        </>
    }

    return <>
        {conditionallyRenderHeader()}
        <div style={{
            display: "flex",
            marginTop: 15,
            flexDirection: "column",
            justifyContent: "space-between",
        }}>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                    <Typography variant="subtitle1" style={{fontWeight: "bold"}}>Job Info</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <TextField
                            style={{width: "49%"}}
                            required
                            margin={"dense"}
                            variant="standard"
                            autoFocus
                            name="jobName"
                            label="Job Name"
                            error={isInvalid("jobName")}
                            helperText={getErrorMessage("jobName")}
                            onChange={updateData}
                            value={jobLineApplication.jobName}
                        />
                        <TextField
                            style={{width: "49%"}}
                            required
                            margin={"dense"}
                            variant="standard"
                            name="jobEstimatedMaterialCost"
                            label="Estimated Material Cost"
                            error={isInvalid("jobEstimatedMaterialCost")}
                            helperText={getErrorMessage("jobEstimatedMaterialCost")}
                            onChange={(event) => {
                                const value = event.target.value;
                                const doubleRegex = /^\d*\.?\d{0,2}$/;
                                const candidate = Number(value);

                                if (value === "" || (doubleRegex.test(value) && candidate >= 0)) {
                                    updateData(event);
                                }
                            }}
                            InputProps={{
                                startAdornment: jobLineApplication.jobEstimatedMaterialCost > 0 ?
                                    <span>$</span> : null,
                            }}
                            value={jobLineApplication.jobEstimatedMaterialCost > 0 ? jobLineApplication.jobEstimatedMaterialCost : ""}
                        />



                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <TextField
                            style={{width: "49%"}}
                            required
                            margin={"dense"}
                            variant="standard"
                            name="jobBidNumber"
                            label="PO Number"
                            error={isInvalid("jobBidNumber")}
                            helperText={getErrorMessage("jobBidNumber").replace("Job Bid Number", "PO Number")}
                            onChange={updateData}
                            value={jobLineApplication.jobBidNumber}
                        />
                        <TextField
                            style={{width: "49%"}}
                            margin={"dense"}
                            variant="standard"
                            name="scrNumber"
                            label="SCR Number"
                            error={isInvalid("scrNumber")}
                            helperText={getErrorMessage("scrNumber").replace("Scr Number", "SCR Number")}
                            onChange={updateData}
                            value={jobLineApplication.scrNumber}
                        />
                    </div>

                        <AddressInputFields address={jobLineApplication.jobPhysicalAddress}
                                            setAddress={address => directDataUpdate("jobPhysicalAddress", address)}
                                            label={"Job Physical Address"}
                                            required={true}
                                            outlined={false}
                        />
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                    <Typography variant="subtitle1" style={{fontWeight: "bold"}}>Property Owner Info</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <TextField
                            style={{width: "49%"}}
                            required
                            margin={"dense"}
                            variant="standard"
                            name="propertyOwnerName"
                            label="Property Owner Name"
                            error={isInvalid("propertyOwnerName")}
                            helperText={getErrorMessage("propertyOwnerName")}
                            onChange={updateData}
                            value={jobLineApplication.propertyOwnerName}
                        />
                        <TextField
                            style={{width: "49%"}}
                            required
                            margin={"dense"}
                            variant="standard"
                            name="propertyOwnerPhoneNumber"
                            label="Property Owner Phone Number"
                            error={isInvalid("propertyOwnerPhoneNumber")}
                            helperText={getErrorMessage("propertyOwnerPhoneNumber")}
                            onChange={(event) => {
                                const value = event.target.value;
                                const doubleRegex = /^\d*\.?\d{0,2}$/;
                                const candidate = Number(value);

                                if (value === "" || (doubleRegex.test(value) && candidate >= 0)) {
                                    updateData(event);
                                }
                            }}
                            value={jobLineApplication.propertyOwnerPhoneNumber}
                        />
                    </div>

                    <AddressInputFields address={jobLineApplication.propertyOwnerPhysicalAddress}
                                        setAddress={address => directDataUpdate("propertyOwnerPhysicalAddress", address)}
                                        label={"Property Owner Address"}
                                        required
                                        outlined={false}/>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                    <Typography variant="subtitle1" style={{fontWeight: "bold"}}>General Contractor Info</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <TextField
                            style={{width: "49%"}}
                            required
                            margin={"dense"}
                            variant="standard"
                            name="generalContractorName"
                            label="General Contractor Name"
                            error={isInvalid("generalContractorName")}
                            helperText={getErrorMessage("generalContractorName")}
                            onChange={updateData}
                            value={jobLineApplication.generalContractorName}
                        />
                        <TextField
                            style={{width: "49%"}}
                            required
                            margin={"dense"}
                            variant="standard"
                            name="generalContractorPhoneNumber"
                            label="General Contractor Phone"
                            error={isInvalid("generalContractorPhoneNumber")}
                            helperText={getErrorMessage("generalContractorPhoneNumber")}
                            onChange={(event) => {
                                const value = event.target.value;
                                const doubleRegex = /^\d*\.?\d{0,2}$/;
                                const candidate = Number(value);

                                if (value === "" || (doubleRegex.test(value) && candidate >= 0)) {
                                    updateData(event);
                                }
                            }}
                            value={jobLineApplication.generalContractorPhoneNumber}
                        />
                    </div>

                    <AddressInputFields address={jobLineApplication.generalContractorPhysicalAddress}
                                        setAddress={address => directDataUpdate("generalContractorPhysicalAddress", address)}
                                        label={"Property Owner Address"}
                                        required
                                        outlined={false}/>
                </AccordionDetails>
            </Accordion>
            <FormControlLabel
                style={{marginTop: 10, marginLeft: 0}}
                control={<Checkbox checked={jobLineApplication.digitallySigned}
                                   onChange={(event) => directDataUpdate("digitallySigned", event.target.checked)}/>}
                label={`Signed by ${jobLineApplication.signedBy} on ${jobLineApplication.dateOfApplication.toLocaleDateString('en-CA')}.`}
            />
        </div>
    </>
}

export default JobLineApplicationCard;