import {WebsiteAvailability, WebsiteAvailabilityGroup} from "../../types/ProductType";
import React from "react";
import {PaddedCard} from "./PaddedCard";
import {KeyValue} from "../KeyValue";
import {CardContent, CardHeader} from "@mui/material";
import ItemAvailability from "../guiComponents/ItemAvailability";

export type ProductInfoProps = {
    selectedProduct: WebsiteAvailabilityGroup;
    defaultBranch: string;
    isLoggedOUt: boolean;
    pageReady: boolean;
}

export const ProductInfo = (props: ProductInfoProps) => {
    const product = props.selectedProduct;

    const conditionalRender = (label: string, value: string | number | undefined) => {
        if(value) {
            if(typeof value === "string") {
                const capitalized = value.charAt(0).toUpperCase() + value.slice(1);

                return KeyValue(label + " ", "" + capitalized);
            }

            return KeyValue(label + " ", "" + value);
        } else {
            return "";
        }
    }

    const renderPlantInfo = () => {
        return  <PaddedCard>
                    <CardHeader title="Product Information" />
                    <CardContent>
                        {KeyValue("Common Name:", product?.commonName )}
                        {KeyValue("Latin Name:", product?.botanicalName )}
                        {KeyValue("Quantity: ", product?.quantity )}
                        {KeyValue("Future Availability:", product?.quantityOnPO )}
                        <hr/>
                        {conditionalRender("Category:", product?.category)}
                        {conditionalRender("Leaf Type: ", product?.leafType)}
                        {conditionalRender("Root ball:", product?.rootBall)}
                        {conditionalRender("Growth Rate: ", product?.growthRate)}
                        {conditionalRender("Min Zone: ", product?.zoneMin)}
                        {conditionalRender("Max Zone: ", product?.zoneMax)}
                        {conditionalRender("Mature Height (ft): ", product?.matureHeightInFeet)}
                        {conditionalRender("Mature Spread (ft): ", product?.matureSpreadInFeet)}
                        {conditionalRender("Bloom Start Month: ", product?.bloomStartDate)}
                        {conditionalRender("Bloom End Month: ", product?.bloomEndDate)}
                        {conditionalRender("Bloom Description: ", product?.bloomDesc)}
                        {conditionalRender("Sun: ", product?.sun)}
                        {conditionalRender("Water: ", product?.water)}
                        {conditionalRender("Maintenance: ", product?.maintenance)}
                        {conditionalRender("Suggested Use: ", product?.suggestedUse)}
                        {conditionalRender("Color: ", product?.color)}
                    </CardContent>
                </PaddedCard>
    }

    const renderAvailabilityInfo = () => {
        if(product.websiteItemAvailabilities.length === 0) {
            return <></>
        }

        return   <PaddedCard>
                    <CardHeader title={"Pick Up At " + props.defaultBranch}  />
                    <CardContent>
                        {product.websiteItemAvailabilities.map((x) => {
                            return (
                                <React.Fragment key={x.id}>
                                    <ItemAvailability
                                        websiteAvailability={x as WebsiteAvailability}
                                        minimumPrice={product?.priceFloor}
                                        screen="product"
                                        setErrorToastMessage={(e) => {}}
                                        setErrorToastOpen={(e) => {}}
                                        setSuccessToastOpen={(e) => {}}
                                    />
                                    <br/>
                                </React.Fragment>
                            );
                        })}
                    </CardContent>
                </PaddedCard>
    }

    const renderProductCard = () => {
        return <>
            {renderPlantInfo()}
            {props.isLoggedOUt ? "" : renderAvailabilityInfo()}
        </>
    }

    return props.pageReady ? renderProductCard() : <></>
}