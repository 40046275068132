export const OBJECT_TYPE = "object", FIELD_TYPE = "field";

export interface ValidationRule {
    type: string; // field || object
    isValid: (value: any) => boolean;
    getErrorMessage: (fieldName: string) => string;
}

export function camelCaseToSplitWords(input: string): string {
    // Insert a space before each uppercase letter and capitalize the first letter
    return input
        .replace(/([A-Z])/g, ' $1') // Insert space before each uppercase letter
        .replace(/^./, str => str.toUpperCase()) // Capitalize the first letter
        .trim(); // Remove any leading/trailing spaces
}

