import BaseValidator from "../BaseValidator";
import NotEmptyRule from "../NotEmptyRule";
import {CreditReference} from "../../CreditApplicationData";
import IsValidAddress from "../IsValidAddress";

class CreditReferenceValidator extends BaseValidator<CreditReference> {
    constructor() {
        super();
        this.dataValidations = {
            companyName: [new NotEmptyRule()],
            contactName: [new NotEmptyRule()],
            address: [new IsValidAddress()],
            phoneNumber: [new NotEmptyRule()],
        };
    }
}

export default CreditReferenceValidator;