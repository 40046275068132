export type ScrollPaneProps = {
    children?: React.ReactNode;
    topMargin?: string;
    height?: string;
}

const ScrollPane: React.FC<React.PropsWithChildren<ScrollPaneProps>> = (props: ScrollPaneProps) => {
    const myTopMargin = props.topMargin ?? '10px';
    const myHeight = props.height ?? '200px';

    return (
        <div style={{ height: myHeight, overflowY: 'auto', border: '1px solid #ccc', padding: '10px', marginTop: myTopMargin, width: '100%', boxSizing: 'border-box' }}>
            {props.children}
        </div>
    );
};

export default ScrollPane;