import {Accordion, AccordionDetails, AccordionSummary, Button, Typography} from "@mui/material";
import {CreditReferenceCard} from "./CreditReferenceCard";
import {CreditApplicationData, CreditReference, DEFAULT_CREDIT_REFERENCE} from "./CreditApplicationData";
import React from "react";
import {ExpandMore} from "@mui/icons-material";

export type CreditReferencesProps = {
    creditApplicationData: CreditApplicationData;
    directDataUpdate: (fieldName: string, value: any) => void;
}

const CreditReferences = (props: CreditReferencesProps) => {
    const {creditApplicationData} = props;

    return (
        <>
            <Accordion style={{width: "100%"}} >
                <AccordionSummary expandIcon={<ExpandMore/>}  >
                    <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Four Trade References</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{marginBottom: 20}}>
                        <Typography variant={"body1"} component="span"> Please provide the names and address of least four
                            established trade references from whom you are presently
                            purchasing and </Typography>
                        <Typography variant={"body1"} component="span" sx={{textDecoration: 'underline'}}> all creditors to whom you
                            are currently past due. </Typography>
                    </div>

                        {creditApplicationData.references.map((reference, index) => {
                            return <CreditReferenceCard reference={reference}
                                                        key={index}
                                                        index={index}
                                                        addReference={() => {
                                                        }}
                                                        removeReference={(index: number) => {
                                                            const newReferences: CreditReference[] = creditApplicationData.references.filter((reference, i) => i !== index);

                                                            props.directDataUpdate("references", newReferences);
                                                        }}
                                                        updateReference={(reference: CreditReference, index: number) => {
                                                            const newReferences: CreditReference[] = creditApplicationData.references.map((ref, i) => i === index ? reference : ref);

                                                            props.directDataUpdate("references", newReferences);
                                                        }}
                            />
                        })
                        }
                        <div style={{display: "flex", flexDirection: "row-reverse", marginTop: 15, marginRight: 10, marginBottom: 5}}>
                            <Button variant="contained" color="primary" onClick={() => {
                                const newReferences: CreditReference[] = [...creditApplicationData.references, DEFAULT_CREDIT_REFERENCE];

                                props.directDataUpdate("references", newReferences);
                            }}>Add Reference</Button>
                        </div>
                </AccordionDetails>
            </Accordion>
        </>

    )
}

export default CreditReferences