import { useState, useContext } from "react";
import { WebsiteAvailability } from "../../types/ProductType";
import { OrderContext } from "../../providers/OrderProvider";
import { showAddFeature } from "../../featureFlags/showAddFeature";
import { Button, Dialog } from "@mui/material";
import AddItemDialog from "../LoggedIn/AddItemDialog";
import {getDisplayForMaxPrice} from "../../Utilities/ProductUtils";

const ItemAvailability = ({
  websiteAvailability,
  minimumPrice,
  screen,
  setErrorToastMessage,
  setErrorToastOpen,
  setSuccessToastOpen,
}: {
  websiteAvailability: WebsiteAvailability;
  minimumPrice: number | null;
  screen: string;
  setErrorToastMessage: (value: String | string) => void;
  setErrorToastOpen: (value: boolean) => void;
  setSuccessToastOpen: (value: boolean) => void;
}) => {
  const orderProvider = useContext(OrderContext);
  const [openAddItem, setOpenAddItem] = useState(false);

  function addItemCallback(response: any) {
    if (typeof response === "string" || response instanceof String) {
      setErrorToastMessage(response);
      setErrorToastOpen(true);
    } else {
      if (orderProvider.setSelectedOrderForAdd) {
        orderProvider.setSelectedOrderForAdd(response.data);
      }

      setSuccessToastOpen(true);
    }
  }

  const generatePriceDetails = (
      view: string,
      vendor: string,
      qty: string,
      price: string
  ) => {
    if (view === "availabilityList") {
        return <div>{price}</div>
    } else {
      return (
          <div>
            <strong>Vendor: </strong>
            {" " + vendor + " "}
            <strong>Quantity: </strong>
            {" " + qty + " "}
            <strong>Price: </strong> {" " + price + " "}
          </div>
      );
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {generatePriceDetails(
          screen,
          websiteAvailability.companyName,
          websiteAvailability.quantity,
          getDisplayForMaxPrice(websiteAvailability.price, minimumPrice)
      )}
      {showAddFeature() ? (
        <Button
          style={{ margin: 10 }}
          variant="contained"
          onClick={() => setOpenAddItem(true)}
        >
          Add To Order
        </Button>
      ) : (
        ""
      )}
      <Dialog
        open={openAddItem}
        onClose={() => {
          setOpenAddItem(false);
        }}
        maxWidth="sm"
      >
        <AddItemDialog
          openOrder={orderProvider.allAddToOptions as any[]}
          selectedLogistics={"Will Call"}
          setOpen={setOpenAddItem}
          vendor={websiteAvailability.companyName}
          itemCode={websiteAvailability?.itemCode as string}
          maxQuantity={parseInt(websiteAvailability?.quantity as string)}
          callback={addItemCallback}
        />
      </Dialog>
    </div>
  );
};

export default ItemAvailability;
