import BaseValidator from "../BaseValidator";
import NotEmptyRule from "../NotEmptyRule";
import { BankReference } from "../../CreditApplicationData";
import IsValidAddress from "../IsValidAddress";

class BankReferenceValidator extends BaseValidator<BankReference> {
    constructor() {
        super();
        this.dataValidations = {
            bankName: [new NotEmptyRule()],
            officerName: [new NotEmptyRule()],
            phoneNumber: [new NotEmptyRule()],
            checkingAccountNumber: [new NotEmptyRule()],
            savingsAccountNumber: [new NotEmptyRule()],
            address: [new IsValidAddress()],
        };
    }
}

export default BankReferenceValidator;