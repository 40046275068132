import React, {useContext, useState} from "react";
import { StylesContext } from "../../providers/StylesProvider";
import CreditApplicationCard from "../authComponents/registration/CreditApplicationCard";
import {DEFAULT_CREDIT_APPLICATION} from "../authComponents/registration/CreditApplicationData";
import {Button, Container, Snackbar, Typography} from "@mui/material";
import {useHistory} from "react-router";
import {AuthContext} from "../../providers/AuthProvider";
import {AddressType} from "../../types/CardDataType";
import Alert from "@mui/material/Alert";
import {CustomerContext} from "../../providers/CustomerProvider";
import {datadogLogs} from "@datadog/browser-logs";

export type NewTermsCardProps = {

}

const NewTermsCard: React.FC<NewTermsCardProps> = () => {
    //context n' such
    const {navBarHeight} = useContext(StylesContext);
    const {user} = useContext(AuthContext);
    const { getParentCustomerName } = useContext(CustomerContext);
    const history = useHistory();

    //local state
    const userAddress: AddressType = { streetAddress: user?.customer?.billingAddress ?? "" }
    const [creditApplication, setCreditApplication] = useState({...DEFAULT_CREDIT_APPLICATION,
                                                                                userRole: user?.title ?? "",
                                                                                userFirstName: user?.firstName ?? "",
                                                                                userLastName: user?.lastName ?? "",
                                                                                companyName: user?.customer?.companyName ?? "", //could be full name
                                                                                userStreetAddress: userAddress });
    const [submitted, setSubmitted] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const cancelCreditApp = () => {
        history.push('/order-management/')
    }

    const renderThankYou = () => {
        return <Container>
            <div style={{paddingTop: (navBarHeight ?? 0) + 30, marginBottom: 30}}>
                <h2>Thank You For Your Application</h2>
                <Typography>
                    Your application has been submitted. The accounting team will reach out to you within five business days about your application.
                </Typography>
            </div>
        </Container>
    }

    const submitCreditApp = () => {
        setSubmitted(true);

        const customerName = user?.customer?.fullName ?? "";
        const parentCustomerName = getParentCustomerName ? getParentCustomerName(customerName) : customerName;
        const encodedCustomerName = encodeURIComponent(parentCustomerName);

        fetch(`/api/customer/${encodedCustomerName}/terms`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
            body: JSON.stringify(creditApplication),

        }).then((response) => {
            if (!response.ok) {
                setSubmitted(false);
                datadogLogs.logger.error(`Error: ${response.statusText} : ${response.body}`);
                setErrorMessage(`Something unexpected happened. Sorry about that! Please reach out if the problem persists: ${response.statusText}`);
            }
        });
    }

    const renderCreditApp = () => {
        return <div style={{paddingTop: navBarHeight}}>
                <Container>
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={errorMessage.length > 0}
                        autoHideDuration={null}
                    >
                        <Alert
                            onClose={() => setErrorMessage("")}
                            severity={"error"}
                            style={{ width: "100%" }}
                        >
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                    <CreditApplicationCard creditApplicationData={creditApplication}
                                           setCreditApplicationData={setCreditApplication}
                                           setIsValid={setIsValid}
                                           standAlone={true}/>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 40 }}>
                        <div/>
                        <div>
                            <Button style={{marginRight: 10}} onClick={() => cancelCreditApp()} color="secondary" variant={"contained"}>Cancel</Button>
                            <Button style={{marginRight: 5}}  onClick={submitCreditApp} disabled={!isValid} variant={"contained"}>Submit</Button>
                        </div>
                    </div>
                </Container>
            </div>
    }

    if(submitted) {
        return renderThankYou();
    } else {
        return renderCreditApp();
    }
}

export default NewTermsCard;