
import styled from 'styled-components';
import treesourceLogo from '../Loaders/graphic-test3-01.svg'

type Props = {
  message: string
}

const PREFIX = 'TreeSourceLoad';

const classes = {
  parent: `${PREFIX}-parent`,
  font: `${PREFIX}-font`,
};

const Root = styled('div')({
  [`& .${classes.parent}`]: {
    margin: "auto",
    textAlign: "center",
    marginTop: "300px"
  },
  [`& .${classes.font}`]: {
    fontFamily: 'Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva,Verdana, sans-serif'
  }
})

const TreeSourceLoad = (props: Props ) => {
  return(
      <Root>
        <div className = {classes.parent} >
          <div style={{minHeight: "100vh", maxWidth: "100px" , margin:"auto",  borderStyle:"none"}}>
            <img src={treesourceLogo} alt="treesource loading spinner"/>
            <p className={classes.font}>{props.message}</p>
          </div>
        </div>
      </Root>
  )
}

export default TreeSourceLoad