import {DEFAULT_PHOTO, Photo} from "./PhotoGallery";
import React, {useContext} from "react";
import {Button, CardContent, CardHeader} from "@mui/material";
import {KeyValue} from "../KeyValue";
import {PaddedCard} from "./PaddedCard";
import {AuthContext} from "../../providers/AuthProvider";
import {StylesContext} from "../../providers/StylesProvider";

export type PhotoInfoProps = {
    photo: Photo;
    pageReady: boolean;
    setHideItemBaseConfig: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PhotoInfo = (props: PhotoInfoProps) => {
    const photo = props.photo;
    const isTrustedInternalUser = useContext(AuthContext).trustedInternalRole || false
    const isDesktop = useContext(StylesContext).isDesktop || false

    const editItemBasePhotos = () => {
        if(isDesktop) {
            return <div style={{display: "flex", flexDirection: "row-reverse", marginLeft: 10, marginRight: 10, marginTop: 10}}>
                <Button variant="contained" color="primary" onClick={() => props.setHideItemBaseConfig(false)}>
                    Edit Item Base Photos
                </Button>
            </div>
        } else {
            return <></>;
        }
    }

    const renderCorePhoto = () => {
        const userSpecifiedNote = photo.note && photo.note.length > 0;
        const augmentedNote = userSpecifiedNote ? photo.note : "Photo is representative of this plant. ";

        return <CardContent>
                    {KeyValue("Note: ", augmentedNote) }
                </CardContent>
    }

    const renderSupplementalPhoto = () => {
        return  <CardContent>
                    {KeyValue("Date: ", photo.uploadTime ?? "") }
                    {KeyValue("Branch: ", photo.branch) }
                    {KeyValue("Dimensions: ", photo.dimensions) }
                    {photo.note ? KeyValue("Note: ", photo.note)  : ""}
                </CardContent>
    }

    if(photo === DEFAULT_PHOTO || !props.pageReady) {
        return <>{isTrustedInternalUser && editItemBasePhotos()}</>
    } else {
        return  <PaddedCard>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <CardHeader title="Photo Information" />
                        <div style={{marginTop: 5}}>
                            {isTrustedInternalUser && editItemBasePhotos()}
                        </div>

                    </div>

                    {photo.core ? renderCorePhoto() : renderSupplementalPhoto()}
                </PaddedCard>
    }
}