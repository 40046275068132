import {
    TextField
} from "@mui/material"
import {NewUserData} from "./Register";
import React, {useContext} from "react";
import {USER_ROLES} from "../../../constants/Constants";
import {BranchContext} from "../../../providers/BranchProvider";
import SimpleAutoComplete from "../../guiComponents/SimpleAutoComplete";
import {StylesContext} from "../../../providers/StylesProvider";
import RegistrationTermsAndConditions from "./RegistrationTermsAndConditions";

export type BasicUserDataFormProps = {
    userData: NewUserData,
    updateUserData: (event: React.ChangeEvent<HTMLInputElement>) => void,
    directDataUpdate: (fieldName: string, value: any) => void,
    isInvalid: (fieldName: string) => boolean
    getErrorMessage: (fieldName: string) => string
}

const BasicUserForm = (props: BasicUserDataFormProps) => {
    //destructing and context
    const {userData} = props;
    const {isDesktop} = useContext(StylesContext);
    const { branches } = useContext(BranchContext);
    const branchNames = branches?.map(x => x.entityName ?? "") ?? [];

    //slight changes: do not ask for company name or role. Move some fields around for balance
    const homeownerVersion = () => {
        return <>
            <div style={{display: "flex", flexDirection: "column", minWidth: isDesktop ? "400px" : "100%"}}>
                <TextField
                    style={{margin: 10}}
                    variant="standard"
                    required
                    autoFocus
                    name="firstName"
                    label="First Name"
                    error={props.isInvalid("firstName")}
                    helperText={props.getErrorMessage("firstName")}
                    onChange={props.updateUserData}
                    value={userData.firstName}
                />
                <TextField
                    style={{margin: 10}}
                    required
                    variant="standard"
                    name="lastName"
                    label="Last Name"
                    error={props.isInvalid("lastName")}
                    helperText={props.getErrorMessage("lastName")}
                    onChange={props.updateUserData}
                    value={userData.lastName}
                />
                <TextField
                    style={{margin: 10}}
                    required
                    variant="standard"
                    name="phoneNumber"
                    label="Phone Number"
                    error={props.isInvalid("phoneNumber")}
                    helperText={props.getErrorMessage("phoneNumber")}
                    onChange={(event) => {
                        const value = event.target.value;
                        const doubleRegex = /^\d*\.?\d{0,2}$/;
                        const candidate = Number(value);

                        if (value === "" || (doubleRegex.test(value) && candidate >= 0)) {
                            props.directDataUpdate('phoneNumber', value);
                        }
                    }}
                    value={userData.phoneNumber}
                />
            </div>
            <div style={{display: "flex", flexDirection: "column", minWidth: isDesktop ? "400px" : "100%"}}>

                <TextField
                    style={{margin: 10}}
                    required
                    name="email"
                    variant="standard"
                    label="Email"
                    error={props.isInvalid("email")}
                    helperText={props.getErrorMessage("email")}
                    onChange={props.updateUserData}
                    value={userData.email}
                />
                <TextField
                    style={{margin: 10}}
                    required
                    name="confirmEmail"
                    variant="standard"
                    label="Confirm Email"
                    error={props.isInvalid("confirmEmail")}
                    helperText={props.getErrorMessage("confirmEmail")}
                    onChange={props.updateUserData}
                    value={userData.confirmEmail}
                />
                <SimpleAutoComplete
                    id={"preferredBranch"}
                    required={true}
                    label={"Preferred Branch"}
                    options={branchNames}
                    outlined={false}
                    value={userData.preferredBranch}
                    error={props.isInvalid("preferredBranch")}
                    errorMessage={props.getErrorMessage("preferredBranch")}
                    setValue={(value) => props.directDataUpdate("preferredBranch", value)}
                    style={{margin: 10}}/>
            </div>
        </>
    }

    const normalVersion = () => {
        return <>
            <div style={{display: "flex", flexDirection: "column", minWidth: isDesktop ? "400px" : "100%"}}>
                <TextField
                    style={{margin: 10}}
                    variant="standard"
                    required
                    autoFocus
                    name="firstName"
                    label="First Name"
                    error={props.isInvalid("firstName")}
                    helperText={props.getErrorMessage("firstName")}
                    onChange={props.updateUserData}
                    value={userData.firstName}
                />
                <TextField
                    style={{margin: 10}}
                    required
                    variant="standard"
                    name="lastName"
                    label="Last Name"
                    error={props.isInvalid("lastName")}
                    helperText={props.getErrorMessage("lastName")}
                    onChange={props.updateUserData}
                    value={userData.lastName}
                />
                <TextField
                    style={{margin: 10}}
                    required
                    name="email"
                    variant="standard"
                    label="Email"
                    error={props.isInvalid("email")}
                    helperText={props.getErrorMessage("email")}
                    onChange={props.updateUserData}
                    value={userData.email}
                />
                <TextField
                    style={{margin: 10}}
                    required
                    name="confirmEmail"
                    variant="standard"
                    label="Confirm Email"
                    error={props.isInvalid("confirmEmail")}
                    helperText={props.getErrorMessage("confirmEmail")}
                    onChange={props.updateUserData}
                    value={userData.confirmEmail}
                />
                {userData.newCustomer && <TextField
                    style={{margin: 10}}
                    required
                    variant="standard"
                    name="EIN"
                    label="EIN"
                    error={props.isInvalid("EIN")}
                    helperText={props.getErrorMessage("EIN")}
                    onChange={props.updateUserData}
                    value={userData.EIN}
                />}
            </div>
            <div style={{display: "flex", flexDirection: "column", minWidth: isDesktop ? "400px" : "100%"}}>
                <TextField
                    style={{margin: 10}}
                    required
                    variant="standard"
                    name="companyName"
                    label="Company Name"
                    error={props.isInvalid("companyName")}
                    helperText={props.getErrorMessage("companyName")}
                    onChange={props.updateUserData}
                    value={userData.companyName}
                />
                <TextField
                    style={{margin: 10}}
                    required
                    variant="standard"
                    name="phoneNumber"
                    label="Phone Number"
                    error={props.isInvalid("phoneNumber")}
                    helperText={props.getErrorMessage("phoneNumber")}
                    onChange={(event) => {
                        const value = event.target.value;
                        const doubleRegex = /^\d*\.?\d{0,2}$/;
                        const candidate = Number(value);

                        if (value === "" || (doubleRegex.test(value) && candidate >= 0)) {
                            props.directDataUpdate('phoneNumber', value);
                        }
                    }}
                    value={userData.phoneNumber}
                />
                <SimpleAutoComplete
                    required={true}
                    id={"preferredBranch"}
                    label={"Preferred Branch"}
                    options={branchNames}
                    outlined={false}
                    value={userData.preferredBranch}
                    error={props.isInvalid("preferredBranch")}
                    errorMessage={props.getErrorMessage("preferredBranch")}
                    setValue={(value) => props.directDataUpdate("preferredBranch", value)}
                    style={{margin: 10}}/>
                <SimpleAutoComplete
                    required={true}
                    id={"role"}
                    label={"Role"}
                    options={USER_ROLES}
                    outlined={false}
                    value={userData.role}
                    setValue={(value) => props.directDataUpdate("role", value)}
                    error={props.isInvalid("role")}
                    errorMessage={props.getErrorMessage("role")}
                    style={{margin: 10}}/>
            </div>
        </>
    }

    return <>
        <div style={{ display: "flex", marginBottom: 10, flexDirection: "row", minWidth: isDesktop ? "400px" : "100%", flexFlow: "wrap" }}>
            {userData.isHomeowner ? homeownerVersion() : normalVersion()}
        </div>

        <RegistrationTermsAndConditions userData={userData} directDataUpdate={props.directDataUpdate}/>
    </>
}

export default BasicUserForm;