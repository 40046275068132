import { OrderForAdd } from "../components/LoggedOut/Products";
import { CUSTOMER_NAME, NEW_ORDER } from "../constants/Constants";
import { OrderType } from "../types/OrderType";
import { addHeadersAndCallBackend } from "./AddHeadersAndCallBackend";

export const getUnfinishedOrders: () => Promise<OrderForAdd[]> = async () => {
  let customerName = encodeURIComponent(
    localStorage.getItem(CUSTOMER_NAME) as string | number | boolean
  );

  let url = `/api/orders?selectedCustomerName=${customerName}&status=QUOTE&status=COMMITTED`;

  let res = await addHeadersAndCallBackend(url);
  let orderNames: OrderForAdd[] = res.data.map((x: OrderType) => {
    let jobJame = x.jobName ? "- " + x.jobName : "";
    let temp: OrderForAdd = {
      label: `${x.orderId} ${jobJame}`,
      orderID: x.orderId,
      orderName: x.jobName as string,
    };

    return temp;
  });

  const addToOrderOptions: OrderForAdd[] = [NEW_ORDER];
  orderNames.forEach((val: any) =>
    addToOrderOptions.push(Object.assign({}, val))
  );

  return orderNames;
};
