import {NewUserData} from "./Register";
import React, {useContext} from "react";
import { TextField, Typography} from "@mui/material";
import SimpleAutoComplete from "../../guiComponents/SimpleAutoComplete";
import {StylesContext} from "../../../providers/StylesProvider";

const typesOfBusinesses = [
    "Sole-Proprietor",
    "Family-Owned",
    "Investor Backed",
    "Corporation",
    "Franchise",
    "Other"
]

export type BusinessCustomerSupplementalInfoOneProps = {
    userData: NewUserData,
    updateUserData: (event: React.ChangeEvent<HTMLInputElement>) => void,
    directDataUpdate: (fieldName: string, value: any) => void,
    isInvalid: (fieldName: string) => boolean
    getErrorMessage: (fieldName: string) => string
    showInstructions?: boolean
}

const BusinessCustomerSupplementalOneInfo = (props: BusinessCustomerSupplementalInfoOneProps) => {
    //context
    const {userData} = props;
    const {isDesktop} = useContext(StylesContext);

    //local state
    const showInstructions = props.showInstructions ?? true;

    return <>
        <div style={{
            display: "flex",
            marginBottom: 10,
            flexDirection: "row",
            width: isDesktop ? "800px" : "100%",
            flexFlow: "wrap"
        }}>
            {showInstructions && <Typography variant={"h6"} style={{margin: 10}}>
                Understanding your business will help the team at Tree Source better serve you. Please take a few moments to answer the questions below.
            </Typography> }
            <div style={{display: "flex", flexDirection: "column", minWidth: isDesktop ? "400px" : "100%"}}>
                <TextField
                    style={{margin: 10}}
                    variant="standard"
                    autoFocus
                    name="businessLegalName"
                    label="Business Legal Name (if different)"
                    error={props.isInvalid("businessLegalName")}
                    helperText={props.getErrorMessage("businessLegalName")}
                    onChange={props.updateUserData}
                    value={userData.businessLegalName}
                />
                <SimpleAutoComplete
                    id={"typeOfBusiness"}
                    label={"Business Type"}
                    options={typesOfBusinesses}
                    outlined={false}
                    value={userData.typeOfBusiness}
                    setValue={(value) => props.directDataUpdate("typeOfBusiness", value)}
                    style={{margin: 10}}/>
                <TextField
                    style={{margin: 10}}
                    variant="standard"
                    name="dateBusinessEstablished"
                    label="In Business Since"
                    error={props.isInvalid("dateBusinessEstablished")}
                    helperText={props.getErrorMessage("dateBusinessEstablished")}
                    onChange={props.updateUserData}
                    value={userData.dateBusinessEstablished}
                />
                <TextField
                    style={{margin: 10}}
                    variant="standard"
                    name="businessWebsite"
                    label="Business Website"
                    error={props.isInvalid("businessWebsite")}
                    helperText={props.getErrorMessage("businessWebsite")}
                    onChange={props.updateUserData}
                    value={userData.businessWebsite}
                />
            </div>
            <div style={{display: "flex", flexDirection: "column", minWidth: isDesktop ? "400px" : "100%"}}>
                <TextField
                    style={{margin: 10}}
                    variant="standard"
                    name="paymentContactName"
                    label="Payment Contact Name"
                    error={props.isInvalid("paymentContactName")}
                    helperText={props.getErrorMessage("paymentContactName")}
                    onChange={props.updateUserData}
                    value={userData.paymentContactName}
                />
                <TextField
                    style={{margin: 10}}
                    variant="standard"
                    name="paymentContactTitle"
                    label="Payment Contact Title"
                    error={props.isInvalid("paymentContactTitle")}
                    helperText={props.getErrorMessage("paymentContactTitle")}
                    onChange={props.updateUserData}
                    value={userData.paymentContactTitle}
                />
                <TextField
                    style={{margin: 10}}
                    variant="standard"
                    name="paymentContactPhone"
                    label="Payment Contact Phone"
                    error={props.isInvalid("paymentContactPhone")}
                    helperText={props.getErrorMessage("paymentContactPhone")}
                    onChange={(event) => {
                        const value = event.target.value;
                        const doubleRegex = /^\d*\.?\d{0,2}$/;
                        const candidate = Number(value);

                        if (value === "" || (doubleRegex.test(value) && candidate >= 0)) {
                            props.directDataUpdate('paymentContactPhone', value);
                        }
                    }}
                    value={userData.paymentContactPhone}
                />
                <TextField
                    style={{margin: 10}}
                    variant="standard"
                    name="paymentContactEmail"
                    label="Payment Contact Email"
                    error={props.isInvalid("paymentContactEmail")}
                    helperText={props.getErrorMessage("paymentContactEmail")}
                    onChange={props.updateUserData}
                    value={userData.paymentContactEmail}
                />
            </div>
        </div>
    </>
}

export default BusinessCustomerSupplementalOneInfo;