import {FIELD_TYPE, OBJECT_TYPE, ValidationRule} from "./ValidationRule";
import {NewUserData} from "../Register";

class IfNewCustomer implements ValidationRule {
    type = OBJECT_TYPE;

    validationRule: ValidationRule;
    fieldName: string;

    constructor(ValidationRule: ValidationRule, fieldName: string) {
        this.validationRule = ValidationRule;
        this.fieldName = fieldName;
    }

    isValid(newUserData: NewUserData)  {
        if(newUserData.newCustomer && this.validationRule.type === FIELD_TYPE) {
            // pass the responsibility for validation to the next rule
            return this.validationRule.isValid(newUserData[this.fieldName]);
        } else if (newUserData.newCustomer && this.validationRule.type === OBJECT_TYPE) {
            return this.validationRule.isValid(newUserData);
        } else {
            return true;
        }
    }

    getErrorMessage(fieldName: string) {
        //take advantage of the fact this is only called when isValid returns false
        //to assume we are a business and need to delegate
        return this.validationRule.getErrorMessage(fieldName);
    }
}

export default IfNewCustomer;