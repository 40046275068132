import React, {useContext} from 'react';
import {
    CardHeader,
    TextField
} from '@mui/material';
import {PaddedCard} from "../../LoggedOut/PaddedCard";
import {StylesContext} from "../../../providers/StylesProvider";
import {BankAccountWithNickname, PaymentMethod} from "../../../types/CardDataType";
import SimpleAutoComplete from "../SimpleAutoComplete";
import SavePaymentMethodFields from "./SavePaymentMethodFields";

const accountTypes = [
    { label: "Personal Checking", code: "PERSONAL_CHECKING" },
    { label: "Personal Savings", code: "PERSONAL_SAVINGS" },
    { label: "Business Checking", code: "BUSINESS_CHECKING" },
    { label: "Business Savings", code: "BUSINESS_SAVINGS" },
];

export type NewPaymentMethodCardProps = {
    //Required and used on the [take] payment page
    setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
    paymentMethod: BankAccountWithNickname;
    saveAccount: boolean;
    setSaveAccount: React.Dispatch<React.SetStateAction<boolean>>;

    //Optional and used on the manage payment page
    cancel?: () => void;
    save?: () => void;
}

const NewCreditCard: React.FC<NewPaymentMethodCardProps> = (props: NewPaymentMethodCardProps) => {
    const {isDesktop} = useContext(StylesContext);

    //Converts a CardWithNickname to a PaymentMethod (workaround some limitation of types)
    const asPaymentMethod = (bankAccount: BankAccountWithNickname): PaymentMethod => {
        return bankAccount;
    }

    const renderDesktop = () => {
        return (
            <PaddedCard style={{marginBottom: 20}}>
                <CardHeader title="New Bank Account" />
                <div style={{marginLeft: 10, marginRight: 10, marginBottom: 10}}>
                    <TextField label="Name on account" variant="outlined" margin="dense" fullWidth value={props.paymentMethod.name} onChange={(event) => {
                        props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, name: event.target.value}));
                    }} />
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <TextField
                            id="routingNumber"
                            label="Routing Number"
                            variant="outlined"
                            margin="dense"
                            style={{width: "49%"}}
                            value={props.paymentMethod.routingNumber}
                            onChange={(event) => {
                                const value = event.target.value;
                                const isNumber = !isNaN(Number(value));

                                if (isNumber) {
                                    props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, routingNumber: value}));
                                }
                            }}
                        />
                        <TextField
                            id="accountNumber"
                            label="Account Number"
                            variant="outlined"
                            margin="dense"
                            style={{width: "49%"}}
                            value={props.paymentMethod.accountNumber}
                            onChange={(event) => {
                                const value = event.target.value;
                                const isNumber = !isNaN(Number(value));

                                if (isNumber) {
                                    props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, accountNumber: value}));
                                }
                            }}
                        />
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <TextField
                            id="phoneNumber"
                            label="Phone Number"
                            variant="outlined"
                            margin="dense"
                            style={{width: "49%"}}
                            value={props.paymentMethod.phone}
                            onChange={(event) => {
                                const value = event.target.value;
                                const isNumber = !isNaN(Number(value));

                                if (isNumber) {
                                    props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, phone: value}));
                                }
                            }}
                        />
                        <SimpleAutoComplete
                            id={"accountType"}
                            label={"Account Type"}
                            options={accountTypes.map((accountType) => accountType.label)}
                            value={accountTypes.find(accountType => accountType.code === props.paymentMethod.accountType)?.label || ""}
                            setValue={(value) => {
                                const selectedType = accountTypes.filter((accountType) => accountType.label === value)[0]?.code ?? ""

                                props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, accountType: selectedType}));
                            }}
                            style={{width: "49%", marginTop: 8}}
                        />
                    </div>

                    <SavePaymentMethodFields setPaymentMethod={props.setPaymentMethod}
                                             paymentMethod={props.paymentMethod}
                                             savePaymentMethod={props.saveAccount}
                                             setSavePaymentMethod={props.setSaveAccount}
                                             cancel={props.cancel}
                                             save={props.save}
                    />
                </div>
            </PaddedCard>
        );
    }

    const renderMobile = () => {
        return (
            <PaddedCard style={{marginBottom: 20}}>
                <CardHeader title="New Bank Account" />
                <div style={{marginLeft: 10, marginRight: 10, marginBottom: 10}}>
                    <TextField label="Name on account" variant="outlined" margin="dense" fullWidth value={props.paymentMethod.name} onChange={(event) => {
                        props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, name: event.target.value}));
                    }} />

                    <TextField
                        id="routingNumber"
                        label="Routing Number"
                        variant="outlined"
                        margin="dense"
                        style={{width: "100%"}}
                        value={props.paymentMethod.routingNumber}
                        onChange={(event) => {
                            const value = event.target.value;
                            const isNumber = !isNaN(Number(value));

                            if (isNumber) {
                                props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, routingNumber: value}));
                            }
                        }}
                    />
                    <TextField
                        id="accountNumber"
                        label="Account Number"
                        variant="outlined"
                        margin="dense"
                        style={{width: "100%"}}
                        value={props.paymentMethod.accountNumber}
                        onChange={(event) => {
                            const value = event.target.value;
                            const isNumber = !isNaN(Number(value));

                            if (isNumber) {
                                props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, accountNumber: value}));
                            }
                        }}
                    />

                    <TextField
                        id="phoneNumber"
                        label="Phone Number"
                        variant="outlined"
                        margin="dense"
                        style={{width: "100%"}}
                        value={props.paymentMethod.phone}
                        onChange={(event) => {
                            const value = event.target.value;
                            const isNumber = !isNaN(Number(value));

                            if (isNumber) {
                                props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, phone: value}));
                            }
                        }}
                    />
                    <SimpleAutoComplete
                        id={"accountType"}
                        label={"Account Type"}
                        options={accountTypes.map((accountType) => accountType.label)}
                        value={accountTypes.find(accountType => accountType.code === props.paymentMethod.accountType)?.label || ""}
                        setValue={(value) => {
                            const selectedType = accountTypes.filter((accountType) => accountType.label === value)[0]?.code ?? ""

                            props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, accountType: selectedType}));
                        }}
                        style={{width: "100%", marginTop: 8}}
                    />

                    <SavePaymentMethodFields setPaymentMethod={props.setPaymentMethod}
                                             paymentMethod={props.paymentMethod}
                                             savePaymentMethod={props.saveAccount}
                                             setSavePaymentMethod={props.setSaveAccount}
                                             cancel={props.cancel}
                                             save={props.save}
                    />

                </div>
            </PaddedCard>
        );
    }

    return <>{isDesktop ? renderDesktop() : renderMobile()}</>
};

export default NewCreditCard;