import {NewUserData} from "../Register";
import NotEmptyRule from "./NotEmptyRule";
import IsEmailRule from "./IsEmailRule";
import EmailsMatchRule from "./EmailsMatchRule";
import MustBeTrueRule from "./MustBeTrueRule";
import IfBusiness from "./IfBusiness";
import BaseValidator from "./BaseValidator";
import IfPresent from "./IfPresent";
import IsValidCreditApplication from "./creditApplication/IsValidCreditApplication";
import IsValidJobLineApplication from "./jobLineApplication/IsValidJobLineApplication";
import IsValidAddress from "./IsValidAddress";
import IfNewCustomer from "./IfNewCustomer";

class NewUserDataValidator extends BaseValidator<NewUserData> {
    constructor() {
        super();
        this.dataValidations = {
            firstName: [new NotEmptyRule()],
            lastName: [new NotEmptyRule()],
            email: [new NotEmptyRule(), new IsEmailRule()],
            confirmEmail: [new NotEmptyRule(), new IsEmailRule(), new EmailsMatchRule()],
            phoneNumber: [new NotEmptyRule()],
            companyName: [new IfBusiness(new NotEmptyRule(), "companyName")],
            role: [new IfBusiness(new NotEmptyRule(), "role")],
            preferredBranch: [new NotEmptyRule()],
            customerType: [new NotEmptyRule()],
            hasAcceptedTerms: [new MustBeTrueRule()],
            jobTitle: [new NotEmptyRule()],
            optionalCreditApp: [new IfPresent(new IsValidCreditApplication())],
            optionalJobLineApp: [new IfPresent(new IsValidJobLineApplication())],
            billingAddress: [new IfNewCustomer(new IsValidAddress(), "billingAddress")],
            EIN: [new IfNewCustomer(new IfBusiness(new NotEmptyRule(), "EIN"), "EIN")], //not chaining quite as cleanly as I'd like... but I'm done with this project lol
        };
    }
}

export default NewUserDataValidator;