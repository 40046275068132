import {Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Typography} from "@mui/material";
import React from "react";
import ScrollPane from "../../guiComponents/ScrollPane";
import {CreditApplicationData} from "./CreditApplicationData";
import {ExpandMore} from "@mui/icons-material";

export type CreditSecurityAgreementProps = {
    creditApplicationData: CreditApplicationData;
    directDataUpdate: (fieldName: string, value: any) => void;
}

const CreditSecurityAgreement: React.FC<CreditSecurityAgreementProps> = (props) => {
    function getCurrentDateFormatted(): string {
        const date = new Date();
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const day = date.getDate();
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();

        let daySuffix = "th";
        if (day % 10 === 1 && day !== 11) {
            daySuffix = "st";
        } else if (day % 10 === 2 && day !== 12) {
            daySuffix = "nd";
        } else if (day % 10 === 3 && day !== 13) {
            daySuffix = "rd";
        }

        return `${month} ${day}${daySuffix}, ${year}`;
    }

    function formattedAddress(): string {
        return ` ${props.creditApplicationData.userStreetAddress.streetAddress}, ${props.creditApplicationData.userStreetAddress.city}, ${props.creditApplicationData.userStreetAddress.region}, ${props.creditApplicationData.userStreetAddress.postalCode} `;
    }

    return (
        <>
            <Accordion style={{width: "100%"}}>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                    <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Security Agreement - Required Over $20,000</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ScrollPane height={"300px"}>
                        <Typography variant={"h6"}>SECURITY AGREEMENT</Typography>
                        <Typography className="body-paragraph-header" variant={"subtitle1"}>Bank Authorization</Typography>
                        <Typography variant={"body2"}>
                            Security agreement made this day of {getCurrentDateFormatted()} by and
                            between {props.creditApplicationData.userFirstName} {props.creditApplicationData.userLastName} of,
                            {formattedAddress()} herein referred to as "Customer/Debtor”, and Treesource LLC, 20851 Bingham
                            Street, Rupert, ID 83350,
                            herein after referred to as "Secured Party." For
                            valuable consideration and on the terms and conditions stated in this agreement, Customer/Debtor
                            transfer and
                            grant to Secured Party a security interest to the collateral described below and agree that the
                            Secured Party shall
                            have the rights stated in this agreement with respect to the collateral in addition to all other
                            rights which Secured
                            Party may have by law. The collateral description is as follows:
                        </Typography>
                        <Typography className="body-paragraph" variant={"body2"}>
                            The word "collateral" as used in this agreement means the following described property, whether now
                            owned or
                            hereinafter acquired, whether now existing or hereafter existing, and wherever located, to which
                            Customer/Debtor is giving to Secured Party a security interest for the payment of all indebtedness
                            now existing
                            or future indebtedness and liabilities in performance of all other obligations under this agreement:
                            All
                            nursery stock, inventory, accounts, machinery and equipment and general intangibles. In addition,
                            the
                            word "collateral" also includes all the following items, whether now owned or hereafter acquired,
                            whether now
                            existing or hereafter existing and wherever located.
                        </Typography>
                        <ol type="A">
                            <li>
                                <Typography variant={"body2"}>
                                    All accessions, attachments, accessories, tools, parts, supplies, replacement of and
                                    additions to any of
                                    the collateral described herein, whether added now or later.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant={"body2"}>
                                    All products and produce of any of the property described in this collateral section.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant={"body2"}>
                                    All accounts, general intangibles, instruments, rents, monies, payments and all other rights
                                    existing
                                    out of a sale, lease, consignment, or other disposition of any of the property described in
                                    this collateral
                                    section.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant={"body2"}>
                                    All proceeds (including insurance proceeds) from the sale, destruction, loss or other
                                    disposition of any of
                                    the property described in this collateral section and sums due from a third party who has
                                    damaged or
                                    destroyed the collateral or from that party's insurer, whether due to judgment, settlement
                                    or other
                                    processes.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant={"body2"}>
                                    All records and data relating to any of the property described in this collateral section,
                                    whether in the
                                    form of a writing, photograph, microfilm, microfiche or electronic image, together with all
                                    of
                                    Customer/Debtor's right, title and interest in and to all computer software required to
                                    utilize, create,
                                    maintain, and process any such records or data on electronic media.
                                </Typography>
                            </li>
                        </ol>
                        <Typography className="body-paragraph-header" variant={"subtitle1"}>Creation Of Security
                            Interest</Typography>
                        <Typography variant={"body2"}>
                            Pursuant to the provisions of the Idaho Uniform Commercial Code, Customer/Debtor grants to Secured
                            Party a
                            security interest in the collateral described above to secure the payment or performance of
                            Customer/Debtor's
                            obligations to Secured Party described in Section Three.
                        </Typography>
                        <Typography className="body-paragraph-header" variant={"subtitle1"}>Obligations Secured By This
                            Agreement</Typography>
                        <Typography variant={"body2"}>
                            The obligations secured by this agreement are:
                        </Typography>
                        <ol type="A">
                            <li>
                                <Typography variant={"body2"}>
                                    Payment and performance of all open accounts and existing and future obligations including
                                    renewals
                                    and extensions of the same, and any additional loans and advances made in the future by
                                    Secured Party
                                    to Customer/Debtor including those arising under this agreement; and
                                </Typography>
                            </li>
                            <li>
                                <Typography variant={"body2"}>
                                    The expenses, including attorney fees and legal expenses, incurred or paid by Secured Party
                                    in the preservation
                                    or enforcement of the rights of Secured Party, or the obligations of Customer/Debtor under
                                    this agreement,
                                    including such expenses incurred by Secured Party in performing for the account of
                                    Customer/Debtor or any
                                    obligation of Customer/Debtor.
                                </Typography>
                            </li>
                        </ol>
                        <Typography className="body-paragraph-header" variant={"subtitle1"}>Collateral-Location</Typography>
                        <Typography variant={"body2"}>
                            Except on the prior written consent of Secured Party, the collateral shall be kept and maintained at
                            the location
                            specified above as the location of the collateral.
                        </Typography>
                        <Typography className="body-paragraph-header" variant={"subtitle1"}>Possession Of Collateral</Typography>
                        <Typography variant={"body2"}>
                            On default under this agreement or under any obligation secured by this agreement, or if at any time
                            the Secured Party
                            believes that the collateral is in jeopardy, or if Secured Party otherwise deems itself insecure,
                            Secured Party may,
                            without notice to Customer/Debtor, take possession of the collateral and may enter and remain on the
                            premises for the
                            purposes of this agreement.
                        </Typography>
                        <Typography className="body-paragraph-header" variant={"subtitle1"}>Acceleration On Default</Typography>
                        <Typography variant={"body2"}>
                            On default under this agreement or under any obligation secured by this agreement, or whenever
                            Secured Party deems
                            itself insecure, Secured Party may, without notice to Customer/Debtor, accelerate the payment or
                            performance of any or
                            all of the obligations of Customer/Debtor under this agreement.
                        </Typography>
                        <Typography className="body-paragraph-header" variant={"subtitle1"}>Use And Operation Of Collateral By Secured Party</Typography>
                        <Typography variant={"body2"}>
                            Whenever the collateral is in the possession of Secured Party, Secured Party may use, operate and
                            consume the
                            collateral as appropriate for the purpose of performing the obligations of Customer/Debtor with
                            respect to the same.
                        </Typography>
                        <Typography className="body-paragraph-header" variant={"subtitle1"}>Disposition Of
                            Collateral/Default</Typography>
                        <Typography variant={"body2"}>
                            Upon default Secured Party shall have all remedies allowed under the Idaho Uniform Commercial Code
                            and as set
                            out herein. It is agreed that public or private sales, for cash or on credit, to a wholesaler or
                            retailer or user of
                            collateral of the types subject to this security agreement, or at public auction, are all
                            commercially reasonable,
                            since differences in the sales prices generally realized in the different kinds of sales are
                            ordinarily offset by the
                            differences in the costs and credit risks of such sales.
                        </Typography>
                        <Typography className="body-paragraph-header" variant={"subtitle1"}>Power Of Attorney</Typography>
                        <Typography variant={"body2"}>
                            Customer/Debtor appoints Secured Party the attorney-in-fact of Customer/Debtor to prepare, sign and
                            file or record,
                            for Customer/Debtor in the name of Customer/Debtor, any financing statements, applications for
                            registration and like
                            papers, and to take any other action deemed by Secured Party necessary or desirable in order to
                            perfect security
                            interests of Secured Party under this agreement, and to perform any obligation of Customer/Debtor,
                            at the expense of
                            Customer/Debtor, but without obligation to do so.
                        </Typography>
                        <Typography className="body-paragraph-header" variant={"subtitle1"}>Choice Of Venue</Typography>
                        <Typography variant={"body2"}>
                            If there is a lawsuit, Customer/Debtor agrees to, upon Secured Party's request, to submit to the
                            jurisdiction of the
                            courts of Idaho County, State of Idaho, and this agreement will also be interpreted according to the
                            laws of the State
                            of Idaho.
                        </Typography>
                    </ScrollPane>
                    <FormControlLabel
                        style={{marginTop: 10, marginLeft: 0}}
                        control={<Checkbox checked={props.creditApplicationData.hasAcceptedSecurityAgreement}
                                           onChange={(event) => {
                                               props.directDataUpdate("hasAcceptedSecurityAgreement", event.target.checked)
                                           }}/>}
                        label="I agree to the Security Agreement"
                    />
                </AccordionDetails>
            </Accordion>
        </>
    );
}

export default CreditSecurityAgreement;