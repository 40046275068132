import { TextField,Button, Container, Typography, Snackbar, Alert, AlertColor } from "@mui/material"
import axios from "axios"
import {useState, useContext} from 'react'
import { AuthContext } from "../../providers/AuthProvider"
import { StylesContext } from "../../providers/StylesProvider";
import XFormData from '../../Utilities/XFormData';



export const ReachOutForm = () => {

    const [comment, setComment] = useState<string | undefined>()
    const [toastMessage, setToastMessage] = useState<string | undefined>("")
    const [open, setOpen] = useState(false)
    const [toastSeverity, setToastSeverity] = useState<AlertColor | undefined>()

    const {user} = useContext(AuthContext)
    const {navBarHeight} = useContext(StylesContext)


    const config = {
        headers: {
          'authorization': localStorage.getItem("token"),
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }

    const handleSubmit = async (e: any) => {

        
        e.preventDefault()
        try {

            const xFormData = new XFormData()

            const data = xFormData.format({
                comment: comment,
                customerName: user?.customer?.fullName,
                companyName: user?.customer?.entityName,
            })

            await axios.post('/api/reachouts',data, config) 
            setToastMessage("Your comment was successfully submitted")
            setComment("")
            setToastSeverity("success")
            setOpen(true)

        } catch (error) {
            setToastMessage("There's been an error, please try again later")
            setToastSeverity("error")
            setOpen(true)
        }
    }

    const handleClose = () =>{
        setOpen(false)
    }

    const renderToast = () => {
        return (
          <Snackbar autoHideDuration={2000} open={open} >
            <Alert onClose={handleClose} severity={toastSeverity} style={{ width: '100%' }}>
              {toastMessage}
            </Alert>
          </Snackbar>
        )
      }

return (
    <>
    <Container style={{paddingTop: navBarHeight}}>
        <Typography variant="h6">
            Reach Out Submittal Form
        </Typography>
        <form style={{display: "flex", flexDirection: "column"}} onSubmit={handleSubmit}>
            <hr></hr>
            <TextField 
                style={{marginLeft:10, marginTop:10, width: 270 }} 
                placeholder="Reach Out/Action/Comment" 
                multiline
                rows={6}
                variant="outlined"
                fullWidth
                onChange = {(e)=>setComment(e.target.value)}
                value={comment}
                />
            <div style={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                <Button type="submit" style={{marginLeft: 10, marginTop: 20, width: 270}} variant="contained" color="primary">Submit Reach Out!</Button>
            </div>
        </form>
        {renderToast()}
    </Container>
    </>
)
}