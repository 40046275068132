import {AddressType} from "../../../types/CardDataType";

export type JobLineApplicationData = {
    jobName: string;
    jobPhysicalAddress: AddressType;
    jobEstimatedMaterialCost: number;
    jobBidNumber: string; //PO Number
    scrNumber: string;

    propertyOwnerName: string;
    propertyOwnerPhoneNumber: string;
    propertyOwnerPhysicalAddress: AddressType;

    generalContractorName: string;
    generalContractorPhoneNumber: string;
    generalContractorPhysicalAddress: AddressType;

    dateOfApplication: Date;
    digitallySigned: boolean;
    signedBy: string;
}

export const DEFAULT_JOB_LINE_APPLICATION: JobLineApplicationData = {
    jobName: "",
    jobPhysicalAddress: {
        streetAddress: "",
        city: "",
        region: "",
        postalCode: "",
        country: "US"
    } as AddressType,
    jobEstimatedMaterialCost: 0,
    jobBidNumber: "",
    scrNumber: "",

    propertyOwnerName: "",
    propertyOwnerPhoneNumber: "",
    propertyOwnerPhysicalAddress: {
        streetAddress: "",
        city: "",
        region: "",
        postalCode: "",
        country: "US"
    } as AddressType,

    generalContractorName: "",
    generalContractorPhoneNumber: "",
    generalContractorPhysicalAddress: {
        streetAddress: "",
        city: "",
        region: "",
        postalCode: "",
        country: "US"
    } as AddressType,

    dateOfApplication: new Date(),
    digitallySigned: false,
    signedBy: ""
}