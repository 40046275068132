import {
  Container,
} from "@mui/material";
import React from "react";
import { RouteComponentProps } from "react-router";
import AccountInfoCard from "../guiComponents/AccountInfoCard";

const User: React.FC<RouteComponentProps> = (props) => {
  //TODO: do we care?
  //  const handleClickAddReachOut = () => {
  //     history.push("/user/reachout");
  //   };
  // const addReachOutButton = () => {
  //   return (
  //     <Button onClick={handleClickAddReachOut}>
  //       <Icon sx={{ color: green[500] }}>add_circle</Icon>
  //     </Button>
  //   );
  // };

  return (
      <Container style={{paddingTop: "90px"}}>
        <div>
            <AccountInfoCard/>
        </div>
      </Container>
  );
};

export default User;
