import {camelCaseToSplitWords, FIELD_TYPE, ValidationRule} from "./ValidationRule";

class IsEmailRule implements ValidationRule {
    type = FIELD_TYPE;

    isValid(field: any): boolean {
        return field.includes("@");
    }

    getErrorMessage(fieldName: string): string {
        const name = camelCaseToSplitWords(fieldName);
        return `${name} must be a valid email address`;
    }
}

export default IsEmailRule;