import React, {useContext, useState} from 'react';
import NewOrExistingCustomer from "./NewOrExistingCustomer";
import {Button, Container, Typography} from "@mui/material";
import {StylesContext} from "../../../providers/StylesProvider";
import BasicUserForm from "./BasicUserForm";
import {AddressType} from "../../../types/CardDataType";
import BasicSupplementalInfo from "./BasicSupplementalInfo";
import BusinessCustomerSupplementalInfoOne from "./BusinessCustomerSupplementalOneInfo";
import BusinessCustomerSupplementalInfoTwo from "./BusinessCustomerSupllementalInfoTwo";
import OptionalCreditApp from "./OptionalCreditApp";
import OptionalJobLineApp from "./OptionalJobLineApp";
import RegistrationBreadcrumb from "./RegistrationBreadcrumb";
import {CreditApplicationData} from "./CreditApplicationData";
import { JobLineApplicationData } from './JobLineApplicationData';
import ReviewInformation from "./ReviewInformation";
import {useHistory} from "react-router";
import treesourceLogo from "../../Loaders/graphic-test3-01.svg";
import NewUserDataValidator from "./validators/NewUserDataValidator";

// Define a type for the form data
export type NewUserData = {
    [key: string]: any
    newCustomer: boolean;

    //basic user info
    firstName: string;
    lastName: string;
    email: string;
    confirmEmail: string;
    phoneNumber: string;
    role: string;
    preferredBranch: string;
    hasAcceptedTerms: boolean;

    //optionally empty for homeowners
    companyName: string;

    //new customer info
    isHomeowner: boolean | undefined;
    billingAddress: AddressType;
    shippingAddress: AddressType;
    preferredContactMethod: string;
    referralMethod: string;

    //only for businesses
    EIN: string;
    businessLegalName: string;
    tradeName: string;
    typeOfBusiness: string;
    businessWebsite: string;
    paymentContactName: string;
    paymentContactTitle: string;
    paymentContactPhone: string;
    paymentContactEmail: string;

    //federalTaxID: string; //PII - do not store - move to credit app - is this the same or diff from sate tax id?
    dateBusinessEstablished: string;
    numberOfCrews?: number;
    averageNumberOfProjectsAnnually?: number;
    averageJobSize?: number;
    geographiesServed: string;
    averageSeasonStart: string;
    averageSeasonEnd: string;
    customerTypes: string[];
    services: string[];
    holdPlantMaterialAtYard: boolean;
    acceptShipments: boolean;

    optionalCreditApp?: CreditApplicationData;
    optionalJobLineApp?: JobLineApplicationData;
};

const NEW_USER: NewUserData = {
    newCustomer: false,

    // Initialize basic user info
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    phoneNumber: '',
    preferredBranch: '',
    hasAcceptedTerms: false,

    companyName: '',
    role: '',

    // Initialize new customer info
    isHomeowner: true, //default to homeowner (they get worse pricing ;) )
    billingAddress: {
        region: '',
        postalCode: '',
        streetAddress: '',
        country: '',
        city: ''
    },
    shippingAddress: {
        region: '',
        postalCode: '',
        streetAddress: '',
        country: '',
        city: ''
    },
    preferredContactMethod: '',
    referralMethod: '',

    // Initialize business info
    EIN: '',
    businessLegalName: '',
    tradeName: '',
    typeOfBusiness: '',
    businessWebsite: '',
    paymentContactName: '',
    paymentContactTitle: '',
    paymentContactPhone: '',
    paymentContactEmail: '',
    dateBusinessEstablished: '',

    // federalTaxID: '',
    numberOfCrews: undefined,
    averageNumberOfProjectsAnnually: undefined,
    averageJobSize: undefined,
    averageSeasonStart: '',
    averageSeasonEnd: '',
    holdPlantMaterialAtYard: false,
    acceptShipments: false,
    customerTypes: [],
    services: [],
    geographiesServed: '',

    optionalCreditApp: undefined,
    optionalJobLineApp: undefined
}

export const INITIAL_STEP = 0;

export const EXISTING_CUSTOMER_USER_DATA = 10;

export const NEW_CUSTOMER_USER_DATA = 20;
export const NEW_CUSTOMER_BASIC_INFO = 21;
export const NEW_CUSTOMER_COMPANY_INFO_ONE = 22;
export const NEW_CUSTOMER_COMPANY_INFO_TWO = 23;
export const NEW_CUSTOMER_CREDIT_APP = 24;
export const NEW_CUSTOMER_JOB_LINE_APP = 25;
export const NEW_CUSTOMER_FINISHING_STEP = 26;

const Register: React.FC = () => {
    //context
    const { navBarHeight, isDesktop } = useContext(StylesContext);
    const history = useHistory();

    //local state
    const [currentStep, setCurrentStep] = useState(0);
    const [userData, setUserData] = useState<NewUserData>(NEW_USER);
    const [submitted, setSubmitted] = useState(false);
    const [validator] = useState<any>(new NewUserDataValidator());

    // Function to handle input change
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setUserData(prevState => ({
          ...prevState,
          [name]: value,
        }));
    };

    const directDataUpdate = (fieldName: string, value: string) => {
        setUserData(prevState => ({
            ...prevState,
            [fieldName]: value,
        }));
    }

    const isInvalid = (fieldName: string) => {
        return getErrorMessage(fieldName) !== '';
    }

    const userDataValid = () => {
        const errors = validator.validateAll(userData); //map of fieldName => errorMessage

        if(currentStep === NEW_CUSTOMER_USER_DATA && errors.billingAddress) {
            delete errors.billingAddress //if we're on the first step, we don't need to validate the address - yet
        }

        return Object.keys(errors).length === 0;
    }

    const getErrorMessage = (fieldName: string) => {
        return validator.validateFieldIfTouched(userData, fieldName); //returns the error message or empty
    }

    // Function to navigate to the next step
    const nextStep = () => {
        if(currentStep === NEW_CUSTOMER_BASIC_INFO && userData.isHomeowner) {
            setCurrentStep(NEW_CUSTOMER_FINISHING_STEP);
        } else {
            setCurrentStep(currentStep + 1);
        }

        window.scrollTo(0,0)
    };

    const isFilledOut = (address: AddressType) => {
        return address.city !== '' || address.country !== '' || address.postalCode !== '' || address.region !== '' || address.streetAddress !== '';
    }

    const postNewUserData = async (userData: NewUserData) => {
        try {
            const shippingAddressIsFilledOut = isFilledOut(userData.shippingAddress);
            const toSubmit = {...userData, shippingAddress: shippingAddressIsFilledOut ? userData.shippingAddress : userData.billingAddress};

            setSubmitted(true);

            const response = await fetch('/api/user/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(toSubmit),
            });

            if (!response.ok) {
                setSubmitted(false);
                // will be important for handling server side validation errors
                throw new Error(`Error: ${response.statusText}`);
            } else {
                history.push("/user/sendlink");
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error posting new user data:', error);
            throw error;
        }
    };


    // Function to navigate to the previous step
    const prevStep = () => {
        setCurrentStep((prevStep) => {
            if(prevStep === NEW_CUSTOMER_USER_DATA || prevStep === EXISTING_CUSTOMER_USER_DATA) {
                setUserData({...userData, newCustomer: false});
                return 0;
            } else if (prevStep === NEW_CUSTOMER_FINISHING_STEP && userData.isHomeowner) {
                return NEW_CUSTOMER_BASIC_INFO;
            } else {
                return currentStep - 1
            }
        });
    };


    // Function to render the current step form fields
    const renderStep = () => {
        if(submitted) {
            return (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    margin: 70
                }}>
                    <img style={{width: 200, marginBottom: 30}} src={treesourceLogo} alt="treesource loading spinner"/>
                    <Typography variant={"subtitle1"}>Great, thanks! We're registering {userData.firstName} {userData.lastName}...</Typography>
                </div>
            )
        }

        switch (currentStep) {
            case 0:
                return <NewOrExistingCustomer userData={userData} handleNewUserIdentification={(isNew: boolean, isHomeowner: boolean) => {
                    setUserData({...userData, newCustomer: isNew, isHomeowner: isHomeowner});

                    if (isNew) {
                        setCurrentStep(NEW_CUSTOMER_USER_DATA);
                    } else {
                        setCurrentStep(EXISTING_CUSTOMER_USER_DATA);
                    }

                }}/>;
          //Existing customer steps 10 - 19
          //New customer steps 20 - 29
            case EXISTING_CUSTOMER_USER_DATA:
                return <BasicUserForm userData={userData}
                                      updateUserData={handleChange}
                                      isInvalid={isInvalid}
                                      getErrorMessage={getErrorMessage}
                                      directDataUpdate={directDataUpdate} />

            case NEW_CUSTOMER_USER_DATA:
                return <BasicUserForm userData={userData}
                                      updateUserData={handleChange}
                                      isInvalid={isInvalid}
                                      getErrorMessage={getErrorMessage}
                                      directDataUpdate={directDataUpdate} />
            case NEW_CUSTOMER_BASIC_INFO:
                return <BasicSupplementalInfo userData={userData}
                                              updateUserData={handleChange}
                                              isInvalid={isInvalid}
                                              getErrorMessage={getErrorMessage}
                                              directDataUpdate={directDataUpdate} />
            case NEW_CUSTOMER_COMPANY_INFO_ONE:
                return <BusinessCustomerSupplementalInfoOne userData={userData}
                                                            updateUserData={handleChange}
                                                            isInvalid={isInvalid}
                                                            getErrorMessage={getErrorMessage}
                                                            directDataUpdate={directDataUpdate} />
            case NEW_CUSTOMER_COMPANY_INFO_TWO:
                return <BusinessCustomerSupplementalInfoTwo userData={userData}
                                                            updateUserData={handleChange}
                                                            isInvalid={isInvalid}
                                                            getErrorMessage={getErrorMessage}
                                                            directDataUpdate={directDataUpdate} />
            case NEW_CUSTOMER_CREDIT_APP:
                return <OptionalCreditApp userData={userData} progressStep={nextStep} regressStep={prevStep} directDataUpdate={directDataUpdate} />
            case NEW_CUSTOMER_JOB_LINE_APP:
                return <OptionalJobLineApp userData={userData} progressStep={nextStep} regressStep={prevStep} directDataUpdate={directDataUpdate} />
          default:
            return (
                <ReviewInformation userData={userData}
                                   setUserData={setUserData}
                                   getErrorMessage={getErrorMessage}
                                   userDataIsValid={userDataValid()}
                                   submitFunction={() => {
                                        postNewUserData(userData);
                                   }}
                />
            );
        }
    };

    const finishingStep = (step: number) => {
        return step === NEW_CUSTOMER_FINISHING_STEP || step === EXISTING_CUSTOMER_USER_DATA;
    }

    const stepRendersItsOwnControls = (step: number) => {
        return step === NEW_CUSTOMER_CREDIT_APP || step === NEW_CUSTOMER_JOB_LINE_APP || INITIAL_STEP;
    }

    const renderControls = () => {
        if(stepRendersItsOwnControls(currentStep) || submitted) {
            return <></>
        }

        return (
            <div style={{margin: 10}}>
                {currentStep > 0 && <Button variant="contained" color="primary" onClick={prevStep}>Back</Button>}
                {!finishingStep(currentStep) && currentStep > 0 &&
                    <Button disabled={!userDataValid()} style={{marginLeft: 10}} variant="contained" color="primary" onClick={nextStep}>Next</Button>}
                {finishingStep(currentStep) && currentStep > 0 &&
                    <Button disabled={!userDataValid()} style={{marginLeft: 10}} variant="contained" color="primary" onClick={() => postNewUserData(userData)}>Submit</Button>}
            </div>
        );
    }

    return (
        <Container style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: navBarHeight
        }}>
            <h1>Register</h1>
            <RegistrationBreadcrumb valid={userDataValid()}
                                    userData={userData}
                                    currentStep={currentStep}
                                    setCurrentStep={setCurrentStep}/>
            {renderStep()}
            {renderControls()}

            {/*blank space so that the "message" chatra tool can sit below our controls on mobile. On desktop, this just adds a little visual space below our buttons */}
            <div style={{margin: isDesktop ? 15 : 40}}></div>
        </Container>
    );

};

export default Register;