import { FIELD_TYPE, ValidationRule} from "../ValidationRule";
import CreditApplicationValidator from "./CreditApplicationValidator";

class IsValidCreditApplication implements ValidationRule {
    type = FIELD_TYPE;

    validator = new CreditApplicationValidator();

    isValid(field: any): boolean {
        const validationErrors = this.validator.validateAll(field);

        return Object.keys(validationErrors).length === 0;
    }

    getErrorMessage(fieldName: string): string {
        return `If applying for credit, all fields must be valid.`;
    }
}

export default IsValidCreditApplication;