import React, {useContext, useEffect} from "react";
import {AuthContext} from "../../../providers/AuthProvider";
import {ListItem, ListItemText} from "@mui/material";
import {CustomerContext} from "../../../providers/CustomerProvider";

export type LoyaltyPointCounterProps = {
    fullScreen: boolean;
}
export const LoyaltyPointCounter = (props: LoyaltyPointCounterProps) => {
    //context
    const { fetchLoyaltyPoints } = useContext(CustomerContext)

    const authContext = useContext(AuthContext);
    const selectedCustomerName = authContext?.user?.customer?.fullName;
    const inLoyaltyProgram : boolean | undefined = authContext?.user?.customer?.inLoyaltyProgram;

    //local state
    const [points, setPoints] = React.useState(0);

    useEffect(() => {
        if(selectedCustomerName && fetchLoyaltyPoints) {
            fetchLoyaltyPoints(selectedCustomerName, inLoyaltyProgram)
                .then((data) => {
                    setPoints(data);
                })
        }
    }, [selectedCustomerName, inLoyaltyProgram, fetchLoyaltyPoints]);

    if(authContext?.isConfiguredUser && authContext?.isConfiguredUser() && selectedCustomerName) {
        if (inLoyaltyProgram) {
            if (props.fullScreen) {
                return <div style={{marginLeft: "15px"}}>
                    <h3 style={{marginTop: "13px", marginRight: 15, color: "#8DC63F"}}>Loyalty
                        Points: {points.toLocaleString()}</h3>
                </div>
            } else {
                return <ListItem>
                    <ListItemText primary={"Loyalty Points: " + points.toLocaleString()}/>
                </ListItem>
            }
        }
    }
    return <></>
}
