import { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import { ProtectedRouteProps } from "./ProtectedRoute";

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { isTokenExpired, trustedInternalRole } = useContext(AuthContext);

  const isExpired = () => {
    if (isTokenExpired) {
      return isTokenExpired();
    }
    return true;
  };

  let redirectPath = "";
  if (!props.isAuthenticated || isExpired()) {
    redirectPath = props.authenticationPath;
  } else if (!trustedInternalRole) {
    redirectPath = "/NoMatch";
  }

  if (redirectPath) {
    const renderComponent = () => (
      <Redirect
        to={{
          pathname: redirectPath,
          state: { from: props.location?.pathname },
        }}
        from={props.location?.pathname}
      />
    );
    return <Route {...props} component={renderComponent} />;
  } else {
    return <Route {...props} />;
  }
};

export default ProtectedRoute;
