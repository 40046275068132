import {Chip, FormControl, Input, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

export type MultiSelectProps = {
    id: string;
    onChange: (event: any) => void;
    options: string[];
    values: string[];
    label: string;
    formControlStyle: React.CSSProperties;
}

const MultiSelect: React.FC<MultiSelectProps> = (props: MultiSelectProps) => {
    return (
        <FormControl style={props.formControlStyle} variant="standard">
            <InputLabel style={{ marginLeft: '0px' }} >{props.label}</InputLabel>
            <Select
                variant="standard"
                id={props.id}
                onChange={props.onChange}
                value={props.values}
                multiple
                input={<Input style={{ transform: "translate(0px, -2px)" }}/>}
                renderValue={(selected: any) => (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: 0.5,
                        }}
                    >
                        {selected.map((value: string) => (
                            <Chip key={value} label={value} />
                        ))}
                    </div>
                )}
            >
                {props.options?.map((option, index) => {
                    return (
                        <MenuItem
                            style={{ whiteSpace: "unset", wordBreak: "break-all" }}
                            key={index}
                            value={option}
                        >
                            {option}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export default MultiSelect;