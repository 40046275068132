import React, { useEffect, useRef }  from "react";
import {Button} from "@mui/material";

const CameraWidget = (props) => {
    const INITIAL_STATE = "Take new picture";
    const SECOND_STATE = "Capture image";
    const THIRD_STATE = "Retake picture";

    const videoRef = useRef();
    const canvasRef = useRef();
    const higherResCanvasRef = useRef();

    const setPhotoBlob = props.setPhotoBlob;
    const widgetState = props.widgetState;
    const setWidgetState = props.setWidgetState;

    useEffect(() => {
        if(widgetState === INITIAL_STATE) {
            resetWidget();
        }
    },
        // eslint-disable-next-line
    [widgetState]);

    const resetWidget = () => {
        if(canvasRef) {
            canvasRef.current.hidden = false;
            videoRef.current.hidden = true;
            drawImageFromUrl(
                "https://res.cloudinary.com/treesource-systems/image/upload/v1706579588/tree_source_logo_300w.png",
                canvasRef,
                300,
                400);
        }
    }

    const initPhotoBlob = () => {
        higherResCanvasRef.current.toBlob((blob) => {
            setPhotoBlob(blob);
        }, "image/png");
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if(!file) {
            return;
        }

        const url = URL.createObjectURL(file);

        drawImageFromUrl(url, canvasRef, 300, 400, null);
        drawImageFromUrl(url, higherResCanvasRef, 900, 1200, initPhotoBlob);

        setWidgetState(THIRD_STATE);
    }

    const drawImageFromUrl = (url, canvasRef, width, height, callback) => {
        const img = new Image();
        img.onload = () => {
            const context = canvasRef.current.getContext('2d');
            context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height); // Clear the canvas
            context.drawImage(img, 0, 0, width, height);

            if(callback) {
                callback();
            }
        };
        img.src = url;
        canvasRef.current.width = width;
        canvasRef.current.height = height;
    }

    const initializeCamera = () => {
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: "environment",
                    width: { ideal: 900 }, // do we have good data plans? can bump to 4096 x 2160 test file was 1mb though
                    height: { ideal: 1200 }
                }
            })
                .then((stream) => {
                    if (videoRef.current) {
                        videoRef.current.srcObject = stream;

                        canvasRef.current.hidden = true;
                        videoRef.current.hidden = false;
                    }
                })
                .catch((err) => {
                    console.log("Something went wrong!", err);
                });

        }
    }

    const captureImage = () => {
        if (canvasRef.current && videoRef.current && higherResCanvasRef.current) {
            canvasRef.current.width = 300;
            canvasRef.current.height = videoRef.current.videoHeight / (videoRef.current.videoWidth / canvasRef.current.width);

            const context = canvasRef.current.getContext('2d');
            context?.drawImage(videoRef.current, 0, 0, canvasRef.current.width, videoRef.current.videoHeight / (videoRef.current.videoWidth / canvasRef.current.width));

            higherResCanvasRef.current.width = videoRef.current.videoWidth;
            higherResCanvasRef.current.height = videoRef.current.videoHeight;

            const hiddenContext = higherResCanvasRef.current.getContext('2d');
            hiddenContext?.drawImage(videoRef.current, 0, 0, higherResCanvasRef.current.width, videoRef.current.videoHeight);

            initPhotoBlob();

            canvasRef.current.hidden = false;
            videoRef.current.hidden = true;

            videoRef.current.srcObject.getVideoTracks().forEach(function(track) {
                track.stop();
            });
        }
    }

    const transitionCamera = () => {
        if(widgetState === INITIAL_STATE) {
            initializeCamera();
            setWidgetState(SECOND_STATE);
            return;
        } else if(widgetState === SECOND_STATE) {
            captureImage();
            setWidgetState(THIRD_STATE);
        } else if(widgetState === THIRD_STATE) {
            initializeCamera()
            setWidgetState(SECOND_STATE);
        }
    };

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <video ref={videoRef} width="300" hidden autoPlay></video>
            <canvas ref={canvasRef} width="300" height="400" hidden></canvas>
            <canvas ref={higherResCanvasRef} width="900" height="1200" hidden></canvas>
            <Button
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    width: "100%",
                    flex: "1",
                }}
                id="useCameraButton"
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => transitionCamera()}
            >
                {widgetState}
            </Button>
            <Button
                style={{
                    marginBottom: 10,
                    width: "100%",
                    flex: "1",
                }}
                id="useFileButton"
                component="label"
                variant="contained"
                color="primary"
                disabled={widgetState === SECOND_STATE}
                onChange={(event) => handleFileUpload(event)}
            >
                Upload From File
                <input
                    type="file"
                    accept="image/*"
                    hidden
                />
            </Button>
        </div>
    );
}

export default CameraWidget;