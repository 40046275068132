import React, {useCallback} from "react";
import {formatCurrencyRoundUp} from "../../../constants/GlobalFunctions";
import { Tooltip, Typography} from "@mui/material";

export type RebateCounterProps = {
    points: number;
}

const RebateCounter: React.FC<RebateCounterProps> = (props: RebateCounterProps) => {
    //local state
    const getRate = useCallback((points: number) => {
        if(points >= 2000000 ) {
            return .025
        } else if (points >= 1500000) {
            return .02
        } else if (points >= 1000000) {
            return .015
        } else if (points >= 500000) {
            return .01
        } else if (points >= 250000) {
            return .005
        } else if (points >= 175000) {
            return .0035
        } else if (points >= 100000) {
            return .0025
        } else if (points >= 50000) {
            return .0015
        } else if (points >= 25000) {
            return .001
        } else {
            return 0
        }
    }, []);

    const { points } = props;
    const rate = getRate(points)
    const rebate = points * rate

    const formattedRebate = formatCurrencyRoundUp(rebate)

    const eligible = rebate > 0
    const message = eligible ? `Estimated value ${formattedRebate}.` : "Eligibility starts at 25,000 points.";

    return <Tooltip
        title={"This starts at 25,000 points and scales to 2.5m! The more you spend, the higher the rate. Ask your rep for details."}
        arrow
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -35],
                        },
                    },
                ],
            },
        }}
    >
        <div style={{marginTop: 10, textAlign: "center"}}>
            <Typography variant={"h6"}>Take A Rebate On Your Account! </Typography>
            <Typography variant={"body1"}>{message}</Typography>
        </div>
    </Tooltip>
}

export default RebateCounter;