import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { ShipmentType } from "../../types/ShipmentType";
import { styled } from "@mui/styles";
import { formatter } from "../../constants/Constants";


const PP = styled('p')({
    padding: "5px"
})

function CustomFooterTotalComponent(selectedOrderData: ShipmentType) {
  return (
    <Box sx={{ padding: "10px", display: "flex" , flexDirection: "row"}}>
        <PP>Subtotal : {formatter.format(selectedOrderData.subTotal)}</PP>
        <PP>Tax : {selectedOrderData?.salesTaxOverride ? formatter.format(selectedOrderData.salesTaxOverride) : formatter.format(selectedOrderData?.salesTaxSuggested)}</PP>
        <PP>Freight : {formatter.format(selectedOrderData.shipmentFreightTotal)}</PP>
        <PP>Total : {formatter.format(selectedOrderData.grandTotal)}</PP>
        
        </Box>
  );
}

CustomFooterTotalComponent.propTypes = {
  total: PropTypes.number
};

export { CustomFooterTotalComponent };