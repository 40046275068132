import NotEmptyRule from "../NotEmptyRule";
import MustBeTrueRule from "../MustBeTrueRule";
import {CreditApplicationData} from "../../CreditApplicationData";
import MustHaveFourReferences from "./MustHaveFourReferences";
import IfMeetsOrExceedsRule from "./IfMeetsOrExceeds";
import IsValidBankReference from "./IsValidBankReference";
import BaseValidator from "../BaseValidator";
import ForEach from "./ForEach";
import IsValidCreditReference from "./IsValidCreditReference";

class CreditApplicationValidator extends BaseValidator<CreditApplicationData> {
    constructor() {
        super();
        this.dataValidations = {
            creditRequested: [new NotEmptyRule()],
            hasAcceptedStandardTerms: [new MustBeTrueRule()],
            hasAcceptedPersonalGuaranty: [new IfMeetsOrExceedsRule("creditRequested", 15000, new MustBeTrueRule(), "hasAcceptedPersonalGuaranty")],
            hasAcceptedSecurityAgreement: [new IfMeetsOrExceedsRule("creditRequested", 20000, new MustBeTrueRule(), "hasAcceptedSecurityAgreement")],
            references: [new MustHaveFourReferences(), new ForEach(new IsValidCreditReference())],
            bankReference: [new IsValidBankReference("bankReference")],
        };
    }
}

export default CreditApplicationValidator;