
class XFormData{


    format(someObject: any) {
        return(
        Object.keys(someObject).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(someObject[k])).join('&')
        )
    }
}

export default XFormData