import { Paper, Typography, Button,Input, Dialog } from "@mui/material"
import { styled } from "@mui/styles";
import { GridRowModel } from "@mui/x-data-grid";
import { AxiosResponse } from "axios";

import { Dispatch, SetStateAction } from "react"


const PREFIX = 'UpdateItemDialog';

const classes = {
    sideBarMain: `${PREFIX}-sideBarMain`,
};

const StyledPaper = styled(Paper)({
        [`&.${classes.sideBarMain}`]: {
            padding: "20px",
            margin: "5px",
        }
    });


type props = {
    open: boolean,
    setEditItem: Dispatch<SetStateAction<boolean>>,
    handleUpdateItem: (e: any, row: GridRowModel) => Promise<void>,
    row: GridRowModel,
    setRow: Dispatch<any>,
    error: boolean,
    handleRemoveLineItem: (row: GridRowModel) => Promise<AxiosResponse<any> | undefined>,
    errorMessage: string,
    setErrorMessage: (value: SetStateAction<string>) => void
}

export const UpdateItemDialog = (props: props) => {

    const { open, setEditItem, handleUpdateItem, row, setRow, error, handleRemoveLineItem, errorMessage, setErrorMessage } = props

    const handleClose = () => {
        setEditItem(false)
        setErrorMessage("")
    }
    return(
        <Dialog open={open} onClose={()=>handleClose()}>
            <StyledPaper className={classes.sideBarMain} elevation={0} variant="outlined">
            <form style={{display: "flex", flexDirection: "column"}} onSubmit={(e)=>handleUpdateItem(e,row)}>
                <Typography variant="h6">
                    Update Item 
                </Typography>
                <Typography variant="body2">
                {row?.commonName ? row.commonName : ""}
                </Typography>
                <hr></hr>
                
                <Input type="number" error={error} autoFocus style={{marginLeft:10, marginTop:10, width: 270 }} onChange={e => setRow({...row, sourceItemQuantity: e.target.value})} placeholder={row?.sourceItemQuantity ? row.sourceItemQuantity.toString() : "Update qty"} id="quantity"/>
                <Typography style={{color: 'red', textAlign: 'center'}}>{errorMessage}</Typography>

                <Input type="string" error={error} autoFocus style={{marginLeft:10, marginTop:10, width: 270 }} onChange={e => setRow({...row, comment: e.target.value})} placeholder={row?.comment !== undefined && row?.comment !== "undefined" && row?.comment !== " " ? row.comment : "Add a comment"} id="comment" />

                <div style={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                    <Button type="submit" style={{marginLeft: 10, marginTop: 20, width: 270}} variant="contained" color="primary">Update line item</Button>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                    <Button onClick={()=>handleRemoveLineItem(row)} style={{marginLeft: 10, marginTop: 20, width: 270}} variant="contained" color="error">Remove line item</Button>
                </div>
            </form>
                
        </StyledPaper>
        </Dialog>
    )
}