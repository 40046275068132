import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import {CreditApplicationData} from "./CreditApplicationData";
import {ExpandMore} from "@mui/icons-material";

export type CreditPersonalGuarantyProps = {
    application: CreditApplicationData
    directDataUpdate: (fieldName: string, value: any) => void;
}

const CreditPersonalGuaranty: React.FC<CreditPersonalGuarantyProps> = (props: CreditPersonalGuarantyProps) => {

    //just a quick note to explain why this exists:
    //customer objs don't necessarily have the address broken into parts. They do appear to have the entire
    //thing stored in billing address.... which we store in street address when creating an application for an existing customer
    //when a new user/customer is registering, we have the entire address broken into nice parts which we need to concatenate
    const formatAddress = (streetAddress: string, city?: string, region?: string, postalCode?: string): string => {
        let address = streetAddress;
        if (city) address += `, ${city}`;
        if (region) address += `, ${region}`;
        if (postalCode) address += `, ${postalCode}`;
        return address;
    };

    const address = formatAddress(
        props.application.userStreetAddress.streetAddress ?? "",
        props.application.userStreetAddress.city,
        props.application.userStreetAddress.region,
        props.application.userStreetAddress.postalCode
    );
    return <>
        <Accordion style={{width: "100%"}} >
            <AccordionSummary expandIcon={<ExpandMore/>}  >
                <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Personal Guaranty - Required Over $15,000</Typography>
            </AccordionSummary>
            <AccordionDetails>
                    <div className={"body-paragraph"}>
                        <Typography variant={"body2"}>
                            In order to induce Treesource L.L.C. to sell materials and extend credit to:
                        </Typography>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
                        <TextField
                            style={{margin: 10, width: "48%"}}
                            required
                            variant="standard"
                            name="companyName"
                            label="Company Name"
                            disabled={true}
                            value={props.application.companyName}
                        />
                        <TextField
                            style={{margin: 10, width: "48%"}}
                            required
                            variant="standard"
                            name="address"
                            label="Address"
                            disabled={true}
                            value={address}
                        />
                    </div>
                    <div className={"body-paragraph"}>
                        <Typography variant={"body2"}>
                            And for good and valued consideration, I/we the undersigned, hereby personally guarantee the payment of all such sums of money as may now be
                            due or may at any time hereafter become due to Treesource L.L.C.
                            This is an absolute, continuing, unlimited guaranty, if the primary debtor does not pay, the obligation becomes the immediate liability of the
                            guarantors, jointly and severally.
                            All payments shall be made to Treesource L.L.C. in their offices at 20851 Bingham Street, Rupert, ID 83350. The extension of time of payment,
                            the acceptance or release of notes, drafts, other guaranties, or any security, shall in no way weaken or impair the validity of the guaranty. This
                            guaranty will continue to be in effect until revoked in writing. The undersigned further agrees that its liability for payment will include all costs
                            of collection, including reasonable attorney’s fees, together with interest at the rate of 18% per annum pursuant to terms of sale, until paid.
                            I/We or either of us agrees that this contract is performable in Idaho County, Idaho, and waive the right to be sued elsewhere.
                        </Typography>
                    </div>

                <FormControlLabel
                    style={{marginTop: 10, marginLeft: 0}}
                    control={<Checkbox checked={props.application.hasAcceptedPersonalGuaranty}
                                       onChange={(event) => {
                                           props.directDataUpdate("hasAcceptedPersonalGuaranty", event.target.checked)
                                       }}/>}
                    label={`I, ${props.application.userFirstName} ${props.application.userLastName}, agree to the terms of the Personal Guaranty.`}
                />
            </AccordionDetails>
        </Accordion>
    </>
}

export default CreditPersonalGuaranty;