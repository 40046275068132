import {useState} from "react"
export const Link = (props: {content: string, style?: {}}) => {

    const [hover, setHover] = useState<boolean>(true)
    const {content,style} = props

    const toggleHover = () => {
        setHover(!hover)
    }

    var linkStyle;
    if (hover) {
      linkStyle = {textDecoration: 'underline', color:"black"}
    } else {
      linkStyle = {textDecoration: 'underline', color:"green", cursor: "pointer"}
    }

    let finalStyle = {...linkStyle,...style}

    return(
        <span style={finalStyle} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>{content}</span>
    )
}