import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { KeyValue } from '../KeyValue';
import {Cloudinary} from "@cloudinary/url-gen";
import {pad} from "@cloudinary/url-gen/actions/resize"


type props = {
    imageLink: string
    header: string,
    subheader: string, 
    size: string, 
    quantity: number,
    quantityOnPO: number
}

const cld = new Cloudinary({
    cloud: {
      cloudName: 'treesource-systems'
    }
  });

export const ProductCard = (props: props) => {
    let publicId = "v1661178206/BandB Trees/default_photo"

    if(props.imageLink !== undefined && props.imageLink !== ""){
        publicId = props.imageLink.split("upload/")[1].split(".")[0].replaceAll('%20'," ")
    } 

    let myImage = cld.image(publicId)
    myImage.resize(pad().width(300).height(400))

    const myImageURL = myImage.toURL()

    const imageBox = () => {
        return(
            <CardMedia
                component = "img"
                height = "400"
                image = { myImageURL }
                alt = "plant picture"
            />
        
        )
    }

    const productBox = () => {
        return (
            <Card sx={{ maxWidth: 345, minWidth: 300 }}>
                <CardHeader
                    title={props.header}
                    id="draggable-dialog-title" style={{ cursor: "move" }}
                />
                {imageBox()}
                <CardContent>
                        {KeyValue("Common Name: ",props.subheader)}
                        {KeyValue("Size: ",props.size)}
                        {KeyValue("Quanity: ",props.quantity.toString())}
                        {KeyValue("Future Quantity: ",props.quantityOnPO.toString())}
                </CardContent>
                
            </Card>
        )
    }

    return (
        <>
            {productBox()}
        </>
    )

}