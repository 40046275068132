import {PaddedCard} from "../LoggedOut/PaddedCard";
import {Button, CardContent, CardHeader, Container, Typography} from "@mui/material";
import JobLineInfoBox from "../guiComponents/JobLineInfoBox";
import JobLineData from "./JobLineData";
import {StylesContext} from "../../providers/StylesProvider";
import React, {useContext} from "react";
import Alert from "@mui/material/Alert";
import JobLineApplicationCard from "../authComponents/registration/JobLineApplicationCard";
import {
    DEFAULT_JOB_LINE_APPLICATION,
    JobLineApplicationData
} from "../authComponents/registration/JobLineApplicationData";
import {AuthContext} from "../../providers/AuthProvider";

export type JobLineInfoCardProps = {

}

const JobLineInfoCard = (props: JobLineInfoCardProps) => {
    const {navBarHeight} = useContext(StylesContext)
    const authContext = useContext(AuthContext);

    const user = authContext.user;
    const customerName = authContext.user?.customer?.fullName ?? "";

    const [applyingForJobLine, setApplyingForJobLine] = React.useState(false);
    const [jobLineApplication, setJobLineApplication] = React.useState({...DEFAULT_JOB_LINE_APPLICATION, signedBy: `${user?.firstName} ${user?.lastName}`});
    const [applicationReceived, setApplicationReceived] = React.useState(false);
    const [jobLineAppValid, setJobLineAppValid] = React.useState(false);

    const postJobLineApplication = async (newJobApp: JobLineApplicationData) => {
        try {
            const encodedCustomerName = encodeURIComponent(customerName);

            const response = await fetch(`/api/customer/${encodedCustomerName}/job-lines`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem("token") ?? undefined,
                } as HeadersInit,
                body: JSON.stringify(newJobApp),

            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            } else {
                setApplicationReceived(true);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error posting new user data:', error);
            throw error;
        }
    };

    const renderThankYou = () => {
        return <Container>
            <div style={{marginBottom: 30}}>
                <h2>Thank You For Your Application</h2>
                <Typography>
                    Your application has been submitted. The accounting team will reach out to you within five business days about your application.
                </Typography>
            </div>
        </Container>
    }

    const renderJobLineInfo = () => {
        return <>
            <PaddedCard>
                <Alert severity="warning">
                    If you recently applied for a job line, it may not show up here yet.
                    The accounting team will reach out to you within five business days about your application.
                </Alert>
            </PaddedCard>
            <PaddedCard style={{marginTop: 20}}>
                <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                    <CardHeader title={"Job Line Information"}/>
                    <div style={{marginRight: 20}}>
                        <Button  color="secondary" variant="contained" onClick={() => setApplyingForJobLine(true)}>
                            Apply For Jobline
                        </Button>
                    </div>
                </div>

                <CardContent>
                    <JobLineData/>
                </CardContent>
            </PaddedCard>
        </>
    }

    const renderJobLineApplication = () => {
        return <PaddedCard>
            <div style={{margin: 10}}>
                <JobLineApplicationCard jobLineApplication={jobLineApplication}
                                        setJobLineApplicationData={setJobLineApplication}
                                        setIsValid={setJobLineAppValid}
                />
                <div style={{width: "100%", display: "flex", justifyContent:"space-between", marginTop: 20, marginBottom: 20}}>
                    <div/>
                    <div>
                        <Button variant="contained" color={"secondary"} onClick={() => setApplyingForJobLine(false)}>Cancel</Button>
                        <Button style={{marginLeft: 10, marginRight: 7}}
                                variant={"contained"}
                                disabled={!jobLineAppValid}
                                onClick={() => postJobLineApplication(jobLineApplication)}
                        >Submit</Button>
                    </div>

                </div>
            </div>

        </PaddedCard>
    }

    const renderBody = () => {
        if(applicationReceived) {
            return renderThankYou();
        } if(applyingForJobLine) {
            return renderJobLineApplication();
        } else {
            return renderJobLineInfo();
        }
    }

    return <>
        <Container style={{paddingTop: navBarHeight}}>
            {!applicationReceived && <JobLineInfoBox/>}
            {renderBody()}
        </Container>

    </>
}

export default JobLineInfoCard;