import React, {useContext, useEffect, useState} from 'react';
import Alert from '@mui/material/Alert';
import {AuthContext} from "../../../providers/AuthProvider";
import {CustomerContext} from "../../../providers/CustomerProvider";
import {PaddedCard} from "../../LoggedOut/PaddedCard";

const UnsyncedPaymentsAlert = () => {
    const [hasUnsyncedPayments, setHasUnsyncedPayments] = useState(false);

    const { user } = useContext(AuthContext);
    const { getParentCustomerName } = useContext(CustomerContext);
    const customerName = user?.customer?.fullName


    useEffect(() => {
        const fetchUnsyncedPayments = async () => {
            if(getParentCustomerName && customerName) {
                const parentCustomerName = getParentCustomerName(customerName);

                const encodedCustomerName = encodeURIComponent(parentCustomerName);
                const response = await fetch(`/api/payment/unsynced?parentCustomerName=${encodedCustomerName}`, {
                        headers: {
                            Authorization: localStorage.getItem("token") ?? undefined,
                        }  as HeadersInit});

                const data = await response.json();
                if (data.length > 0) {
                    setHasUnsyncedPayments(true);
                }
            }
        };

        fetchUnsyncedPayments();
    }, [customerName, getParentCustomerName]);

    if (!hasUnsyncedPayments) {
        return <></>;
    }

    return (
        <PaddedCard>
            <Alert severity="warning">
                We noticed an unsynced payment on your account. Your balances may not be up-to-date until these finish syncing.
            </Alert>
        </PaddedCard>

    );
};

export default UnsyncedPaymentsAlert;