import {Button, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {CreditApplicationData} from "./CreditApplicationData";
import CreditApplicationTerms from "./CreditApplicationTerms";
import CreditBankReferenceCard from "./CreditBankReferenceCard";
import CreditPersonalGuaranty from "./CreditPersonalGuaranty";
import CreditSecurityAgreement from "./CreditSecurityAgreement";
import CreditBasicInfo from "./CreditBasicInfo";
import CreditReferences from "./CreditReferences";
import AccountReceivableSpecialistInfo, {RACHEL_SHOCKEY} from "../../guiComponents/payments/AccountsReceivableSpecialist";
import CreditApplicationValidator from "./validators/creditApplication/CreditApplicationValidator";
import Alert from "@mui/material/Alert";
import {PaddedCard} from "../../LoggedOut/PaddedCard";

export type CreditApplicationProps = {
    cancelFunction?: () => void; //not set on the review page
    creditApplicationData: CreditApplicationData;
    setCreditApplicationData: (newData: CreditApplicationData) => void;
    standAlone?: boolean; //only set when an existing (COD) user is applying for credit
    setIsValid: (isValid: boolean) => void;
}

const CreditApplicationCard = (props: CreditApplicationProps) => {
    //destructure app data
    const {creditApplicationData, setCreditApplicationData, setIsValid} = props;

    const [validator] = React.useState(new CreditApplicationValidator());

    useEffect(() => {
        const errors = validator.validateAll(creditApplicationData); //map of fieldName => errorMessage
        setIsValid(Object.keys(errors).length === 0);
    }, [creditApplicationData, setIsValid, validator]);

    const getErrorMessage = (fieldName: string): string => {
        return validator.validateFieldIfTouched(creditApplicationData, fieldName);
    }

    const isInvalid = (fieldName: string): boolean => {
        return getErrorMessage(fieldName) !== "";
    }

    // Function to handle input change
    const updateData = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        directDataUpdate(name, value);
    };

    const directDataUpdate = (name: string, value: any) => {
        const newCreditData = {...creditApplicationData, [name]: value};
        setCreditApplicationData(newCreditData);

        const errors = validator.validateAll(newCreditData); //map of fieldName => errorMessage
        setIsValid(Object.keys(errors).length === 0);
    }

    const addPersonalGuaranty = () => {
        return <CreditPersonalGuaranty application={creditApplicationData}
                                directDataUpdate={directDataUpdate}
        />
    }

    const addSecurityAgreement = () => {
        return <CreditSecurityAgreement creditApplicationData={creditApplicationData} directDataUpdate={directDataUpdate}/>
    }

    const conditionallyRenderHeader = () => {
        if (props.cancelFunction) {
            return <>
                <div style={{ marginTop: 10, marginBottom: 15, display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography variant={"h6"}>CONFIDENTIAL CREDIT APPLICATION</Typography>
                    <div style={{marginTop: 5}}>
                        <Button variant="contained" color="secondary" onClick={() => {
                            props.cancelFunction!()
                        }}>Cancel</Button>
                    </div>
                </div>
                <AccountReceivableSpecialistInfo arSpecialist={RACHEL_SHOCKEY} title={"Questions? Ask me!"} padded={false}/>
            </>
        } else if(props.standAlone) { //very similar... but without a cancel button at the top and with a slightly larger font
            return <>
                <div style={{ marginTop: 10, marginBottom: 15, display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <h2>CONFIDENTIAL CREDIT APPLICATION</h2>
                </div>
                <AccountReceivableSpecialistInfo arSpecialist={RACHEL_SHOCKEY} title={"Questions? Ask me!"} padded={false}/>
                <PaddedCard style={{marginLeft: 0, marginRight: 0}}>
                    <Alert severity="warning">
                        If you recently applied for terms, please give us a little bit to process your application.
                        The accounting team will reach out to you within five business days.
                    </Alert>
                </PaddedCard>
            </>
        }
    }

    return <>
        {conditionallyRenderHeader()}
        <div style={{marginTop: 15}}>
            <CreditBasicInfo creditApplicationData={creditApplicationData}
                             getErrorMessage={getErrorMessage}
                             isInvalid={isInvalid}
                             updateData={updateData}
                             directDataUpdate={directDataUpdate}
            />
            <CreditBankReferenceCard reference={creditApplicationData.bankReference}
                                     directDataUpdate={directDataUpdate}
            />
            <CreditReferences creditApplicationData={creditApplicationData} directDataUpdate={directDataUpdate}/>
            <CreditApplicationTerms creditApplicationData={creditApplicationData} directDataUpdate={directDataUpdate}/>
        </div>

        {creditApplicationData.creditRequested >= 15000 && addPersonalGuaranty()}
        {creditApplicationData.creditRequested >= 20000 && addSecurityAgreement()}
    </>
}

export default CreditApplicationCard;