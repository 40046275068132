import {OBJECT_TYPE, ValidationRule} from "./ValidationRule";

class BaseValidator<T extends  { [key: string]: any }> {
    hasBeenTouched: { [key: string]: boolean } = {};
    dataValidations: { [key: string]: ValidationRule[] } = {};

    validateAll(data: T): { [key: string]: string } {
        const errors: { [key: string]: string } = {};

        for (const field in this.dataValidations) {
            const error = this.validateField(data, field);
            if (error) {
                errors[field] = error;
            }
        }

        return errors;
    }

    validateField(data: T, field: string): string {
        const validations = this.dataValidations[field];
        if (!validations) {
            return "";
        }

        for (const validation of validations) {

            const isValid = validation.type === OBJECT_TYPE
                ? validation.isValid(data)
                : validation.isValid(data[field]);

            if (!isValid) {
                return validation.getErrorMessage(field);
            }
        }

        return "";
    }

    validateFieldIfTouched(data: T, field: string): string {
        const fieldTouched = this.hasBeenTouched[field] || data[field];
        this.hasBeenTouched[field] = fieldTouched;

        if (!fieldTouched) {
            return "";
        }

        return this.validateField(data, field);
    }
}

export default BaseValidator;