import React, {useContext, useEffect, useState} from 'react';
import {Alert, Button, Container, Grid} from '@mui/material';
import { CustomerContext } from '../../../providers/CustomerProvider';
import {BankAccountWithNickname, CardWithNickname, PaymentMethod} from "../../../types/CardDataType";
import {StylesContext} from "../../../providers/StylesProvider";
import NewCreditCard from "./NewCreditCard";
import {NEW_BANK_ACCOUNT, NEW_CARD} from "./PaymentCardConstants";
import TreeSourceLoad from "../../Loaders/TreeSourceLoad";
import SavedPaymentMethodCard from "./SavedPaymentMethodCard";
import NewBankAccount from "./NewBankAccount";


const ManagePaymentMethods: React.FC = () => {
    const [cardsWithNicknames, setCardsWithNicknames] = useState<PaymentMethod[]>([]);
    const [newCard, setNewCard] = useState<PaymentMethod>(NEW_CARD);
    const [newBankAccount, setNewBankAccount] = useState<PaymentMethod>(NEW_BANK_ACCOUNT);
    const [showNewCard, setShowNewCard] = useState<boolean>(false);
    const [showNewBankAccount, setShowNewBankAccount] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");

    const {fetchSavedPaymentMethods, savePaymentMethod, deletePaymentMethod, updatePaymentMethod} = useContext(CustomerContext);
    const {navBarHeight, isDesktop} = useContext(StylesContext);

    useEffect(() => {
        loadCards();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchSavedPaymentMethods]);

    const formatErrorString = (str: string) => {
        if ((str===null) || (str===''))
            return "";
        else {
            str = str.replace("<br>", ". ");
            str = str.replace(/<[^>]*>/g, ''); //remove html tags

            if(str.includes("Error code")) {
                return str.split("Error code")[0];
            } else {
                return str;
            }
        }
    }

    const loadCards = () => {
        if(fetchSavedPaymentMethods) {
            fetchSavedPaymentMethods().then((cards) => {
                setCardsWithNicknames(cards);
                setLoading(false);
            });
        }
    }

    const resetScreen = () => {
        setNewCard(NEW_CARD);
        setNewBankAccount(NEW_BANK_ACCOUNT);
        setShowNewCard(false);
        setShowNewBankAccount(false);
        setError("")
        loadCards();
    }

    const handleSave = () => {
        if(savePaymentMethod) {
            setLoading(true);

            const myPaymentMethod = showNewCard ? newCard : newBankAccount;

            savePaymentMethod(myPaymentMethod).then((paymentMethod: PaymentMethod) => {
                if(paymentMethod.errors) {
                    setError(formatErrorString(paymentMethod.errors));
                    setLoading(false);
                } else if(paymentMethod.warnings) {
                    setError(formatErrorString(paymentMethod.warnings));
                    setLoading(false);
                } else {
                    resetScreen();
                }
            })
        }
    }

    const handleEdit = (paymentMethod: PaymentMethod) => {
        if(updatePaymentMethod) {
            setLoading(true);
            updatePaymentMethod(paymentMethod).then(() => {
                resetScreen();
            });
        }
    }

    const handleDelete = (paymentMethod: PaymentMethod) => {
        if(deletePaymentMethod) {
            setLoading(true);
            deletePaymentMethod(paymentMethod).then(() => {
                resetScreen();
            });
        }
    };

    const handleCancel = () => {
        setShowNewCard(false);
        setShowNewBankAccount(false);
        setError("");
    }

    const loadingLayout = () => {
        return <TreeSourceLoad message={"Loading Payment Methods..."}/>
    }

    const desktopLayout = () => {
        return <Container>
            <div style={{paddingTop: navBarHeight, display: "flex", justifyContent: "space-between"}}>
                <h2>Manage Payment Methods</h2>
                <div style={{display: "flex", alignItems: "center"}}>
                    <Button style={{marginLeft: 15}} variant="contained" color="primary"
                            onClick={() => {
                                setShowNewCard(false)
                                setShowNewBankAccount(true);
                                setError("");
                            }}>
                        Add Bank Account
                    </Button>
                    <Button style={{marginLeft: 15}} variant="contained" color="primary"
                            onClick={() => {
                                setShowNewCard(true)
                                setShowNewBankAccount(false);
                                setError("");
                            }}>
                        Add Credit Card
                    </Button>
                </div>
            </div>

            <Alert severity="info">
                Please note that we ask Intuit to store your card information instead of saving it ourselves.
                Therefore, to update an expired card, you will need to delete the old one and recreate it.
                We apologize for the inconvenience.
            </Alert>

            {showNewCard &&
                <div style={{marginLeft: 20, marginRight: 20}}>
                    <NewCreditCard setPaymentMethod={setNewCard}
                                   paymentMethod={newCard as CardWithNickname}
                                   saveCard={true}
                                   setSaveCard={() => {
                                          }}
                                   cancel={() => handleCancel()}
                                   save={handleSave}
                    />
                </div>
            }

            {error && <div style={{marginLeft: 28, marginRight: 28}}>
                <Alert severity={"error"}>
                    {error}
                </Alert>
            </div>}

            {showNewBankAccount &&
                <div style={{marginLeft: 20, marginRight: 20}}>
                    <NewBankAccount setPaymentMethod={setNewBankAccount}
                                   paymentMethod={newBankAccount as BankAccountWithNickname}
                                   saveAccount={true}
                                   setSaveAccount={() => {
                                   }}
                                   cancel={() => handleCancel()}
                                   save={handleSave}
                    />
                </div>
            }

            <div style={{margin: 20}}>
                <Grid container spacing={1}>
                    {cardsWithNicknames?.filter(c => c.nickname !== "New Bank Account" && c.nickname !== "New Card")
                        .map(f => f as CardWithNickname).map((payMethod: CardWithNickname) => (
                            <Grid item xs={12} sm={6} key={payMethod.id}>
                                <SavedPaymentMethodCard paymentMethod={payMethod} onEdit={handleEdit} onDelete={handleDelete}/>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
        </Container>
    }

    const mobileLayout = () => {
        return <>
            <Container>
                <div style={{paddingTop: navBarHeight}}>
                    <h2>Manage Payment Methods</h2>
                </div>

                <Alert severity="info">
                    Please note that we ask Intuit to store your card information instead of saving it ourselves.
                    Therefore, to update an expired card, you will need to delete the old one and recreate it.
                    We apologize for the inconvenience.
                </Alert>
            </Container>

            <div style={{margin: 15, display: "flex", justifyContent: "space-between"}}>
                    <Button style={{width: "48%"}} variant="contained" color="primary"
                            onClick={() => {
                                setShowNewCard(false)
                                setShowNewBankAccount(true);
                                setError("");
                            }}>
                        Add Bank Account
                    </Button>
                    <Button style={{width: "48%"}} variant="contained" color="primary"
                            onClick={() => {
                                setShowNewCard(true)
                                setShowNewBankAccount(false);
                                setError("");
                            }}>
                        Add Credit Card
                    </Button>
            </div>

            {showNewCard &&
                <NewCreditCard setPaymentMethod={setNewCard}
                               paymentMethod={newCard as CardWithNickname}
                               saveCard={true}
                               setSaveCard={() => {
                               }}
                               cancel={() => handleCancel()}
                               save={handleSave}
                />
            }

            {showNewBankAccount &&
                <NewBankAccount setPaymentMethod={setNewBankAccount}
                                paymentMethod={newBankAccount as BankAccountWithNickname}
                                saveAccount={true}
                                setSaveAccount={() => {
                                }}
                                cancel={() => handleCancel()}
                                save={handleSave}
                />
            }

            {error && <div style={{marginLeft: 28, marginRight: 28}}>
                <Alert severity={"error"}>
                    {error}
                </Alert>
            </div>}

            <div style={{margin: 10}}>
                <Grid container spacing={1}>
                    {cardsWithNicknames?.filter(c => c.nickname !== "New Bank Account" && c.nickname !== "New Card")
                        .map(f => f as CardWithNickname).map((payMethod: CardWithNickname) => (
                            <Grid item xs={12} sm={6} key={payMethod.id}>
                                <SavedPaymentMethodCard paymentMethod={payMethod} onEdit={handleEdit}
                                                        onDelete={handleDelete}/>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
        </>
    }

    if (loading) {
        return loadingLayout();
    } else {
        return isDesktop ? desktopLayout() : mobileLayout();
    }
};

export default ManagePaymentMethods;