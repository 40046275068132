
import React from "react";
import Button from "@mui/material/Button";
import { ShipmentType } from "../../types/ShipmentType";
import { useHistory } from "react-router";

type PayBalanceProps = {
  selectedOrderData: ShipmentType | undefined;
  updateBalanceTemp: (arg1: number) => void;
};

const PayBalanceButton = (props: PayBalanceProps) => {
  const history = useHistory();

  const handleClickOpen = () => {
    const orderID = props.selectedOrderData?.orderID || 0;
    const shipmentID = props.selectedOrderData?.shipmentID || 0;

    const formattedId = orderID + "-" + shipmentID;

    history.push("/payments?hint=" + formattedId);
  };

  return (
    <div>
      <Button
        style={{
          marginLeft: "10px",
          borderStyle: "solid",
          padding: "10px",
          borderRadius: "7px",
          borderWidth: "thin",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        Make Payment
      </Button>
    </div>
  );
};

export default PayBalanceButton;
