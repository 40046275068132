import React, {Dispatch, useState} from "react"
import {useMediaQuery} from "@mui/material";

export type StylesPropertiesType = {
    navBarHeight: number
    isDesktop: boolean
}

export type StylesFunctionsType = {
    setNavBarHeight: Dispatch<React.SetStateAction<number>>
}

export const StylesContext = React.createContext<Partial<StylesPropertiesType & StylesFunctionsType>>({})


export const StylesProvider = (props: any) => {
    const [navBarHeight, setNavBarHeight] = useState(0);
    const isDesktop = useMediaQuery("(min-width:850px");

    const styles: StylesPropertiesType & StylesFunctionsType = {
        setNavBarHeight,
        navBarHeight,
        isDesktop
    }

    return(
        <StylesContext.Provider value = {styles}>
            {props.children}
        </StylesContext.Provider>
    )
}
