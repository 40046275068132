import {BankAccountWithNickname, CardWithNickname, PaymentMethod, TYPE_CARD} from "../../../types/CardDataType";
import {PaddedCard} from "../../LoggedOut/PaddedCard";
import {Button, CardContent, CardHeader, Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import React, {useContext} from "react";
import {StylesContext} from "../../../providers/StylesProvider";

export type SavedPaymentMethodCardProps = {
    paymentMethod: PaymentMethod;
    onEdit: (paymentMethod: PaymentMethod) => void;
    onDelete: (paymentMethod: PaymentMethod) => void;

}

function SavedPaymentMethodCard(props: SavedPaymentMethodCardProps) {
    const [copyOfCard, setCopyOfCard] = React.useState<PaymentMethod>(props.paymentMethod)
    const [editMode, setEditMode] = React.useState<boolean>(false)

    //context
    const { isDesktop } = useContext(StylesContext);

    const cancelEdit = () => {
        setEditMode(!editMode)
        setCopyOfCard(props.paymentMethod)
    }

    const lastFour = (cardNumber: string) => {
        return cardNumber.slice(-4);
    }

    const cardName = () => {
        if(editMode) {
            return <TextField
                id="nickname"
                label="Nickname"
                style={{marginLeft: 10, marginTop: 8, width: isDesktop ? "45%" : "55%"}}
                value={copyOfCard.nickname}
                onChange={(e) => setCopyOfCard({...copyOfCard, nickname: e.target.value})}
            />
        } else {
            return <CardHeader title={props.paymentMethod.nickname} />
        }

    }

    const cardNumber = () => {
        if(props.paymentMethod.type === TYPE_CARD) {
            const castCard = props.paymentMethod as CardWithNickname;
            return <Typography style={{marginRight: 25}}>{castCard.cardType + " - " + lastFour(castCard.number)}</Typography>
        } else {
            const castBankAccount = props.paymentMethod as BankAccountWithNickname;
            return <Typography style={{marginRight: 25}}>{"BANK - " + lastFour(castBankAccount.accountNumber)}</Typography>
        }

    }

    return <PaddedCard>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                {cardName()} {cardNumber()}
            </div>
            <CardContent>

                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{display: "flex", alignItems: "center", flexDirection: "row"}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!editMode}
                                    checked={copyOfCard.isDefault}
                                    onChange={(e) => setCopyOfCard({...copyOfCard, isDefault: e.target.checked})}
                                />
                            }
                            label="Default"
                        />
                    </div>


                    <div style={{display: "flex", flexDirection: "row"}}>
                        {(editMode) && <Button color="primary" onClick={() => props.onEdit(copyOfCard)}>
                            Save
                        </Button> }
                        <Button color="primary" onClick={cancelEdit}>
                            {editMode ? "Cancel" : "Edit"}
                        </Button>

                        <Button color="secondary" onClick={() => props.onDelete(props.paymentMethod)}>
                            Delete
                        </Button>
                    </div>
                </div>

        </CardContent>
    </PaddedCard>;
}

export default SavedPaymentMethodCard;