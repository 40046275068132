import {TextField, Autocomplete, CircularProgress, Switch, FormGroup, FormControlLabel} from "@mui/material"
import React, {Dispatch, SetStateAction, useEffect} from "react"
import {filterParams} from "./Products"

export type SearchParameters = {
    submitSearchString: string
    setSearchString: Dispatch<SetStateAction<string>>,
    applyFilter: (filterParams: filterParams) => void,
    clearFilter: () => void,
    plantSize: string,
    plantGenus: string,
    plantSpecies: string,
    plantSubcategory: string,
    plantCategory: string,
    plantLeafType: string,
    plantStemType: string,
    plantRootball: string,
    searchString: string,
    entitys: string[]
}

const SearchBar = (props: SearchParameters) => {

  const {
    plantSize,
    entitys,
    plantCategory,
    plantLeafType,
    plantRootball,
    plantStemType,
    plantGenus,
    plantSpecies,
    plantSubcategory,
    searchString,
    } = props

  let filterData = {
    size: plantSize,
    entitys: entitys,
    category: plantCategory ,
    leafType: plantLeafType,
    rootball: plantRootball,
    stemType: plantStemType,
    genus: plantGenus,
    species: plantSpecies,
    subCategory: plantSubcategory,
    searchString: searchString
} as filterParams

    const [loading, setLoading] = React.useState(false);
    const [useLatin, setUseLatin] = React.useState(false);
    const [abortController, setAbortController] = React.useState<AbortController | undefined>(undefined);
    const [itemNames , setItemNames] = React.useState<string[]>([]);

    const toggleUseLatin = () => {
        setUseLatin(!useLatin);
    };


    const handleInputChange = (value: string) => {
        // Cancel the previous request
        if (abortController) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        const encodedValue = encodeURIComponent(value);
        setLoading(true);
        fetch(`/api/products/search?searchTerm=${encodedValue}`, { signal: newAbortController.signal })
            .then(response => response.json())
            .then(data => {
                const itemCommonNames = data.map((item: any) => {
                    if(useLatin) {
                        return item?.itemDetail?.latinNameWithQualifiers;
                    } else {
                        return item?.itemDetail?.commonNameWithQualifiers;
                    }
                })
                setItemNames(itemCommonNames)
                setLoading(false);
            })
            .catch(error => {
                console.log(error);

            })
    }
    const handleChange = (value: string) => {
      props.setSearchString(value);
    }

    useEffect(() => {
        if(searchString !== "") {
            props.applyFilter({...filterData, searchString: props.submitSearchString} as filterParams)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString, props.submitSearchString]);

        return (
          <>
            <div
              style={{display:"flex", flexDirection: "row", justifyContent:"center", marginTop: 10, marginBottom: 20}}
              >
                <Autocomplete
                    id="item-detail-combo-box"
                    sx={{ width: "100%", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    freeSolo={true}
                    options={itemNames}
                      renderInput={(params) => (
                          <TextField {...params}
                                     label="Enter Plant Name"
                                     InputProps={{
                                         ...params.InputProps,
                                         endAdornment: (
                                             <React.Fragment>
                                                 {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                 {params.InputProps.endAdornment}
                                             </React.Fragment>
                                         ),
                                     }}/>
                      )}
                    filterOptions={(options, state) => options}
                    onInputChange={(event, value) => handleInputChange(value ? value : "")}
                    onChange={(event, value) => handleChange(value ? value : "")}
                    value={searchString}
                />
                <FormGroup>
                    <FormControlLabel
                        style={{marginLeft: 10, width:"120px"}}
                        control={<Switch checked={useLatin} onChange={toggleUseLatin} />}
                        label={useLatin ? "Latin" : "Common"}
                    />
                </FormGroup>
            </div>
          </>
        )


}

export default SearchBar