import {TextField} from "@mui/material";
import SimpleAutoComplete from "../SimpleAutoComplete";
import React, {useContext} from "react";
import {AddressType} from "../../../types/CardDataType";
import {StylesContext} from "../../../providers/StylesProvider";
import AddressValidator from "../../authComponents/registration/validators/AddressValidator";

const states = [
    { label: "Alabama", code: "AL" },
    { label: "Alaska", code: "AK" },
    { label: "Arizona", code: "AZ" },
    { label: "Arkansas", code: "AR" },
    { label: "California", code: "CA" },
    { label: "Colorado", code: "CO" },
    { label: "Connecticut", code: "CT" },
    { label: "Delaware", code: "DE" },
    { label: "Florida", code: "FL" },
    { label: "Georgia", code: "GA" },
    { label: "Hawaii", code: "HI" },
    { label: "Idaho", code: "ID" },
    { label: "Illinois", code: "IL" },
    { label: "Indiana", code: "IN" },
    { label: "Iowa", code: "IA" },
    { label: "Kansas", code: "KS" },
    { label: "Kentucky", code: "KY" },
    { label: "Louisiana", code: "LA" },
    { label: "Maine", code: "ME" },
    { label: "Maryland", code: "MD" },
    { label: "Massachusetts", code: "MA" },
    { label: "Michigan", code: "MI" },
    { label: "Minnesota", code: "MN" },
    { label: "Mississippi", code: "MS" },
    { label: "Missouri", code: "MO" },
    { label: "Montana", code: "MT" },
    { label: "Nebraska", code: "NE" },
    { label: "Nevada", code: "NV" },
    { label: "New Hampshire", code: "NH" },
    { label: "New Jersey", code: "NJ" },
    { label: "New Mexico", code: "NM" },
    { label: "New York", code: "NY" },
    { label: "North Carolina", code: "NC" },
    { label: "North Dakota", code: "ND" },
    { label: "Ohio", code: "OH" },
    { label: "Oklahoma", code: "OK" },
    { label: "Oregon", code: "OR" },
    { label: "Pennsylvania", code: "PA" },
    { label: "Rhode Island", code: "RI" },
    { label: "South Carolina", code: "SC" },
    { label: "South Dakota", code: "SD" },
    { label: "Tennessee", code: "TN" },
    { label: "Texas", code: "TX" },
    { label: "Utah", code: "UT" },
    { label: "Vermont", code: "VT" },
    { label: "Virginia", code: "VA" },
    { label: "Washington", code: "WA" },
    { label: "West Virginia", code: "WV" },
    { label: "Wisconsin", code: "WI" },
    { label: "Wyoming", code: "WY" }
];

export type AddressInputFieldsProps = {
    address: AddressType;
    setAddress: (newAddress: AddressType) => void;
    label: string;
    outlined?: boolean;
    required?: boolean;
}

const AddressInputFields: React.FC<AddressInputFieldsProps> = (props: AddressInputFieldsProps) => {
    const { isDesktop } = useContext(StylesContext);
    const useOutlined = props.outlined ?? true; //if null, default to true
    const variant = useOutlined ? "outlined" : "standard";
    const isRequired = props.required ?? false;

    const id = props.label.replace(" ", "").toLowerCase();

    const [validator] = React.useState(new AddressValidator());

    const getErrorMessage = (fieldName: string): string => {
        if(!isRequired) {
            return "";
        }

        return validator.validateFieldIfTouched(props.address, fieldName);
    }

    const isInvalid = (fieldName: string): boolean => {
        return getErrorMessage(fieldName) !== "";
    }

    const renderDesktop = () => {
        return <>
            <TextField
                id={id}
                required={isRequired}
                label={props.label}
                variant={variant}
                margin="dense"
                fullWidth
                value={props.address.streetAddress}
                error={isInvalid("streetAddress")}
                helperText={getErrorMessage("streetAddress").replace("Street Address", props.label)}
                onChange={(event) => {
                    const newAddress = {...props.address, streetAddress: event.target.value};
                    props.setAddress( newAddress);
                }}
            />
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <TextField
                    id={"city"}
                    required={isRequired}
                    label="City"
                    variant={variant}
                    margin="dense" style={{width: "50%"}}
                    value={props.address.city}
                    error={isInvalid("city")}
                    helperText={getErrorMessage("city")}
                    onChange={(event) => {
                        const newAddress = {...props.address, city: event.target.value};
                        props.setAddress(newAddress);
                    }}
                />
                <SimpleAutoComplete
                    id={"state"}
                    required={isRequired}
                    label={"State"}
                    outlined={useOutlined}
                    error={isInvalid("region")}
                    errorMessage={getErrorMessage("region").replace("Region", "State")}
                    options={states.map((state) => state.label)}
                    value={states.find(state => state.code === props.address.region)?.label || ""}
                    setValue={(value) => {
                        const stateCode = states.filter((state) => state.label === value)[0]?.code ?? ""

                        const newAddress = {...props.address, region: stateCode};
                        props.setAddress(newAddress);
                    }}
                    style={{width: "30%", marginTop: 8}}
                />
                <TextField
                    id={"zipcode"}
                    required={isRequired}
                    label="Zipcode"
                    variant={variant}
                    margin="dense"
                    style={{width: "15%"}}
                    value={props.address.postalCode}
                    error={isInvalid("postalCode")}
                    helperText={getErrorMessage("postalCode").replace("Postal Code", "Zipcode")}
                    onChange={(event) => {
                        const value = event.target.value;
                        const isNumber = !isNaN(Number(value));

                        if (isNumber) {
                            const newAddress = {...props.address, postalCode: event.target.value};
                            props.setAddress( newAddress );
                        }
                    }}
                />
            </div>
        </>
    }

    const renderMobile = () => {
        return <>
            <TextField
                id={"streetAddress"}
                required={isRequired}
                label="Street Address"
                variant={variant}
                margin="dense"
                fullWidth
                value={props.address.streetAddress}
                error={isInvalid("streetAddress")}
                helperText={getErrorMessage("streetAddress")}
                onChange={(event) => {
                    const newAddress = {...props.address, streetAddress: event.target.value};
                    props.setAddress(newAddress);
                }}
            />
            <TextField
                id={"city"}
                required={isRequired}
                label="City"
                variant={variant}
                margin="dense"
                style={{width: "100%"}}
                value={props.address.city}
                error={isInvalid("city")}
                helperText={getErrorMessage("city")}
                onChange={(event) => {
                    const newAddress = {...props.address, city: event.target.value};
                    props.setAddress(newAddress);
                }}
            />
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <SimpleAutoComplete
                    id={"state"}
                    required={isRequired}
                    label={"State"}
                    outlined={useOutlined}
                    error={isInvalid("region")}
                    errorMessage={getErrorMessage("region").replace("Region", "State")}
                    options={states.map((state) => state.label)}
                    value={states.find(state => state.code === props.address.region)?.label || ""}
                    setValue={(value) => {
                        const stateCode = states.filter((state) => state.label === value)[0]?.code ?? ""

                        const newAddress = {...props.address, region: stateCode};
                        props.setAddress(newAddress);
                    }}
                    style={{width: "59%", marginTop: 8}}
                />
                <TextField
                    id={"zipcode"}
                    required={isRequired}
                    label="Zipcode"
                    variant={variant}
                    margin="dense"
                    style={{width: "39%"}}
                    value={props.address.postalCode}
                    error={isInvalid("postalCode")}
                    helperText={getErrorMessage("postalCode").replace("Postal Code", "Zipcode")}
                    onChange={(event) => {
                        const value = event.target.value;
                        const isNumber = !isNaN(Number(value));

                        if (isNumber) {
                            const newAddress = {...props.address, postalCode: event.target.value};
                            props.setAddress(newAddress);
                        }
                    }}
                />
            </div>
        </>
    }

    return <>{isDesktop ? renderDesktop() : renderMobile()}</>
}

export default AddressInputFields;
