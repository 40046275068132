import React, {useEffect} from "react";
import {TextField, Typography} from "@mui/material";

type ALULookupProps = {
    branch: string;
    aluValue: string;
    setALUValue: React.Dispatch<React.SetStateAction<string>>;
    setAluValid: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ALULookup = (props: ALULookupProps)  => {
    const branch = props.branch;
    const aluValue = props.aluValue;
    const setALUValue = props.setALUValue;
    const setAluValid = props.setAluValid;

    const [latinName, setLatinName] = React.useState("");
    const [commonName, setCommonName] = React.useState("");
    const [abortController, setAbortController] = React.useState<AbortController | undefined>(undefined);

    useEffect(() => {
        if(!aluValue) {
            setLatinName("");
            setCommonName("");
        }
    }, [aluValue]);

    const handleInputChange = (value: string) => {
        // Cancel the previous request
        if (abortController) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        const encodedBranch = encodeURIComponent(branch);
        const encodedAlu = encodeURIComponent(value);

        fetch(`/api/products/${encodedBranch}/${encodedAlu}`, { signal: newAbortController.signal, headers: {
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit })
            .then(response => response.json())
            .then(data => {
                setLatinName(data?.itemDetail?.latinNameWithQualifiers);
                setCommonName(data?.itemDetail?.commonNameWithQualifiers);

                setAluValid(data?.itemDetail !== undefined);
            })
            .catch(error => {
                console.log(error);
                setLatinName("");
                setCommonName("");
            })

        setALUValue(value);
    }


    return (
        <>
            <div style={{marginTop: 10}}>
                <TextField
                    id="aluTextBox"
                    fullWidth
                    label="Enter Item ALU Here"
                    type="text"
                    value={aluValue}
                    onChange={(e) => handleInputChange(e.target.value)}
                />
            </div>

            <div style={{marginTop: 10}}>
                <Typography align="center">{latinName}</Typography>
                <Typography align="center">{commonName}</Typography>
            </div>
        </>
    )
}