import {Button, CardContent, CardHeader, Typography} from "@mui/material";
import React, {useContext} from "react";
import {AuthContext} from "../../providers/AuthProvider";
import {PaddedCard} from "../LoggedOut/PaddedCard";
import {useHistory} from "react-router";

const SalesRepInfo = () => {
    const history = useHistory();
    const Auth = useContext(AuthContext);
    const customer = Auth.user?.customer;
    const terms = customer?.termsRef_FullName?.trim();

    const goToNewTerms = () => {
        history.push('/new-terms');
    }

    if (customer?.salesRep?.name) {
        return (
            <PaddedCard style={{display: "flex", justifyContent: "space-between", alignItems: "flex-start", height: "90%"}} >
                {/*<div style={{width: "68%"}}>*/}
                <div>
                    <CardHeader title="Rep Info" />
                    <CardContent>
                        <Typography variant="body1">
                            <strong>Assigned Sales Rep: </strong> {customer?.salesRep.name}
                        </Typography>
                        <Typography variant="body1">
                            <strong> Email: </strong>
                            <a href={`mailto: ${customer.salesRep.emailAddress}`}>
                                {customer.salesRep.emailAddress}
                            </a>
                        </Typography>
                        <Typography variant="body1">
                            <strong>Phone: </strong>
                            <a href={`tel: ${customer.salesRep.phoneNumber}`}>
                                {customer.salesRep.phoneNumber}
                            </a>
                        </Typography>
                        {/*<Typography variant="body1">*/}
                        {/*    <strong>Humanizing Fact: </strong>*/}
                        {/*    A humanizing fact about the sales rep goes here.*/}
                        {/*</Typography>*/}
                    </CardContent>
                </div>
                {terms === "COD" && <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end", width: "32%"}}>
                    <div style={{width: "100%", marginRight: 15}}>
                        {/*<Button style={{ marginTop: 15, width: "100%"}} variant="contained" color="primary">*/}
                        {/*    Send Me A List!*/}
                        {/*</Button>*/}
                        <Button style={{ marginTop: 15, width: "100%"}} onClick={() => goToNewTerms()} variant="contained" color="primary">
                            Apply For Terms
                        </Button>
                    </div>

                </div>}
            </PaddedCard>
        );
    } else {
        return <></>
    }
};

export default SalesRepInfo;