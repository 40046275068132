import { FormControl, MenuItem, Select, styled } from "@mui/material"
import { BranchType } from "../../types/BranchType"
import { Customer } from "../../types/UserType"

const PREFIX = 'DropDown'

const classes = {
    formControl: `${PREFIX}-formControl`
}

const Root = styled('div')({
[`& .${classes.formControl}`]: {
    margin: 1,
    minWidth: 175,
    textOverflow: "ellipsis"
  }
})

type props = {
    fullscreen: boolean,
    handleChange: (e: any)=> void,
    value: string,
    menuItems: Array<BranchType> | Array<Customer>,
    dropDownUse: string
}

export const AccountDropDown = (props: props) => {

    const {fullscreen, handleChange, value, menuItems, dropDownUse} = props
    const customerMenuItems = menuItems as Customer[]
    const branchMenuItems = menuItems as BranchType[]

    const customerDropDownTitle = () => {
        return (
          <h3 style = {{marginTop: "13px", marginRight: 15}}>{dropDownUse === "customer" ?  "Account Name:" : "Branch Name:"} </h3>
        )
      }

    const getCustomerMenuItems = () => {

        return(
            customerMenuItems.map((x, index)=>{

            let displayNameValid = x?.displayName && x.displayName.length > 0
            let value = x?.fullName

            return(
                <MenuItem style={{whiteSpace: "unset", wordBreak: "break-all"}} key={index} value = {value} >
                    {displayNameValid ? x?.displayName : x?.fullName}
                </MenuItem>
            )
            })

        )
    }

    const getBranchMenuItems = () => {

        return(
            branchMenuItems.map((x, index)=>{
                    
            let value = x?.entityName

            return(
                    <MenuItem style={{whiteSpace: "unset", wordBreak: "break-all"}} key={index} value = {value} >
                        {x?.entityName}
                    </MenuItem>
                )
            })

        )
    }

    const customerDropDown = () => {
        return(
        <>
            <FormControl variant="outlined" hiddenLabel={true} required size="medium" className={classes.formControl}
            >
            <div style={{display: "flex", flexDirection: "row"}}>
                
                {fullscreen ? customerDropDownTitle() : ""}

                <Select 
                    style={{color:"white", backgroundColor: "#4F7182", maxWidth: "250px"}}
                    name="company"
                    onChange={(e)=>handleChange(e)}
                    value={value}
                    
                >
                    { dropDownUse === 'customer' ? getCustomerMenuItems() : getBranchMenuItems() }
                </Select>

                { dropDownUse === ''}
            </div>
            </FormControl>
        </>
        )
      }

      return(
        <Root>
            {menuItems.length === 0 ? null : customerDropDown() } 
        </Root>
      )
}