import {
  Button,
  Container,
  Typography,
  Snackbar,
  Alert,
  AlertColor, Checkbox, FormControlLabel,
} from "@mui/material";
import React, { useState, useContext } from "react";
import { StylesContext } from "../../providers/StylesProvider";
import { LocationSelector, Location } from "./LocationSelector";
import {ALUCounter, AluInfo} from "./ALUCounter";

export const CycleCount = () => {
  // local data
  const DEFAULT_LOCATION: Location = {
    branch: "",
    yard: "",
    block: "",
  };

  const [selectedLocation, setSelectedLocation] =
    useState<Location>(DEFAULT_LOCATION);
  const [aluCounts, setALUCounts] = useState<{ [key: string]: AluInfo }>({});
  const [uncountedToZero, setUncountedToZero] = useState<boolean>(false);

  //ui controls
  const [toastMessage, setToastMessage] = useState<string | undefined>("");
  const [open, setOpen] = useState(false);
  const [toastSeverity, setToastSeverity] = useState<AlertColor | undefined>();

  // context
  const { navBarHeight } = useContext(StylesContext);

  const clearCount = () => {
    setALUCounts({});
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!isValid()) {
      return;
    }

    const data = {
      branch: selectedLocation.branch,
      yard: selectedLocation.yard,
      block: selectedLocation.block,
      setUncountedToZero: uncountedToZero,
      aluToCount: aluCounts,
    };

    //let's submit our form to /api/cycle-count with a POST request using the fetch API. We will also need to include our token in the request header.
    fetch("/api/cycle-count", {
      method: "POST",
      headers: {
        authorization: localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json",
      } as HeadersInit,
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          setToastMessage("Your cycle count was submitted successfully.");
          setToastSeverity("success");
          setOpen(true);

          clearCount();
          setSelectedLocation(DEFAULT_LOCATION);
        } else {
          response.json().then((body) => {
            const errorMessage: string =
              body.message ?? "An error occurred, please try again later.";
            handleError(errorMessage);
          });
        }
      })
      .catch((error) => {
        handleError("A network error occurred, please try again later.");
      });
  };

  const toggleSetUncountedToZero = () => {
    setUncountedToZero(!uncountedToZero);
  }

  const isValid = () => {
    if (selectedLocation.branch === "") {
      handleError("Please select a branch.");
      return false;
    }

    if (selectedLocation.yard === "") {
      handleError("Please select a yard.");
      return false;
    }

    if (selectedLocation.block === "") {
      handleError("Please select a block.");
      return false;
    }

    if (Object.keys(aluCounts).length === 0) {
      handleError("Please enter at least one ALU.");
      return false;
    }

    return true;
  };

  const handleError = (errorMessage: string) => {
    setToastMessage(errorMessage);
    setToastSeverity("error");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderToast = () => {
    return (
      <Snackbar autoHideDuration={4000} open={open} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={toastSeverity}
          style={{ width: "50%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <>
      <Container style={{ paddingTop: navBarHeight }}>
        <Typography variant="h6">Simple Cycle Count Form</Typography>
        <hr></hr>
        <LocationSelector
          location={selectedLocation}
          setLocation={setSelectedLocation}
        />
        <FormControlLabel  control={<Checkbox checked={uncountedToZero} onChange={toggleSetUncountedToZero} />} label="Set uncounted items to zero." />
        <div
          id="buttonGroup"
          style={{ display: "flex", marginTop: 10, marginRight: 10 }}
        >
          <Button
            style={{
              marginRight: 10,
              marginBottom: 5,
              flex: "1",
            }}
            id="clearCountButton"
            type="submit"
            variant="contained"
            color="primary"
            onClick={clearCount}
          >
            Clear Count
          </Button>
          <Button
            style={{
              marginLeft: 10,
              marginBottom: 5,
              flex: "1",
            }}
            id="submitCountButton"
            type="submit"
            variant="contained"
            color="success"
            onClick={handleSubmit}
          >
            Submit Count
          </Button>
        </div>
        <hr></hr>
        <ALUCounter aluCounts={aluCounts} setALUCounts={setALUCounts} branch={selectedLocation.branch} yard={selectedLocation.yard} block={selectedLocation.block}  />
        {renderToast()}
      </Container>
    </>
  );
};
