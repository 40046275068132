import React, {useContext, useCallback} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Button, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {NewUserData} from "./Register";
import {CreditApplicationData, DEFAULT_CREDIT_APPLICATION} from "./CreditApplicationData";
import {StylesContext} from "../../../providers/StylesProvider";
import BasicUserForm from "./BasicUserForm";
import BasicSupplementalInfo from "./BasicSupplementalInfo";
import BusinessCustomerSupplementalInfoOne from "./BusinessCustomerSupplementalOneInfo";
import BusinessCustomerSupplementalInfoTwo from "./BusinessCustomerSupllementalInfoTwo";
import CreditApplicationCard from "./CreditApplicationCard";
import {DEFAULT_JOB_LINE_APPLICATION, JobLineApplicationData} from "./JobLineApplicationData";
import JobLineApplicationCard from "./JobLineApplicationCard";

export type ReviewInformationProps = {
    userData: NewUserData;
    setUserData: React.Dispatch<React.SetStateAction<NewUserData>>
    submitFunction: () => void;
    getErrorMessage: (fieldName: string) => string;
    userDataIsValid: boolean;
}

const ReviewInformation = (props: ReviewInformationProps) => {
    const {isDesktop} = useContext(StylesContext);
    const { userData, setUserData, getErrorMessage } = props;

    const isInvalid = useCallback((fieldName: string): boolean => {
        return getErrorMessage(fieldName) !== "";
    }, [getErrorMessage]);

    const userDataHandleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }, [setUserData]);

    const userDataDirectDataUpdate = useCallback((name: string, value: any) => {
        setUserData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }, [setUserData]);

    const setCreditAppData = useCallback((newData: CreditApplicationData) => {
        userDataDirectDataUpdate("optionalCreditApp", newData);
    }, [userDataDirectDataUpdate]);

    const setJobLineApplicationData = useCallback((newData: JobLineApplicationData) => {
        userDataDirectDataUpdate("optionalJobLineApp", newData);
    }, [userDataDirectDataUpdate]);

    const addBusinessInfo = useCallback(() => {
        return <>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                    <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Suggested: Business Info 1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <BusinessCustomerSupplementalInfoOne userData={userData}
                                                         updateUserData={userDataHandleChange}
                                                         isInvalid={isInvalid}
                                                         getErrorMessage={getErrorMessage}
                                                         directDataUpdate={userDataDirectDataUpdate}
                                                         showInstructions={false}/>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                    <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Suggested: Business Info 2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <BusinessCustomerSupplementalInfoTwo userData={userData}
                                                         updateUserData={userDataHandleChange}
                                                         isInvalid={isInvalid}
                                                         getErrorMessage={getErrorMessage}
                                                         directDataUpdate={userDataDirectDataUpdate}
                                                         showInstructions={false}/>
                </AccordionDetails>
            </Accordion>
        </>
    }, [userData, userDataHandleChange, isInvalid, getErrorMessage, userDataDirectDataUpdate]);

    const addCreditAppInfo = useCallback(() => {
        return <Accordion>
            <AccordionSummary expandIcon={<ExpandMore/>}>
                <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Credit Application</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <CreditApplicationCard creditApplicationData={props.userData.optionalCreditApp ?? DEFAULT_CREDIT_APPLICATION}
                                       setCreditApplicationData={setCreditAppData}
                                       setIsValid={() => {}}
                />
            </AccordionDetails>
        </Accordion>
    }, [props.userData.optionalCreditApp, setCreditAppData]);

    const addJobLineAppInfo = useCallback(() => {
        return <Accordion>
            <AccordionSummary expandIcon={<ExpandMore/>}>
                <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Job Line Application</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <JobLineApplicationCard jobLineApplication={props.userData.optionalJobLineApp ?? DEFAULT_JOB_LINE_APPLICATION}
                                        setJobLineApplicationData={setJobLineApplicationData}
                                        setIsValid={() => {}}
                                        showHeader={false}
                />
            </AccordionDetails>
        </Accordion>
    }, [props.userData.optionalJobLineApp, setJobLineApplicationData]);

    const renderNewCustomerInfo = useCallback(() => {
        return <>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                    <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Suggested: Basic Customer Info</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <BasicSupplementalInfo userData={userData}
                                           updateUserData={userDataHandleChange}
                                           isInvalid={isInvalid}
                                           getErrorMessage={getErrorMessage}
                                           directDataUpdate={userDataDirectDataUpdate}
                                           showInstructions={false}/>
                </AccordionDetails>
            </Accordion>

            {!userData.isHomeowner && addBusinessInfo()}

            {userData.optionalCreditApp && addCreditAppInfo()}
            {userData.optionalJobLineApp && addJobLineAppInfo()}
        </>
    }, [userData, userDataHandleChange, isInvalid, getErrorMessage, userDataDirectDataUpdate, addBusinessInfo, addCreditAppInfo, addJobLineAppInfo]);

    return <>
        <div style={{width: isDesktop ? "840px" : "100%"}}>
            <div style={{marginTop: 10, marginBottom: 15, display: "flex", justifyContent: "space-between", width: "100%"}}>
                <Typography variant={"h6"}>PLEASE REVIEW YOUR INFORMATION</Typography>
                <div style={{marginTop: 5}}>
                    <Button variant="contained" color="primary" disabled={!props.userDataIsValid} onClick={() => {
                        props.submitFunction()
                    }}>Submit</Button>
                </div>
            </div>
            <div style={{marginTop: 15}}>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMore/>}>
                        <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Required: User Data</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BasicUserForm userData={userData}
                                       updateUserData={userDataHandleChange}
                                       isInvalid={isInvalid}
                                       getErrorMessage={getErrorMessage}
                                       directDataUpdate={userDataDirectDataUpdate}/>
                    </AccordionDetails>
                </Accordion>

                {userData.newCustomer && renderNewCustomerInfo()}
                <div style={{margin: 15}}/>
            </div>
        </div>
    </>
}

export default ReviewInformation;