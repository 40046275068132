import BaseValidator from "./BaseValidator";
import {AddressType} from "../../../../types/CardDataType";
import NotEmptyRule from "./NotEmptyRule";

class AddressValidator extends BaseValidator<AddressType> {
    constructor() {
        super();
        this.dataValidations = {
            region: [new NotEmptyRule()],
            postalCode: [new NotEmptyRule()],
            streetAddress: [new NotEmptyRule()],
            city: [new NotEmptyRule()],
        };
    }
}

export default AddressValidator;