import React, {useContext} from 'react';
import { useLocation } from 'react-router-dom';
import {
    Alert,
    CardContent,
    CardHeader,
    Container,
    Typography
} from "@mui/material";
import {useHistory} from "react-router";
import {StylesContext} from "../../../providers/StylesProvider";
import {PaddedCard} from "../../LoggedOut/PaddedCard";
import {formatter} from "../../../constants/Constants";

export type PaymentApplication = {
    invoiceRefNumber: string;
    originalAmount: number;
}

export type PaymentReceipt = {
    txnDate: string;
    paymentWarning?: string;
    creditMemoApplications?: Map<string, PaymentApplication[]>
    totalCreditMemoAmount: number;
    paymentApplications?: Map<string, PaymentApplication[]>
    processingFee: number;
    totalPaymentAmount: number;
    customerNote: string;
}

interface PaymentReceiptLocationState {
    data: PaymentReceipt;
}

const ThankYouPage: React.FC = () => {
    const { navBarHeight } = useContext(StylesContext);
    const location = useLocation<PaymentReceiptLocationState>();
    const history = useHistory();

    // Redirect to /payments if location.state is null or undefined
    if (!location.state) {
        history.push('/payments');
        return null;
    }

    const receipt = location.state.data;

    const notesCard = () => {
        if(!receipt.customerNote) {
            return <></>
        }

        return <PaddedCard style={{marginBottom: 20}}>
            <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <div style={{width: "28%"}}>
                    <CardHeader title="Notes:"/>
                </div>
                <div style={{ width: "72%", margin: 10, display: "flex", alignItems: "flex-start", justifyContent: "space-between"}}>
                    <Typography>{receipt.customerNote}</Typography>
                </div>
            </div>
        </PaddedCard>
    }

    const renderApplications = (applications: any) => {
        let rows: any[] = [];

        if (!(applications instanceof Map)) {
            let mapData = new Map();
            for (let key in applications) {
                mapData.set(key, applications[key]);
            }

            applications = mapData;
        }

        applications.forEach((applications: any, payment: any) => {
            // Add a row for the payment with blanks for the invoice ref number and amount applied
            rows.push(
                <tr key={payment}>
                    <td style={{borderRight: '1px solid black', borderBottom: "1px solid black"}}>{payment}</td>
                    <td style={{borderRight: '1px solid black', borderBottom: "1px solid black"}}></td>
                    <td style={{borderBottom: "1px solid black"}}></td>
                </tr>
            );

            // Add a row for each application with the correct invoiceRefNumber and original amount
            applications.forEach((application: any, index: any) => {
                rows.push(
                    <tr key={`${payment}-${index}`}>
                        <td style={{borderRight: '1px solid black', borderBottom: "1px solid black"}}></td>
                        <td style={{borderRight: '1px solid black', borderBottom: "1px solid black"}}>{application.invoiceRefNumber}</td>
                        <td style={{textAlign: "right",borderBottom: "1px solid black"}}>{formatter.format(application.originalAmount)}</td>
                    </tr>
                );
            });
        });

        return rows;
    };

    const creditsTable = () => {
        if(!receipt.creditMemoApplications) {
            return <></>;
        }

        return (
            <PaddedCard>
                <CardHeader title={"Credit Memo Applications:"} />
                <CardContent>
                    <p>Total Credit Memo Amount Applied: {formatter.format(receipt.totalCreditMemoAmount)}</p>
                    <table style={{border: '1px solid black', borderCollapse: "collapse"}}>
                        <thead>
                        <tr>
                            <th style={{width: 150, borderRight: '1px solid black', borderBottom: "1px solid black" }}>Credit Memo </th>
                            <th style={{width: 150, borderRight: '1px solid black', borderBottom: "1px solid black" }}>Invoice Ref </th>
                            <th style={{width: 150, borderBottom: "1px solid black"}}>Amount Applied</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderApplications(receipt.creditMemoApplications)}
                        </tbody>
                    </table>
                </CardContent>
            </PaddedCard>
        );
    }

    const paymentsTable = () => {
        if (!receipt.paymentApplications) {
            return <></>;
        }

        return (
            <PaddedCard>
                <CardHeader title={"Payment Applications:"}/>
                <CardContent>
                    {receipt.paymentWarning && <Alert severity="warning">
                        {receipt.paymentWarning}
                    </Alert>}

                    <p>Payment Date: {receipt.txnDate}</p>
                    <p>Total Payment Taken: {formatter.format(receipt.totalPaymentAmount)}</p>
                    {receipt.processingFee > 0 &&
                        <p>Credit Card Fee: {formatter.format(receipt.processingFee)}</p>
                    }
                    <table style={{border: '1px solid black', borderCollapse: "collapse"}}>
                        <thead>
                        <tr>
                            <th style={{
                                width: 150,
                                borderRight: '1px solid black',
                                borderBottom: "1px solid black"
                            }}>Payment
                            </th>
                            <th style={{
                                width: 150,
                                borderRight: '1px solid black',
                                borderBottom: "1px solid black"
                            }}>Invoice Ref
                            </th>
                            <th style={{width: 150, borderBottom: "1px solid black"}}>Amount Applied</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderApplications(receipt.paymentApplications)}
                        </tbody>
                    </table>
                </CardContent>
            </PaddedCard>
        );
    }

    return (
        <Container>
            <div style={{paddingTop: navBarHeight, marginBottom: 30}}>
                <h1>Thank You For Your Payment</h1>
                <Alert severity={"info"}>Normally, our systems sync in about 30min. Please give us up to three business days to double check and apply everything. </Alert>
                {notesCard()}
                {creditsTable()}
                {paymentsTable()}
            </div>
            <div style={{margin: 50}}></div> {/* Spacer for mobile */}
        </Container>

    );
};

export default ThankYouPage;