import {camelCaseToSplitWords, OBJECT_TYPE, ValidationRule} from "../ValidationRule";
import BankReferenceValidator from "./BankReferenceValidator";
import {CreditApplicationData} from "../../CreditApplicationData";

class IsValidBankReference implements ValidationRule {
    type = OBJECT_TYPE;
    validator = new BankReferenceValidator();

    fieldUnderTest: string;

    constructor(fieldUnderTest: string) {
        this.fieldUnderTest = fieldUnderTest;
    }

    isValid(application: CreditApplicationData)  {
        const bankReference = application[this.fieldUnderTest];
        const validationErrors = this.validator.validateAll(bankReference);

        return Object.keys(validationErrors).length === 0;
    }

    getErrorMessage(fieldName: string) {
        const name = camelCaseToSplitWords(fieldName);
        return ` ${name}`;
    }
}

export default IsValidBankReference;