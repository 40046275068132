import {Accordion, AccordionDetails, AccordionSummary, TextField, Typography} from "@mui/material";
import React, {ChangeEvent, useContext} from "react";
import AddressInputFields from "../../guiComponents/payments/AddressInputFields";
import {BankReference} from "./CreditApplicationData";
import {AddressType} from "../../../types/CardDataType";
import {ExpandMore} from "@mui/icons-material";
import {StylesContext} from "../../../providers/StylesProvider";
import BankReferenceValidator from "./validators/creditApplication/BankReferenceValidator";

export type CreditBankReferenceCardProps = {
    reference: BankReference;
    directDataUpdate: (fieldName: string, value: any) => void;
}

const CreditBankReferenceCard: React.FC<CreditBankReferenceCardProps> = (props: CreditBankReferenceCardProps) => {
    const {isDesktop} = useContext(StylesContext);

    const [validator] = React.useState(new BankReferenceValidator());

    const getErrorMessage = (fieldName: string): string => {
        return validator.validateFieldIfTouched(props.reference, fieldName);
    }

    const isInvalid = (fieldName: string): boolean => {
        return getErrorMessage(fieldName) !== "";
    }

    // Function to handle input change
    const updateData = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        const newBankReference = {...props.reference, [name]: value};
        props.directDataUpdate("bankReference", newBankReference);
    };

    const updateAddress = (address: AddressType) => {
        const newBankReference = {...props.reference, address: address};
        props.directDataUpdate("bankReference", newBankReference);
    }

    const renderDesktop = () => {
        return <>
            <Accordion style={{width: "100%"}}>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                    <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Bank Reference</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginLeft: 15,
                        marginRight: 15
                    }}>
                        <TextField
                            style={{width: "62%"}}
                            required
                            variant="standard"
                            name="bankName"
                            label="Name of Bank"
                            error={isInvalid("bankName")}
                            helperText={getErrorMessage("bankName")}
                            onChange={updateData}
                            value={props.reference.bankName}
                        />
                        <TextField
                            style={{width: "36%"}}
                            required
                            variant="standard"
                            name="officerName"
                            label="Officer Name"
                            error={isInvalid("officerName")}
                            helperText={getErrorMessage("officerName")}
                            onChange={updateData}
                            value={props.reference.officerName}
                        />
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 7,
                        marginLeft: 15,
                        marginRight: 15
                    }}>
                        <TextField
                            style={{width: "27%"}}
                            required
                            variant="standard"
                            name="phoneNumber"
                            label="Phone Number"
                            error={isInvalid("phoneNumber")}
                            helperText={getErrorMessage("phoneNumber")}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                const value = event.target.value;
                                const isNumber = !isNaN(Number(value));

                                if (isNumber && !value.includes(" ")) {
                                    updateData(event);
                                }
                            }}
                            value={props.reference.phoneNumber}
                        />
                        <TextField
                            style={{width: "34%"}}
                            required
                            variant="standard"
                            name="checkingAccountNumber"
                            label="Checking Account No."
                            error={isInvalid("checkingAccountNumber")}
                            helperText={getErrorMessage("checkingAccountNumber")}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                const value = event.target.value;
                                const isNumber = !isNaN(Number(value));

                                if (isNumber && !value.includes(" ")) {
                                    updateData(event);
                                }
                            }}
                            value={props.reference.checkingAccountNumber}
                        />
                        <TextField
                            style={{width: "36%"}}
                            required
                            variant="standard"
                            name="savingsAccountNumber"
                            label="Savings Account No."
                            error={isInvalid("savingsAccountNumber")}
                            helperText={getErrorMessage("savingsAccountNumber")}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                const value = event.target.value
                                const isNumber = !isNaN(Number(value));

                                if (isNumber && !value.includes(" ")) {
                                    updateData(event);
                                }
                            }}
                            value={props.reference.savingsAccountNumber}
                        />
                    </div>
                    <div style={{marginLeft: 15, marginRight: 15, marginBottom: 20}}>
                        <AddressInputFields address={props.reference.address}
                                            setAddress={updateAddress}
                                            label={"Address"}
                                            outlined={false}
                                            required={true}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    }

    const renderMobile = () => {
        return <>
            <Accordion style={{width: "100%"}}>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                    <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Bank Reference</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField
                        fullWidth
                        required
                        margin="dense"
                        variant="standard"
                        name="bankName"
                        label="Name of Bank"
                        error={isInvalid("bankName")}
                        helperText={getErrorMessage("bankName")}
                        onChange={updateData}
                        value={props.reference.bankName}
                    />
                    <TextField
                        fullWidth
                        required
                        margin="dense"
                        variant="standard"
                        name="officerName"
                        label="Officer Name"
                        error={isInvalid("officerName")}
                        helperText={getErrorMessage("officerName")}
                        onChange={updateData}
                        value={props.reference.officerName}
                    />
                    <TextField
                        fullWidth
                        required
                        margin="dense"
                        variant="standard"
                        name="phoneNumber"
                        label="Phone Number"
                        error={isInvalid("phoneNumber")}
                        helperText={getErrorMessage("phoneNumber")}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const value = event.target.value;
                            const isNumber = !isNaN(Number(value));

                            if (isNumber && !value.includes(" ")) {
                                updateData(event);
                            }
                        }}
                        value={props.reference.phoneNumber}
                    />
                    <TextField
                        fullWidth
                        required
                        margin="dense"
                        variant="standard"
                        name="checkingAccountNumber"
                        label="Checking Account No."
                        error={isInvalid("checkingAccountNumber")}
                        helperText={getErrorMessage("checkingAccountNumber")}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const value = event.target.value;
                            const isNumber = !isNaN(Number(value));

                            if (isNumber && !value.includes(" ")) {
                                updateData(event);
                            }
                        }}
                        value={props.reference.checkingAccountNumber}
                    />
                    {/*todo: i know its kind of hard, but I really do need to create a number and phone number class...*/}
                    <TextField
                        fullWidth
                        required
                        margin="dense"
                        variant="standard"
                        name="savingsAccountNumber"
                        label="Savings Account No."
                        error={isInvalid("savingsAccountNumber")}
                        helperText={getErrorMessage("savingsAccountNumber")}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const value = event.target.value
                            const isNumber = !isNaN(Number(value));

                            if (isNumber && !value.includes(" ")) {
                                updateData(event);
                            }
                        }}
                        value={props.reference.savingsAccountNumber}
                    />
                    <AddressInputFields address={props.reference.address}
                                        setAddress={updateAddress}
                                        label={"Address"}
                                        outlined={false}
                                        required={true}
                    />
                </AccordionDetails>
            </Accordion>
        </>
    }

    if(isDesktop) {
        return renderDesktop();
    } else {
        return renderMobile();
    }
}

export default CreditBankReferenceCard;