import {DataGridPro} from "@mui/x-data-grid-pro";
import {GridColDef, GridSelectionModel, GridToolbar} from "@mui/x-data-grid";
import React from "react";
import {formatter} from "../../../constants/Constants";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {PictureAsPdf} from "@mui/icons-material";
import {AccountEvent} from "./PaySpecificInvoicesCard";

type SpecificInvoiceGridProps = {
    filteredAccountBalanceEvents: AccountEvent[];
    selectedRowModel: GridSelectionModel;
    setSelectedRowModel: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
    handleEventSelectionChange: (newSelection: GridSelectionModel) => void;
    handlePdfClick: (pressedRow: AccountEvent) => void;
}

const SpecificInvoiceGrid = (props: SpecificInvoiceGridProps) => {
    const columns: GridColDef[] = [
        {
            field: "type",
            headerName: "Type",
            flex: .8,
        },
        {
            field: "refNumber",
            headerName: "Ref. Number",
            flex: .8
        },
        {
            field: "dueDate",
            headerName: "Due Date",
            flex: .8,
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                return Date.parse(v1) - Date.parse(v2);
            },
        },
        {
            field: "poNumber",
            headerName: "PO Number",
            flex: 1
        },
        {
            field: "memo",
            headerName: "Memo",
            flex: 2
        },
        {
            field: "balanceRemaining",
            headerName: "Balance",
            flex: .8,
            valueFormatter: (params) => formatter.format(params.value as number)
        },
        {
            field: 'pdf',
            headerName: 'PDF',
            width: 70,
            renderCell: (params) => {
                const isDisabled = !params.row.url;
                const tooltipTitle = "We're sorry, this event came from another system. Please reach out to our AR team to get a PDF.";

                return (
                    <Tooltip title={isDisabled ? tooltipTitle : ""}>
                    <span>
                        <IconButton
                            color="primary"
                            onClick={() => !isDisabled && props.handlePdfClick(params.row)}
                            disabled={isDisabled}
                        >
                            <PictureAsPdf />
                        </IconButton>
                    </span>
                    </Tooltip>
                );
            },
        },
    ];

    return <>
        <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", margin: 10}}>
            <DataGridPro
                style={{height: "500px"}}
                rows={props.filteredAccountBalanceEvents}
                columns={columns}
                getRowId={(row) => row.refNumber}
                checkboxSelection
                disableSelectionOnClick={true}
                selectionModel={props.selectedRowModel}
                onSelectionModelChange={(newSelection) => {
                    props.setSelectedRowModel(newSelection);
                    props.handleEventSelectionChange(newSelection);
                }}
                components={{
                    Toolbar: GridToolbar,
                }}
                componentsProps={{
                    toolbar: {
                        csvOptions: {disableToolbarButton: true},
                        printOptions: {disableToolbarButton: true},
                        quickFilterProps: {debounceMs: 250},
                    },
                }}
            />
        </div>
    </>
}


export default SpecificInvoiceGrid;
