import { datadogLogs } from "@datadog/browser-logs";
import { Backdrop, Button, CircularProgress, Container, Typography, useMediaQuery } from "@mui/material"
import { styled } from '@mui/material/styles';
import axios from "axios"
import { useContext, useRef } from "react"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { CUSTOMER_NAME, SHOW_ADD_TO_SHIP, formatter } from "../../constants/Constants"
import { StylesContext } from "../../providers/StylesProvider";
import { OrderLineItemType } from "../../types/OrderLineItemType"
import { ShipmentType } from "../../types/ShipmentType"
import { Error } from "../global/Error";
import TreeSourceLoad from "../Loaders/TreeSourceLoad"
import OrderLineItems from "./OrderLineItems"
import PayBalanceButton from "./PayBalanceButton"
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';


const PREFIX = 'SpecificOrder';

const classes = {
    orderLineItems: `${PREFIX}-orderLineItems`,
    orderLineItemsPhone: `${PREFIX}-orderLineItemsPhone`,
    MuiDataGrid: `${PREFIX}-MuiDataGrid`
};

const Root = styled('div')({
    [`& .${classes.orderLineItems}`]: {
        display: "flex",
        flexDirection: "column",
        margin: 10,
        minHeight: "800px",
        maxHeight: "800px",
        marginBottom: 150
    },
    [`& .${classes.orderLineItemsPhone}`]: {
        flexGrow: 2,
        minHeight: "400px",
        maxHeight: "400px"
    },
    [`& .${classes.MuiDataGrid}`]: {
        height: "600px"
    }

});

export type orderParams = {
    orderid: string,
    shipmentid: string,
    view: string
}

export type shipmentProps = {
    orderid: string,
    shipmentid: string,
    successToastOpen: boolean
}



const SpecificOrder = (props: shipmentProps) => {


    const matches = useMediaQuery('(min-width:716px')

    const { orderid, shipmentid, view } = useParams<orderParams>()
    const [orderLineItems, setOrderLineItems] = useState<OrderLineItemType[]>([])
    const [oliLoading, setOliLoading] = useState(true)
    const [orderLoading, setOrderLoading] = useState(true)
    const [orderError, setOrderError] = useState(false)
    const [orderErrorMessage, setOrderErrorMessage] = useState("")
    const [oliError, setOliError] = useState(false)
    const [oliErrorMessage, setOliErrorMessage] = useState("")
    const [removingUpdating, setRemovingUpdating] = useState(false)

    let orderId = orderid || props.orderid
    let shipmentId = shipmentid || props.shipmentid

    const [selectedOrderData, setSelectedOrderData] = useState<ShipmentType>()

    const oli = useRef<HTMLDivElement>(null)

    const {navBarHeight} = useContext(StylesContext)


    const history = useHistory();

    const isOrderSelected = () => {
        if(orderId !== "-1" && shipmentId !== "-1"){
            return true
        } 
        return false
    }

    useEffect(()=>{
        if(isOrderSelected()){
            getSingleOrderData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shipmentId, orderId])

    useEffect(()=>{
        if(props.successToastOpen && isOrderSelected()){
            getOrderLineItems(parseInt(orderId), parseInt(shipmentId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.successToastOpen])

    const getOrderLineItems = async (orderId: number, shipmentId: number) => {
        setOliLoading(true)
        setOliError(false)
        try {
            const config = {
                headers: {
                  'authorization':localStorage.getItem("token"),
                  'Accept' : 'application/json',
                  'Content-Type': 'application/json',
                  "Cache-Control": "no-store"
                }
              }

            let res = await axios.get(`/api/orderlineitems/${orderId}/${shipmentId}`, config)
            
            datadogLogs.logger.info("User loaded order " + orderId + "-" + shipmentId)

            setOrderLineItems(res.data)
            setOliLoading(false)
        } catch (error: any) {
            datadogLogs.logger.info("We ran into an error while order " + orderId + "-" + shipmentId, {errorData: error.response.data})
            
            setOliLoading(false)
            setOrderLoading(false)
            setOliError(true)
            setOliErrorMessage(error.response.data && typeof(error.response.data) === "string" ? error.response.data : "Internal Server Error")
            return error
        }
    }

    const renderPayBalanceButton = () => {
        return (
            <PayBalanceButton selectedOrderData={selectedOrderData} updateBalanceTemp = {updateBalanceTemp} />
        )

    }

    const updateBalanceTemp = (amount: number) => {
        if(selectedOrderData?.shipmentBalance > 0 ){
            let newOrderData = {...selectedOrderData, shipmentBalance: selectedOrderData?.shipmentBalance - amount }
            setSelectedOrderData(newOrderData)
        }
        
    }

    const getSingleOrderData = async () => {
        setOrderLoading(true)
        setOrderError(false)
        try {
            const config = {
                headers: {
                  'authorization':localStorage.getItem("token"),
                  'Accept' : 'application/json',
                  'Content-Type': 'application/json',
                  "Cache-Control": "no-store"
                }
              }

            let customer = encodeURIComponent(localStorage.getItem(CUSTOMER_NAME) as string)
            let res = await axios.get(`/api/shipments/${orderId}/${shipmentId}/customername/${customer}`, config)
            let formattedShipment = { ...res.data, landDate: res.data.landDate ? new Date(res.data.landDate) : null}
            setSelectedOrderData(formattedShipment)
            setOliLoading(false)
            getOrderLineItems(parseInt(orderId), parseInt(shipmentId));

            setOrderLoading(false)
            
        } catch (error:any) {
            setOrderError(true)
            setOliError(true)
            setOliLoading(false)
            setOrderLoading(false)
            setOrderErrorMessage(error.response.data && typeof(error.response.data) === "string" ? error.response.data : "Internal Server Error")
            if(error.response.data === "The token was expected to have 3 parts, but got 1."){
                history.go(0)
              }
            return error
        }
    }

    const availabilityListAltError = () => {
        return(
            <Error errorHeader="No Order Selected" errorMessage="Please select an order from the drop down to view order details."/>
        )
    }

    const regularError = () => {
        return (
            <Container>
                <Root>
                    <div style={{ paddingTop: navBarHeight}}>
                        <h1>Please Select an Order.</h1>
                        <p>{oliErrorMessage}</p>
                        <br />
                        <p>{orderErrorMessage}</p>
                    </div>
                
                </Root>
            </Container>
        )
    }

    const handleCloseUpdater = () => {
        setRemovingUpdating(false)
    }

    function showAddFeature() {
        return localStorage.getItem("token") !== undefined && localStorage.getItem("token") != null && localStorage.getItem(SHOW_ADD_TO_SHIP) === "true"
      }

    const addItemsButton = () => {
        if(showAddFeature()){
            return(
            <Button style={{marginLeft: 10}} variant="outlined" color="primary" onClick={() => history.push(`/products?orderid=${orderId}&shipmentid=${shipmentId}`)}>Add More Items</Button>
            )
        }
    }

    const getAccountReceivableStamp = () => {        
        if(selectedOrderData?.dueDate) {
            let today = new Date().getTime()
            let dueDate = Date.parse(selectedOrderData.dueDate)

            if(selectedOrderData.shipmentBalance === 0) {
                return (<div style={{marginLeft: "10px", border: "green", borderStyle: "solid", padding: "6px", borderRadius: "7px", borderWidth: "thin", display: "flex",flexDirection: "row", alignItems: "center"}}>
                            <DoneIcon color="success" />
                            <Typography variant="body2" style={{color: "green"}}>Paid</Typography>
                        </div>)
            } else if(today > dueDate) {
                return (<div style={{marginLeft: "10px", border: "red", borderStyle: "solid", padding: "6px", borderRadius: "7px", borderWidth: "thin", display: "flex",flexDirection: "row", alignItems: "center"}}>
                            <WarningIcon color="error" />
                            <Typography variant="body2" style={{color: "red"}}>{formatter.format(selectedOrderData.shipmentBalance)} - Overdue</Typography>
                        </div>)
            } 
        } 
        
        return (<div></div>)
        
    }

    let allOrdersUrl = '/order-management'
    let allOrdersLabel = 'Back to all Orders'
    let specificOrderUrl = `/order-management/order/${orderId}/shipment/${shipmentId}/view/orderview`
    let specificOrderLabel = 'View Order'

    if ((oliLoading || orderLoading) && isOrderSelected()) {
        return (
            <Root>
                <TreeSourceLoad message="Loading Order..." />
            </Root>
        )
    }

    if (oliError || orderError) {
        return orderId === "-1" || orderId === undefined ? availabilityListAltError() : regularError()
    }

    if(!isOrderSelected()){
        return(
            availabilityListAltError()
        )
    }

    return (
            <Root>
            <Container>
                <div style={{ display: "flex", flexDirection: "column"}}>
                    <div style={{ paddingTop: view === "orderview" ? navBarHeight : "20px"}}>
                        <Button variant="outlined" color="primary" onClick={() => history.push(view ? allOrdersUrl : specificOrderUrl)}>{view ? allOrdersLabel : specificOrderLabel}</Button>
                        {view ==="orderview" && selectedOrderData?.status === "OPEN" ? addItemsButton() : ""}
                    </div>
                    <h1 style={{display: "flex",flexDirection: "row"}}>
                        Order Number {orderId}-{shipmentId}
                        {getAccountReceivableStamp()}
                        {selectedOrderData?.status === 'INVOICED' && selectedOrderData?.shipmentBalance > 0 ? renderPayBalanceButton() : ""}
                    </h1>
                    <div className={matches ? classes.orderLineItems : classes.orderLineItemsPhone}>
                        <OrderLineItems setRemovingUpdating = {setRemovingUpdating} orderLineItems={orderLineItems} oli={oli} oliLoading={oliLoading} oliError={oliError} selectedOrderData={selectedOrderData} view={view} />
                    </div>
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={removingUpdating}
                    onClick={handleCloseUpdater}
                    >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </Root>

    )


}

export default SpecificOrder