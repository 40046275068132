import {
    EXISTING_CUSTOMER_USER_DATA,
    INITIAL_STEP,
    NEW_CUSTOMER_BASIC_INFO,
    NEW_CUSTOMER_COMPANY_INFO_ONE,
    NEW_CUSTOMER_COMPANY_INFO_TWO,
    NEW_CUSTOMER_CREDIT_APP, NEW_CUSTOMER_FINISHING_STEP,
    NEW_CUSTOMER_JOB_LINE_APP,
    NEW_CUSTOMER_USER_DATA, NewUserData
} from "./Register";
import {RectangleTwoTone} from "@mui/icons-material";
import {Tooltip, Typography} from "@mui/material";
import {useEffect, useState} from "react";

export type RegistrationBreadcrumbProps = {
    currentStep: number;
    valid: boolean;
    setCurrentStep: (step: number) => void;
    userData: NewUserData;
}

const RegistrationBreadcrumb = (props: RegistrationBreadcrumbProps) => {
    const { currentStep, setCurrentStep, valid } = props;

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [invalidStepMessage, setInvalidTransitionMessage] = useState<string>("");
    const [triggerValidation, setTriggerValidation] = useState<boolean>(false);

    useEffect(() => {
        if (currentStep === INITIAL_STEP) {
            setErrorMessage("")
            setTriggerValidation(false)
        } else if(!valid && triggerValidation) {
            setErrorMessage("Please complete the current step before moving on.")
        } else {
            setErrorMessage("")
        }
    }, [valid, triggerValidation, currentStep]);

    useEffect(() => {
        // clear the invalid transition message if the currentStep changes
        setInvalidTransitionMessage("")
    }, [currentStep]);

    const getColor = (currentStep: number, buttonStep: number) => {
        if(currentStep === buttonStep) {
            return "blue"
        } else if (currentStep > buttonStep) {
            return "green"
        } else {
            return "grey"
        }
    }

    const stepRequiresCompanyCustomer = (step: number) => {
        return step === NEW_CUSTOMER_COMPANY_INFO_ONE
                    || step === NEW_CUSTOMER_COMPANY_INFO_TWO
                    || step === NEW_CUSTOMER_CREDIT_APP
                    || step === NEW_CUSTOMER_JOB_LINE_APP
    }

    const goToStep = (step: number) => {
        setTriggerValidation(true)

        if(stepRequiresCompanyCustomer(step) && props.userData.isHomeowner) {
            setInvalidTransitionMessage("That step is only for business customers.")
        } else if (props.valid && currentStep === NEW_CUSTOMER_USER_DATA) {
            //jump to part two so they can enter the billing address
            setInvalidTransitionMessage("")
            setCurrentStep(NEW_CUSTOMER_BASIC_INFO);
        } else if (props.valid) {
            setInvalidTransitionMessage("")
            setCurrentStep(step);
        }

    }

    const renderButtons = () => {
        if(currentStep < EXISTING_CUSTOMER_USER_DATA) {
            return <></>
        } else if (!props.userData.newCustomer) {
            // we expected to have a sequence of steps for existing customers, but ATM it turns out they really only need one step
            // soooo... breadcrumbs are a bit of overkill. Skip.
            return <></>
        } else if(props.userData.newCustomer && props.userData.isHomeowner) {
            return <div>
                <Tooltip title={"Required User Data"} >
                    <RectangleTwoTone style={{ color: getColor(currentStep, NEW_CUSTOMER_USER_DATA) }} onClick={() => goToStep(NEW_CUSTOMER_USER_DATA)} />
                </Tooltip>
                <Tooltip title={"Suggested Basic Customer Info"} >
                    <RectangleTwoTone style={{ color: getColor(currentStep, NEW_CUSTOMER_BASIC_INFO) }} onClick={() => goToStep(NEW_CUSTOMER_BASIC_INFO)} />
                </Tooltip>
                <Tooltip title={"Review Registration"} >
                    <RectangleTwoTone  style={{ color: getColor(currentStep, NEW_CUSTOMER_FINISHING_STEP) }} onClick={() => goToStep(NEW_CUSTOMER_FINISHING_STEP)} />
                </Tooltip>
            </div>
        } else {
            return <div>
                <Tooltip title={"Required User Data"} >
                    <RectangleTwoTone style={{ color: getColor(currentStep, NEW_CUSTOMER_USER_DATA) }} onClick={() => goToStep(NEW_CUSTOMER_USER_DATA)} />
                </Tooltip>
                <Tooltip title={"Suggested Basic Customer Info"} >
                    <RectangleTwoTone style={{ color: getColor(currentStep, NEW_CUSTOMER_BASIC_INFO) }} onClick={() => goToStep(NEW_CUSTOMER_BASIC_INFO)} />
                </Tooltip>
                <Tooltip title={"Suggested Company Info 1"} >
                    <RectangleTwoTone  style={{ color: getColor(currentStep, NEW_CUSTOMER_COMPANY_INFO_ONE) }} onClick={() => goToStep(NEW_CUSTOMER_COMPANY_INFO_ONE)} />
                </Tooltip>
                <Tooltip title={"Suggested Company Info 2"} >
                    <RectangleTwoTone  style={{ color: getColor(currentStep, NEW_CUSTOMER_COMPANY_INFO_TWO) }} onClick={() => goToStep(NEW_CUSTOMER_COMPANY_INFO_TWO)} />
                </Tooltip>
                <Tooltip title={"Optional Credit Application"} >
                    <RectangleTwoTone  style={{ color: getColor(currentStep, NEW_CUSTOMER_CREDIT_APP) }} onClick={() => goToStep(NEW_CUSTOMER_CREDIT_APP)} />
                </Tooltip>
                <Tooltip title={"Optional Job Line Application"} >
                    <RectangleTwoTone  style={{ color: getColor(currentStep, NEW_CUSTOMER_JOB_LINE_APP) }} onClick={() => goToStep(NEW_CUSTOMER_JOB_LINE_APP)} />
                </Tooltip>
                <Tooltip title={"Review Registration"} >
                    <RectangleTwoTone  style={{ color: getColor(currentStep, NEW_CUSTOMER_FINISHING_STEP) }} onClick={() => goToStep(NEW_CUSTOMER_FINISHING_STEP)} />
                </Tooltip>
            </div>
        }
    }

    return <>
        {renderButtons()}
        <Typography variant="body1" style={{color: "red"}}>{errorMessage}</Typography>
        <Typography variant="body1" style={{color: "red"}}>{invalidStepMessage}</Typography>
    </>
};

export default RegistrationBreadcrumb;