import {PaddedCard} from "../../LoggedOut/PaddedCard";
import {CardHeader, Checkbox, FormControlLabel, TextField} from "@mui/material";
import React, {useContext} from "react";
import {StylesContext} from "../../../providers/StylesProvider";

type PaymentNotesCardProps = {
    paymentAmount: number;
    setPaymentAmount: React.Dispatch<React.SetStateAction<number>>
    applyToOldestFirst: boolean;
    setApplyToOldestFirst: React.Dispatch<React.SetStateAction<boolean>>;
}
const PaymentNotesCard = (props: PaymentNotesCardProps) => {
    //Context
    const { isDesktop } = useContext(StylesContext);


    const renderDesktop = () => {
        return <PaddedCard style={{marginBottom: 20}}>
            <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <div style={{width: "17%"}}>
                    <CardHeader title="Other Amount"/>
                </div>
                <div style={{ width: "83%", margin: 10, display: "flex", alignItems: "flex-start", justifyContent: "space-between"}}>
                    <div style={{width: "75%"}}>
                        <TextField
                            id={"payOtherAmount"}
                            fullWidth
                            type="number"
                            label="How much would you like to pay?"
                            onChange={(event) => {
                                const value = event.target.value;
                                const doubleRegex = /^\d*\.?\d{0,2}$/;
                                const candidate = Number(value);

                                if (value === "" || (doubleRegex.test(value) && candidate >= 0 && candidate <= 99999.99)) {
                                    props.setPaymentAmount(value === "" ? 0 : candidate);
                                }
                            }}
                            value={ props.paymentAmount === 0 ? "no" : props.paymentAmount}
                            InputProps={{
                                startAdornment: <span>$</span>,
                            }}
                        />
                    </div>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "25%", marginLeft: 10, alignContent: "center"}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.applyToOldestFirst}
                                    onChange={(event) => props.setApplyToOldestFirst(event.target.checked)}
                                />
                            }
                            label="Apply To Oldest First"
                        />
                    </div>

                </div>
            </div>
        </PaddedCard>
    }

    const renderMobile = () => {
        return <PaddedCard style={{marginBottom: 20}}>
            <CardHeader title="Other Amount"/>

                    <div style={{ marginLeft: 20, marginTop: 5, marginRight: 20 }}>
                        <TextField
                            id={"payOtherAmount"}
                            fullWidth
                            type="number"
                            label="How much would you like to pay?"
                            onChange={(event) => {
                                const value = event.target.value;
                                const doubleRegex = /^\d*\.?\d{0,2}$/;
                                const candidate = Number(value);

                                if (value === "" || (doubleRegex.test(value) && candidate >= 0 && candidate <= 99999.99)) {
                                    props.setPaymentAmount(value === "" ? 0 : candidate);
                                }
                            }}
                            value={ props.paymentAmount === 0 ? "no" : props.paymentAmount}
                            InputProps={{
                                startAdornment: <span>$</span>,
                            }}
                        />
                    </div>
                    <div style={{ marginLeft: 30, marginTop: 10, marginBottom: 20 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.applyToOldestFirst}
                                    onChange={(event) => props.setApplyToOldestFirst(event.target.checked)}
                                />
                            }
                            label="Apply To Oldest First"
                        />
                    </div>

        </PaddedCard>
    }

    return <>
        { isDesktop ? renderDesktop() : renderMobile() }
    </>
}

export default PaymentNotesCard;
