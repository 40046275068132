import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    TextField,
    Typography
} from "@mui/material";
import React, {useContext} from "react";
import {CreditApplicationData} from "./CreditApplicationData";
import {ExpandMore} from "@mui/icons-material";
import {StylesContext} from "../../../providers/StylesProvider";

export type CreditBasicInfoProps = {
    isInvalid: (fieldName: string) => boolean;
    getErrorMessage: (fieldName: string) => string;
    updateData: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    directDataUpdate: (name: string, value: any) => void
    creditApplicationData: CreditApplicationData;
}

const CreditBasicInfo: React.FC<CreditBasicInfoProps> = (props: CreditBasicInfoProps) => {
    //props + context
    const {isInvalid, getErrorMessage, updateData} = props
    const {isDesktop} = useContext(StylesContext);

    const renderDesktop = () => {
        return <>
                <Accordion defaultExpanded style={{width: "100%"}}>
                    <AccordionSummary expandIcon={<ExpandMore/>}>
                        <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Requested Credit Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                            <TextField
                                style={{width: "49%"}}
                                required
                                variant="standard"
                                name="creditRequested"
                                label="Amount of Credit Requested"
                                error={isInvalid("creditRequested")}
                                helperText={getErrorMessage("creditRequested")}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    const doubleRegex = /^\d*\.?\d{0,2}$/;
                                    const candidate = Number(value);

                                    if (value === "" || (doubleRegex.test(value) && candidate >= 0)) {
                                        updateData(event);
                                    }
                                }}
                                InputProps={{
                                    startAdornment: props.creditApplicationData.creditRequested > 0 ?
                                        <span>$</span> : null,
                                }}
                                value={props.creditApplicationData.creditRequested > 0 ? props.creditApplicationData.creditRequested : ""}
                            />
                            <TextField
                                style={{width: "49%"}}
                                variant="standard"
                                name="stateSalesTaxNumber"
                                label="Sate sales tax number"
                                error={isInvalid("stateSalesTaxNumber")}
                                helperText={getErrorMessage("stateSalesTaxNumber")}
                                onChange={updateData}
                                value={props.creditApplicationData.stateSalesTaxNumber}
                            />
                        </div>

                    </AccordionDetails>
                </Accordion>
        </>
    }

    const renderMobile = () => {
        return <Accordion defaultExpanded style={{width: "100%"}}>
            <AccordionSummary expandIcon={<ExpandMore/>}>
                <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Requested Credit
                    Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%"}}>
                    <TextField
                        fullWidth
                        required
                        margin="dense"
                        variant="standard"
                        name="creditRequested"
                        label="Amount of Credit Requested"
                        error={isInvalid("creditRequested")}
                        helperText={getErrorMessage("creditRequested")}
                        onChange={(event) => {
                            const value = event.target.value;
                            const doubleRegex = /^\d*\.?\d{0,2}$/;
                            const candidate = Number(value);

                            if (value === "" || (doubleRegex.test(value) && candidate >= 0)) {
                                updateData(event);
                            }
                        }}
                        InputProps={{
                            startAdornment: props.creditApplicationData.creditRequested > 0 ?
                                <span>$</span> : null,
                        }}
                        value={props.creditApplicationData.creditRequested > 0 ? props.creditApplicationData.creditRequested : ""}
                    />
                    <TextField
                        fullWidth
                        margin="dense"
                        variant="standard"
                        name="stateSalesTaxNumber"
                        label="Sate sales tax number"
                        error={isInvalid("stateSalesTaxNumber")}
                        helperText={getErrorMessage("stateSalesTaxNumber")}
                        onChange={updateData}
                        value={props.creditApplicationData.stateSalesTaxNumber}
                    />
                </div>

            </AccordionDetails>
        </Accordion>
    }

    if(isDesktop) {
        return renderDesktop();
    } else {
        return renderMobile();
    }
}

export default CreditBasicInfo;