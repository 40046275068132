import { Error } from "./Error"

const NoMatch = () => {
  return (
    <Error errorHeader="Page Does Not Exist" errorMessage="Sorry, it looks like you've landed on a page that we haven't created yet." />

  )

}

export default NoMatch