import { useState, useContext, useEffect } from "react"
import { AuthContext } from "../../providers/AuthProvider"
import { RouteComponentProps, RouteProps, useHistory } from "react-router"
import { passwordString } from "../../constants/Constants"
import { TextField, Button, Divider, Container } from "@mui/material"
import TreeSourceLoad from "../Loaders/TreeSourceLoad"

type Props = {
  history: RouteComponentProps & LocationState
} & RouteProps

type LocationState = {
  state: { from: { pathname: string } }
};

const Login: React.FC<Props> = (props) => {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const history = useHistory()
  

  const auth = useContext(AuthContext)

  useEffect(() => {
    let urlString = auth.fromPath ? auth.fromPath : ""
    let guidString = urlString.split("?")[1]
    let urlParams = new URLSearchParams(guidString);
    let guid = urlParams.get('GUID')

    if (auth.getUserInfoFromGuid) { auth.getUserInfoFromGuid(guid) }

    if (auth.user) {
      setEmail(auth.user.email)
      if (auth.user.verified === 0) { history.push('/setpassword') }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user])

  useEffect(() => {
    if (auth.isTokenExpired && localStorage.getItem('expirationDate') !== null) {
      if (auth.setErrorMessage) { auth.setErrorMessage("Your session has expired, please login to continue your session.") }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

const handleSubmit = (e: any) => {
  e.preventDefault()
  if (auth.handleLogin) {
    auth.handleLogin(email, password, props.history, () => history.replace(calcLandingPage(auth.fromPath)));
  }
}

const calcLandingPage = (fromPath: string | undefined) => {
    if(!fromPath || fromPath === '/user/emailverification' || fromPath === '/login') {
      return "/order-management"; // default landing page
    } else {
        return fromPath;
    }
}

const renderErrorMessage = () => {
  if (auth.errorMessage != null) {
    return (
      <p>{auth.errorMessage}</p>
    )
  }
}

if (auth.loadingLogin) {
  return (
    <TreeSourceLoad message="Logging in..." />
  )
}

const renderRegisterButton = () => {
  return (
    <>
      <Divider variant="fullWidth" />
      <Button
        variant="contained"
        color="primary"
        style={{ margin: "10px" }}
        onClick={() => history.push('/register')}
      >Register
      </Button>
    </>
  )
}

return (
  <Container>
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 200 }}>
      <h1 style={{ textAlign: "center" }}>Login</h1>
      {renderErrorMessage()}
      <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", minWidth: "300px" }}>
        <TextField
          required
          variant="outlined"
          autoFocus
          name="email"
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          style={{ margin: "10px" }}
        />
        <TextField
          required
          variant="outlined"
          name={passwordString}
          label={passwordString}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          style={{ margin: "10px" }}
        />
        <Button
          type="submit"
          style={{ margin: "10px" }}
          variant="contained"
          color="primary"
        >Login
        </Button>
        {auth.user?.verified ? "" : renderRegisterButton()}
      </form>
      <p><a href = "/user/forgottenpassword"> Having trouble? Click here to reset your password</a></p>

    </div>
  </Container>
)
}

export default Login